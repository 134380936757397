import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchUser } from "@/configs/fetches/PoliceCommandCenter/fetchUser";
import type { UserSearch } from "@/configs/types/User/typeUser";
import { ENUM_MUTATION, ENUM_MUTATION_STATUS } from "@/configs/enums/enum";

const storeName = `useUserSearchStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  status: {
    is: false,
    name: `status`,
    label: `form.status.label`,
    placeholder: `form.status.placeholder`,
    value: null,
  },
  role: {
    is: false,
    name: `role`,
    label: `form.role.label`,
    placeholder: `form.role.placeholder`,
    value: null,
  },
  organizationCode: {
    is: false,
    name: `division`,
    label: `form.division.label`,
    placeholder: `form.division.placeholder`,
    value: null,
  },
  responsibleAreaCode: {
    is: false,
    name: `responsibleAreaCode`,
    label: `form.region.label`,
    placeholder: `form.region.placeholder`,
    value: null,
  },
  keyword: {
    is: false,
    name: `keyword`,
    label: `form.keyword.label`,
    placeholder: `form.keyword.placeholder`,
    value: null,
  },
};
export const useUserSearchStore = defineStore(storeName, {
  state: () => ({
    fetchUser: new fetchUser(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: true,
    isRequired,
    currentPage: 1,
    totalPage: 1,
    total: 0,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    tableResults: null as any,
    query: <UserSearch>{
      status: undefined,
      role: undefined,
      keyword: undefined,
      page: undefined,
      size: undefined,
    },
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object({
      status: isRequired.status.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      role: isRequired.role.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      keyword: isRequired.keyword.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
  }),
  actions: {
    async get(query: UserSearch) {
      try {
        this.loading = true;
        this.tableResults = await this.fetchUser.List(query);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.totalPage = this.tableResults.totalPages;
        this.total = this.tableResults.total;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
      } finally {
        this.loading = false;
      }
    },
  },
});
