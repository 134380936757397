<template>
  <div class="w-full">
    <label v-if="label?.length" :for="name" class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50"> {{ label }} <span v-if="required" class="text-ttp-error">*</span></label>
    <div class="relative">
      <div v-if="$slots.prefix" class="absolute inset-y-0 flex items-center start-2 z-50">
        <slot name="prefix" />
      </div>
      <input v-model="model" :id="id" :name="name" :type="type || 'text'" :placeholder="placeholder" :disabled="disabled" :readonly="readonly" :maxlength="maxlength" :autocomplete="autocomplete ? 'on' : 'off'" class="z-0 bg-white border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-xs rounded-lg disabled:cursor-not-allowed disabled:bg-ttp-new block w-full p-2.5" :class="[{ 'border-ttp-error': errorMessage?.length, 'pl-10': $slots.prefix, 'pr-10': $slots.suffix }, inputClass]" />
      <div v-if="$slots.suffix" class="absolute inset-y-0 flex items-center end-2 z-50">
        <slot name="suffix" />
      </div>
    </div>
    <small v-if="errorMessage?.length" class="relative text-ttp-text-error z-0">{{ errorMessage }}</small>
  </div>
</template>

<script setup lang="ts">
import type { InputTypeHTMLAttribute } from "vue";

const model = defineModel();

defineProps<{
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  type?: InputTypeHTMLAttribute;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  maxlength?: number;
  autocomplete?: boolean;
  inputClass?: any;
  errorMessage?: string;
}>();
</script>
