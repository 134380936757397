<script setup lang="ts">
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { ENUM_COLOR, ENUM_FIELD_LOCALE, ENUM_SIZE } from "@/configs/enums/enum";
import { fetchIncident } from "@/configs/fetches/PoliceCommandCenter/fetchIncident";
import { fetchUser } from "@/configs/fetches/PoliceCommandCenter/fetchUser";
import { convertString } from "@/configs/helpers/MainHelper";
import type { typeGetUser } from "@/configs/types/User/typeUser";
import { computed, onMounted, ref, watch } from "vue";
import { useIncidentAssigneeFormStore } from "../stores/useIncidentAssigneeFormStore";
import {  ENUM_MUTATION_STATUS } from "@/configs/enums/enum";

type Props = {
  id: string;
  responsible?: typeGetUser;
  editable?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  editable: false,
});

const incidentAssigneeFormStore = useIncidentAssigneeFormStore();

const responsible = ref<typeGetUser | undefined>(props.responsible)

const name = computed(() => convertString(responsible.value?.content?.[0]?.fullName))
const email = computed(() => convertString(responsible.value?.email))

const users = ref<typeGetUser[]>([]);
const responsibleID = ref<string>('');
const loading = ref<boolean>(false);

const onInitial = async () => {
  const u = new fetchUser();

  try {
    const response = await u.List({
      role: "INTEPETOR",
      status: "ACTIVE",
      size: 9999,
    });

    users.value = response.items;
  } catch (e) {
    console.error("failed to list users", e);
  }
}

const onSubmit = async () => {
  const i = new fetchIncident()
  try {
    loading.value = true
    await i.SetResponsible(props.id, responsibleID.value)
  } catch(e) {
    console.error(e)
  } finally {
    loading.value = false
    incidentAssigneeFormStore.isResponsibleSuccess = true;
    incidentAssigneeFormStore.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
    onInitial();
  }
};

onMounted(async () => {
 onInitial();
});

watch(() => props.responsible, (current) => {
  responsible.value = current
  responsibleID.value = String(current?.id || '')
})
</script>

<template>
  <div class="ttp-layout">
    <div class="flex flex-col gap-2.5 p-4">
      <h4 class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">ผู้รับเรื่อง</h4>
      <div class="flex-col justify-start items-start gap-2.5 inline-flex">
        <div v-if="!editable" class="justify-start items-center gap-2.5 inline-flex">
          <div class="flex-col justify-start items-start gap-[7px] inline-flex">
            <p class="self-stretch h-4 text-gray-600 font-normal leading-tight">
              {{ name }}
            </p>
            <p class="self-stretch text-gray-500 ttp-text-xs font-normal">
              {{ email }}
            </p>
          </div>
        </div>
        <form v-else @submit.prevent="onSubmit">
          <div class="flex w-full">
            <select
              v-model="responsibleID"
              :disabled="loading"
              class="bg-gray-50 border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-xs rounded-lg disabled:cursor-not-allowed disabled:bg-ttp-new block w-full p-2.5"
            >
              <option value="" disabled hidden>กรุณาเลือกผู้รับเรื่อง</option>
              <option v-for="(item, index) in users" :key="index" :value="item.id">
                {{ item.content.find(({ locale }) => locale === ENUM_FIELD_LOCALE.TH)?.fullName }}
              </option>
            </select>
          </div>
          <div class="flex w-full mt-4">
            <ButtonDefaultComponent
              :class-enum-name="ENUM_COLOR.DEFAULT_1"
              :size="ENUM_SIZE.SMALL"
              :is-loading="loading"
              title="มอบหมายงาน"
              is-submit />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
