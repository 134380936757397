<script lang="ts">
import { defineComponent } from "vue";

const EmptyDataComponent = defineComponent({
  name: "EmptyDataComponent",
  props: {
    title: {
      type: String,
      required: true,
    },
    classTagName: {
      type: String,
      required: false,
      default: "py-5",
    },
  },
});

export default EmptyDataComponent;
</script>

<template>
  <div :class="classTagName">
    <div class="mx-auto w-max">
      <svg xmlns="http://www.w3.org/2000/svg" width="52" height="68" viewBox="0 0 52 68" fill="none">
        <path d="M51 41.5V32.75C51 29.7663 49.8147 26.9048 47.705 24.795C45.5952 22.6853 42.7337 21.5 39.75 21.5H34.75C33.7554 21.5 32.8016 21.1049 32.0983 20.4016C31.3951 19.6984 31 18.7446 31 17.75V12.75C31 9.76631 29.8147 6.90483 27.7049 4.79505C25.5952 2.68526 22.7337 1.5 19.75 1.5H13.5M13.5 44H38.5M13.5 54H26M21 1.5H4.75C2.68 1.5 1 3.18 1 5.25V62.75C1 64.82 2.68 66.5 4.75 66.5H47.25C49.32 66.5 51 64.82 51 62.75V31.5C51 23.5435 47.8393 15.9129 42.2132 10.2868C36.5871 4.6607 28.9565 1.5 21 1.5Z" stroke="#9CA3AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <h5 class="text-center text-gray-400 text-sm mt-4 whitespace-pre-wrap" v-html="title" />
  </div>
</template>
