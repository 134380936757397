import { routePoliceTourist } from "@/routers/routePoliceTourist";
import { routePoliceAgent } from "@/routers/routePoliceAgent";

import MainLayoutView from "@/layouts/NotAuthenticationLayouts/MainLayoutView.vue";
import NotFoundView from "@/layouts/OtherLayouts/NotFoundView.vue";
import MaintenanceView from "@/layouts/OtherLayouts/MaintenanceView.vue";

import LoginPoliceCommandCenterView from "@/views/PoliceCommandCenter/Authentication/LoginView.vue";
import ForgotPasswordPoliceCommandCenterView from "@/views/PoliceCommandCenter/Authentication/ForgotPasswordView.vue";
import ForgotPasswordOTPView from "@/views/PoliceTouristAndAgent/Authentication/ForgotPasswordOTPView.vue";
import ForgotPasswordView from "@/views/PoliceTouristAndAgent/Authentication/ForgotPasswordView.vue";
import LoginView from "@/views/PoliceTouristAndAgent/Authentication/LoginView.vue";
import RegisterView from "@/views/PoliceTouristAndAgent/Authentication/RegisterView.vue";
import RegisterInformationView from "@/views/PoliceTouristAndAgent/Authentication/RegisterInformationView.vue";
import RegisterSuccessView from "@/views/PoliceTouristAndAgent/Authentication/RegisterSuccessView.vue";
import RegisterOTPView from "@/views/PoliceTouristAndAgent/Authentication/RegisterOTPView.vue";
import ResetPasswordView from "@/views/PoliceTouristAndAgent/Authentication/ResetPasswordView.vue";
import TermConditionsView from "@/views/PoliceTouristAndAgent/Authentication/TermConditionsView.vue";
import ForgotPasswordNewPasswordView from "@/views/PoliceTouristAndAgent/Authentication/ForgotPasswordNewPasswordView.vue";
import PrivacyPolicyView from "@/views/PoliceTouristAndAgent/Authentication/PrivacyPolicyView.vue";

export const routePoliceTouristAndAgent: any = [
  ...routePoliceTourist,
  ...routePoliceAgent,
  {
    path: "/",
    name: "MainLayoutPoliceCommandCenterView",
    component: MainLayoutView,
    meta: {
      requiresAuth: false,
      guestOnly: true,
      title: "MainLayoutPoliceCommandCenterView",
      i18n: "layout.menu.MainLayoutPoliceCommandCenterView",
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
      isIcon: false,
      isDropdown: false,
      isMenu: false,
      getProjectType: "tourist",
      activeMenu: [],
    },
    children: [
      {
        path: "/",
        redirect: "/login",
      },
      {
        path: "/:pathMatch(.*)*",
        name: "NotFoundView",
        component: NotFoundView,
        meta: {
          requiresAuth: false,
          title: "Not Found",
          i18n: "layout.menu.NotFoundView",
          icon: '<svg class="hi-mini hi-x-circle inline-block ttp-icon-inside-box-03" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd"/></svg>',
          isIcon: false,
          isDropdown: false,
          isHeader: false,
          isBackground: true,
          isMenu: false,
          layout: "MainLayoutView",
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/maintenance",
        name: "MaintenanceView",
        component: MaintenanceView,
        meta: {
          requiresAuth: false,
          title: "Maintenance",
          i18n: "layout.menu.MaintenanceView",
          icon: "",
          isIcon: false,
          isDropdown: false,
          isHeader: false,
          isBackground: true,
          isMenu: false,
          layout: "MainLayoutView",
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/agent/login",
        name: "LoginPoliceCommandCenterView",
        component: LoginPoliceCommandCenterView,
        meta: {
          requiresAuth: false,
          title: "Login",
          i18n: "layout.menu.LoginView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/agent/forgot-password",
        name: "ForgotPasswordPoliceCommandCenterView",
        component: ForgotPasswordPoliceCommandCenterView,
        meta: {
          requiresAuth: false,
          title: "Account / Forgot Password",
          i18n: "layout.menu.ForgotPasswordView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/login",
        name: "LoginView",
        component: LoginView,
        meta: {
          requiresAuth: false,
          title: "Login",
          i18n: "layout.menu.LoginView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/register",
        name: "RegisterView",
        component: RegisterView,
        meta: {
          requiresAuth: false,
          title: "Register",
          i18n: "layout.menu.RegisterView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/register/info",
        name: "RegisterInformationView",
        component: RegisterInformationView,
        meta: {
          requiresAuth: true,
          title: "Register",
          i18n: "layout.menu.RegisterView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/register/success",
        name: "RegisterSuccessView",
        component: RegisterSuccessView,
        meta: {
          requiresAuth: true,
          title: "Register",
          i18n: "layout.menu.RegisterView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/register/otp",
        name: "RegisterOTPView",
        component: RegisterOTPView,
        meta: {
          requiresAuth: true,
          title: "Account / Create / OTP",
          i18n: "layout.menu.CreateAccountOTPView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/register/term-conditions",
        name: "TermConditionsView",
        component: TermConditionsView,
        meta: {
          requiresAuth: true,
          title: "Account / Create / Term & Conditions",
          i18n: "layout.menu.TermConditionsView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/forgot-password",
        name: "ForgotPasswordView",
        component: ForgotPasswordView,
        meta: {
          requiresAuth: false,
          title: "Account / Forgot Password",
          i18n: "layout.menu.ForgotPasswordView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/forgot-password/otp",
        name: "ForgotPasswordOTPView",
        component: ForgotPasswordOTPView,
        meta: {
          requiresAuth: false,
          title: "Account / Forgot Password / OTP",
          i18n: "layout.menu.ForgotPasswordOTPView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/forgot-password/new-password",
        name: "ForgotPasswordNewPasswordView",
        component: ForgotPasswordNewPasswordView,
        meta: {
          requiresAuth: true,
          title: "Account / Forgot Password / New Password",
          i18n: "layout.menu.ForgotPasswordNewPasswordView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/reset-password",
        name: "ResetPasswordView",
        component: ResetPasswordView,
        meta: {
          requiresAuth: true,
          title: "Account / Reset Password",
          i18n: "layout.menu.ResetPasswordView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicyView",
        component: PrivacyPolicyView,
        meta: {
          requiresAuth: false,
          title: "Privacy Policy",
          i18n: "layout.menu.PrivacyPolicyView",
          icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="hi-mini hi-user-group inline-block ttp-icon-inside-box-03"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" /></svg>',
          isHeader: false,
          isBackground: false,
          isIcon: true,
          isMenu: false,
          isSpecial: false,
          getProjectType: "tourist",
          activeMenu: [],
        },
      },
    ],
  },
];
