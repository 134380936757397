import { ENUM_APP_NAME } from "@/configs/enums/enum";
import { axiosClient } from "@/configs/helpers/AxiosHelper";
import type { typeGetNews } from "@/configs/types/News/typeNews";
import type { typeGetPagination } from "@/configs/types/Shared/typePagination";

export class fetchNews {
  async List(params: object): Promise<any> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetPagination<typeGetNews>>("/v1/news", { params });
    return data;
  }

  async Create(body: object): Promise<typeGetNews> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).post<typeGetNews>("/v1/news", body);
    return data;
  }

  async Get(id: string): Promise<typeGetNews> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).get<typeGetNews>(`/v1/news/${id}`);

    const translations = [
      data.translations.find(t => t.locale === 'TH'),
      data.translations.find(t => t.locale === 'EN'),
      data.translations.find(t => t.locale === 'CN'),
      data.translations.find(t => t.locale === 'JP'),
      data.translations.find(t => t.locale === 'KR'),
      data.translations.find(t => t.locale === 'RU'),
    ]

    for (const [i, t] of translations.entries()) {
      if (t) {
        data.translations[i] = t
      }
    }

    return data;
  }

  async Update(id: string, body: object): Promise<typeGetNews> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).put<typeGetNews>(`/v1/news/${id}`, body);
    return data;
  }

  async Delete(id: string): Promise<typeGetNews> {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).delete<typeGetNews>(`/v1/news/${id}`);
    return data;
  }

  async PatchOrdering(id: string, ordering: number) {
    const { data } = await axiosClient({ isFile: false }, ENUM_APP_NAME.POLICE_COMMAND_CENTER).patch<typeGetNews>(`/v1/news/${id}/ordering`, { ordering });
    return data;
  }
}
