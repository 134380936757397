<script lang="ts">
import { defineComponent } from "vue";
import { useAuthStore } from "../../Authentication/stores/useAuthStore";
import { useHistorySearchStore } from "../History/stores/useHistorySearchStore";
import LoadingFormComponent from "@/components/Loadings/LoadingFormComponent.vue";
import { fetchFile } from "@/configs/fetches/PoliceTouristAndAgent/shared/fetchFile";
import { fetchAlert } from "@/configs/fetches/PoliceTouristAndAgent/Tourist/fetchAlert";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useChatFormStore } from "../Chat/stores/useChatFormStore";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper/modules";
import { usePopularPlaceStore } from "@/stores/PoliceTouristAndAgent/usePopularPlaceStore";
import { useNewsSearchStore } from "../News/stores/useNewsSearchStore";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import AppHeader from "../AppHeader.vue";
import { usePermissionStore } from "@/stores/PoliceTouristAndAgent/usePermissionStore";

const WORLD_CIRCUMFERENCE = 40075 * 1000;

export default defineComponent({
  name: "HomeView",
  components: {
    AppHeader,
    LoadingFormComponent,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      componentName: this.$options.name,
      profile_image: "",
      image_url: new URL(`@/assets/images/ttp-logo.png`, import.meta.url).href as any,
      popularPlace: null,
      roomId: "" as any,
      coordinations: null
    } as any;
  },
  computed: {
    defaultFormComponents() {
      return {
        alert: {
          backgroundCheck: {
            component: PopupSuccessComponent,
            props: {
              isOpen: this.historySearchStore.isBackgroundCheck,
              textTitle: this.$t("popup.success.tel1155_title"),
              textDetail: this.$t("popup.success.tel1155_detail"),
              isIcon: true,
              htmlIcon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" /</svg>`,
              classEnumName: this.$enums.ENUM_COLOR.YELLOW,
              class: "text-[#FB923C]",
              functionProp: () => {
                this.historySearchStore.isBackgroundCheck = false;
                this.$window.location.href = "tel:1155";
              },
              functionCancelProp: () => {
                this.historySearchStore.isBackgroundCheck = false;
              },
            } as typeof PopupSuccessComponent.props,
          },
        },
        form: {
          button: {
            btnSOS: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.sharedStore.isLoadingPermission,
                isDisable: this.sharedStore.isLoadingPermission,
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("page.HomeView.sos_btn"),
                classEnumName: this.$enums.ENUM_COLOR.RED,
              } as typeof ButtonDefaultComponent.props,
              click: async (_e: Event) => {
                this.makeSOSCall();
              },
            },
            btn1155: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.sharedStore.isLoadingPermission,
                isDisable: this.sharedStore.isLoadingPermission,
                htmlIcon: "",
                isSubmit: true,
                title: "1155",
                classEnumName: this.$enums.ENUM_COLOR.RED,
              } as typeof ButtonDefaultComponent.props,
              click: async (_e: Event) => {
                this.telSOS1155();
              },
            },
          },
        },
      };
    },
  },
  methods: {
    async popularPlaceFetch() {
      const delay = (d: number) => new Promise((resolve) => setTimeout(resolve, d));

      await Promise.all([this.historySearchStore.onUpdateCurrentPage()]);

      const recursivePopularPlace = async (distance: number) => {
        let d = 0;
        try {
          this.popularPlace = await this.popularPlaceStore.list(
            {
              latitude: this.coordinations?.latitude,
              longitude: this.coordinations?.longitude,
              distance,
            },
            this.sharedStore?.locale?.toUpperCase()
          );
          distance = distance * 2;
        } catch (e) {
          d = 5 * 1000; // delay 5 seconds
        } finally {
          await delay(d);
          if (!this.popularPlace?.total && distance < WORLD_CIRCUMFERENCE) {
            await recursivePopularPlace(distance * 2);
          }
        }
      };

      await recursivePopularPlace(5000);
    },
    async newsFetch() {
      const results = await this.newsSearchStore.onUpdateListCurrentPage(1, 3);

      if (results?.items.length) {
        results.items = await Promise.all(
          results.items.map(async (item: any) => {
            if (typeof item.thumbnail !== "string") {
              return item;
            }

            try {
              const [thumbnail] = await this.newsSearchStore?.fetchFile.Get([item.thumbnail]);
              item.thumbnail = thumbnail;
            } catch (e) {
              item.thumbnail = null;
            }

            return item;
          })
        );
      }

      this.newsSearchStore.tableListResults = results;
      this.newsSearchStore.totalPage = results?.totalPages;

      this.newsSearchStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
      this.newsSearchStore.loadingScroll = false;
    },
    loading() {
      return this.historySearchStore.loading || this.chatFormStore.loading;
    },
    async setting() {
      this.newsFetch();

      // Get Image
      if (this.authStore?.user?.image) {
        const f = new fetchFile();
        this.profile_image = await f.GetUrl(this.authStore.user.image);
      }

      this.$configLightbox();
    },
    async telSOS1155() {
      this.historySearchStore.isBackgroundCheck = !this.historySearchStore.isBackgroundCheck;
    },
    trimName(name: string): string {
      if (name.length <= 20) {
        return name;
      }

      return `${name.slice(0, 17)}...`;
    },
    async alertFetch() {
      if (this.coordinations) {
        const alertFetch = new fetchAlert();
        const { items } = await alertFetch.List({
          latitude: this.coordinations?.latitude,
          longitude: this.coordinations?.longitude,
        });

        if (!items) {
          return;
        }

        try {
          const thumbnails = await this.newsSearchStore?.fetchFile.Get([items?.map((i) => i.thumbnail)]);
          thumbnails.forEach((thumbnail: any, index: number) => {
            items[index].thumbnail = thumbnail;
          });

          this.sharedStore.alerts = items;
        } catch (e) {}

        this.sharedStore.isAlert = this.sharedStore.alerts.length;
      }
    },
  },
  async mounted() {
    // Setting Page
    await this.setting();
    if (!this.permissionStore.hasLocationPermission) {
      await this.permissionStore.requestGeolocationPermission()
    }

    if (this.permissionStore.hasLocationPermission) {
      this.coordinations = await this.permissionStore.getGeolocation()
    }

    if (this.coordinations) {
      this.popularPlaceFetch();
    }
  },
  setup() {
    const authStore = useAuthStore();
    const historySearchStore = useHistorySearchStore();
    const masterDataStore = useMasterDataStore();
    const chatFormStore = useChatFormStore();
    const sharedStore = useSharedStore();
    const permissionStore = usePermissionStore();
    const popularPlaceStore = usePopularPlaceStore();
    const newsSearchStore = useNewsSearchStore();

    return {
      chatFormStore,
      sharedStore,
      permissionStore,
      authStore,
      historySearchStore,
      masterDataStore,
      modules: [Pagination, Navigation],
      popularPlaceStore,
      newsSearchStore,
    };
  },
  watch: {
    coordinations(to) {
      if (to) {
        this.popularPlaceFetch();
        this.alertFetch();
      }
    },
  },
});
</script>

<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px]">
    <main class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <AppHeader />

          <div class="flex flex-col gap-4 gradient-border ttp-padding-01 w-full h-full overflow-auto pb-20">
            <section v-if="sharedStore?.alerts?.length" id="alert">
              <Swiper :pagination="true" :loop="true" :modules="modules" :spaceBetween="24" class="swiper-wrapper">
                <SwiperSlide v-for="alert in sharedStore?.alerts" :key="alert.id" class="swiper-slide">
                  <router-link
                    :to="{ name: 'AlertDetailView', params: { id: alert?.id } }"
                    :class="{
                      'px-2': !alert?.thumbnail?.url,
                      'grid-cols-3': !alert?.thumbnail?.url?.length,
                      'grid-cols-2': alert?.thumbnail?.url?.length,
                    }"
                    class="relative grid gap-2 h-36 border border-[2px] rounded-[21px] overflow-hidden bg-[#FFD1D3] border-[#CC2B34] my-4"
                  >
                    <div class="relative w-full overflow-hidden flex justify-center items-center">
                      <div v-if="alert?.thumbnail?.url" class="absolute inset-0 w-full h-36">
                        <img :src="alert?.thumbnail?.url" alt="รูปภาพ" class="object-cover w-full h-full" />
                      </div>
                      <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#CC2B34" class="w-20 h-20">
                        <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <div class="flex flex-col w-full justify-center py-2" :class="{ 'col-span-2': !alert?.thumbnail?.url?.length }">
                      <h4 class="w-full ttp-text-lg font-semibold text-[#CC2B34]">
                        {{ $filterNewsByLocale(alert?.translations, masterDataStore.lang())?.title?.length ? $filterNewsByLocale(alert?.translations, masterDataStore.lang())?.title : $filterNewsByLocale(alert?.translations, 'EN')?.title }}
                      </h4>
                      <p class="w-full ttp-text-base text-[#CC2B34]">
                        {{ $filterNewsByLocale(alert?.translations, masterDataStore.lang())?.detail?.length ? $filterNewsByLocale(alert?.translations, masterDataStore.lang())?.detail : $filterNewsByLocale(alert?.translations, 'EN')?.detail }}
                      </p>
                    </div>
                  </router-link>
                </SwiperSlide>
              </Swiper>
            </section>

            <section id="news">
              <div class="flex mb-4">
                <div class="w-full text-neutral-700 ttp-text-lg font-bold">{{ $t("page.HomeView.news") }}</div>
                <router-link :to="{ name: 'NewsView' }" class="w-full ttp-text-sm text-right text-[#2C3483]">{{ $t("page.HomeView.see_more") }}</router-link>
              </div>

              <div v-if="!newsSearchStore?.tableListResults?.items" class="flex flex-col justify-center items-center w-full">
                <div><LoadingFormComponent /></div>
                <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
              </div>
              <div class="grid md:grid-cols-3 grid-cols-1 gap-2 mb-4">
                <router-link
                  v-for="data in newsSearchStore?.tableListResults?.items || []"
                  :to="{ name: 'NewsDetailView', params: { id: data?.id } }"
                  class="grid grid-cols-2 md:block gap-2 border border-[2px] rounded-[21px] overflow-hidden"
                >
                  <div class="aspect-[3/2] w-full overflow-hidden mr-0">
                    <img v-if="data?.thumbnail?.url" :src="data?.thumbnail?.url" alt="รูปภาพ" class="object-cover w-full h-full" />
                    <img v-else="image_url" :src="image_url" alt="รูปภาพ" class="object-cover w-full h-full" />
                  </div>
                  <div class="md:p-4 md:pt-2">
                    <h4 class="ttp-text-md font-bold text-ellipsis overflow-hidden line-clamp-2 mb-4">{{ $convertString($filterNewsByLocale(data?.translations, masterDataStore.lang())?.title) }}</h4>
                    <p class="ttp-text-sm text-ellipsis overflow-hidden line-clamp-3">{{ $convertString($filterNewsByLocale(data?.translations, masterDataStore.lang())?.description) }}</p>
                  </div>
                </router-link>
              </div>
            </section>

            <hr />

            <section id="poppular-place" class="mb-4">
              <div class="flex mb-4">
                <div class="w-full text-neutral-700 ttp-text-lg font-bold">{{ $t("page.HomeView.popular_places") }}</div>
                <router-link :to="{ name: 'PopularPlaceView' }" class="w-full ttp-text-sm text-right text-[#2C3483]">{{ $t("page.HomeView.see_more") }}</router-link>
              </div>
              <div class="swiper-container w-full">
                <div v-if="!popularPlace?.items" class="flex flex-col justify-center items-center w-full">
                  <div><LoadingFormComponent /></div>
                  <div class="ttp-text-xs text-black">{{ $t("layout.loading") }}</div>
                </div>
                <Swiper v-else :slidesPerView="2.75" :loop="true" :spaceBetween="5" :modules="modules" class="swiper-wrapper">
                  <SwiperSlide v-for="(result, number) in popularPlace?.items" :key="number" class="swiper-slide">
                    <router-link :to="{ name: 'PopularPlaceDetailView', params: { id: result?.id } }" class="relative flex gap-2 h-36 rounded-[21px] overflow-hidden">
                      <div class="relative w-full">
                        <div class="absolute w-full h-36 inset-0">
                          <img v-if="result.images" :src="result?.images[0]" alt="รูปภาพ" class="object-cover w-full h-full" />
                        </div>
                      </div>
                      <div class="absolute w-full text-white bottom-0 p-4 ttp-text-md leading-none font-bold">{{ $convertString(result?.name) }}</div>
                    </router-link>
                  </SwiperSlide>
                </Swiper>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  </div>
  <component v-if="historySearchStore.isBackgroundCheck" :is="defaultFormComponents.alert.backgroundCheck.component" v-bind="defaultFormComponents.alert.backgroundCheck.props" />
</template>

<style lang="css">
.swiper-horizontal {
  padding-bottom: 0.25rem !important;
}

.swiper-pagination-bullet-active {
  background-color: #2c3483 !important;
}

.swiper-pagination-horizontal {
  bottom: 0 !important;
}
</style>
