<template>
  <div id="page-container" class="flex flex-col mx-auto w-full min-w-[320px ttp-bg-graident-2 text-ttp-text-primary">
    <main id="page-content" class="flex max-w-full flex-auto flex-col">
      <div class="mx-auto flex w-full max-w-10xl justify-center">
        <div class="flex flex-col w-full max-w-5xl h-screen">
          <div class="flex items-center justify-center mx-2 my-5">
            <router-link :to="{ name: 'HomeView' }" class="w-10 text-white text-left">
              <ChevronLeftIcon class="flex-none inline-block ttp-icon-inside-box-02" />
            </router-link>
            <div class="w-full text-white font-bold text-center">
              <h1 class="text-white font-bold text-center ttp-text-2xl">{{ t("page.RegisterView.title") }}</h1>
              <p class="text-white text-center">{{ t("page.RegisterView.desc") }}</p>
            </div>
            <div class="w-10" />
          </div>

          <div class="grid grid-cols-12 gap-2 h-full">
            <div class="col-start-1 col-end-13 h-full">
              <div class="ttp-layout gradient-border w-full h-full mt-0 p-4">
                <h2 class="text-black font-bold ttp-text-2xl">{{ t("page.RegisterView.create") }}</h2>
                <p class="text-black">
                  {{ $t("page.RegisterView.login") }}&nbsp;
                  <router-link :to="{ name: 'LoginView' }" class="w-full m-auto"><u>Login</u></router-link>
                </p>
                <form @submit.prevent="onSubmit">
                  <div class="grid grid-cols-12 gap-2 py-4">
                    <FormInput v-model="email" :label="t('form.email.label')" :placeholder="t('form.email.placeholder')" :is-disable="loading" :error-message="errorMessage.email" class="col-start-1 col-end-13 md:col-start-1 md:col-end-13" required @change="errorMessage.email = ''">
                      <template #prefix>
                        <EnvelopeIcon class="ttp-icon-inside-box-03 text-ttp-placeholder" />
                      </template>
                    </FormInput>
                    <small class="col-start-1 col-end-13 md:col-start-1 md:col-end-13">{{ t("caution.email_1") }}</small>

                    <FormInput v-model="contactNumber" :label="t('form.phone.label')" :placeholder="t('form.phone.placeholder')" :is-disable="loading" :error-message="errorMessage.contactNumber" type="tel" class="col-start-1 col-end-13 md:col-start-1 md:col-end-13" required @change="errorMessage.contactNumber = ''">
                      <template #prefix>
                        <PhoneIcon class="ttp-icon-inside-box-03 text-ttp-placeholder" />
                      </template>
                    </FormInput>

                    <template v-if="setPassword">
                      <FormInput v-model="newPassword" :label="t('form.newPassword.label')" :placeholder="t('form.newPassword.placeholder')" :is-disable="loading" :error-message="errorMessage.newPassword" :type="showPassword ? 'text' : 'password'" class="col-start-1 col-end-13 md:col-start-1 md:col-end-13" required @change="errorMessage.newPassword = ''">
                        <template #prefix>
                          <LockClosedIcon class="ttp-icon-inside-box-03 text-ttp-placeholder" />
                        </template>
                        <template #suffix>
                          <a href="#" @click.prevent="showPassword = !showPassword">
                            <component :is="showPassword ? EyeIcon : EyeSlashIcon" class="ttp-icon-inside-box-03 text-ttp-placeholder" />
                          </a>
                        </template>
                      </FormInput>
                      <small class="col-start-1 col-end-13 md:col-start-1 md:col-end-13">{{ t("caution.password_1") }}</small>

                      <FormInput v-model="repeatPassword" :label="t('form.repeatPassword.label')" :placeholder="t('form.repeatPassword.placeholder')" :is-disable="loading" :error-message="errorMessage.repeatPassword" :type="showPassword ? 'text' : 'password'" class="col-start-1 col-end-13 md:col-start-1 md:col-end-13" required @change="errorMessage.repeatPassword = ''">
                        <template #prefix>
                          <LockClosedIcon class="ttp-icon-inside-box-03 text-ttp-placeholder" />
                        </template>
                        <template #suffix>
                          <a href="#" @click.prevent="showPassword = !showPassword">
                            <component :is="showPassword ? EyeIcon : EyeSlashIcon" class="ttp-icon-inside-box-03 text-ttp-placeholder" />
                          </a>
                        </template>
                      </FormInput>
                    </template>
                  </div>

                  <div class="ttp-between border-t-2 pt-4">
                    <div class="flex gap-2 w-full">
                      <ButtonDefaultComponent :is-loading="loading" :is-disable="loading" :title="t('btn.btn_next')" :class-enum-name="ENUM_COLOR.DEFAULT_1" :size="ENUM_SIZE.SMALL" class-tag-name="w-full m-auto" is-visible is-icon is-submit />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import FormInput from "@/components/Forms/Input/FormInput.vue";
import { ENUM_COLOR, ENUM_SIZE } from "@/configs/enums/enum";
import { useAuthStore } from "@/stores/PoliceTouristAndAgent/useAuthStore";
import { ChevronLeftIcon, EnvelopeIcon, EyeIcon, EyeSlashIcon, LockClosedIcon, PhoneIcon } from "@heroicons/vue/24/solid";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { useRouter } from "vue-router";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";

const router = useRouter();
const { t } = useI18n();
const authStore = useAuthStore();

const loading = ref<boolean>(false);

const email = ref<string>("");
const contactNumber = ref<string>("");
const newPassword = ref<string>("");
const repeatPassword = ref<string>("");

const showPassword = ref<boolean>(false);
const setPassword = ref<boolean>(true);

const errorMessage = ref<{ [key: string]: string }>({
  email: "",
  contactNumber: "",
  newPassword: "",
  repeatPassword: "",
});

const onSubmit = async () => {
  const rules = {
    email: yup
      .string()
      .required()
      .email()
      .test("email", t('errorMessage.duplicateEmail'), async (email) => {
        const duplicated = await authStore.checkDuplicateEmail(email);
        return !duplicated;
      }),
    contactNumber: yup.string().required(),
  };

  if (setPassword.value) {
    Object.assign(rules, {
      newPassword: yup.string().min(6).minLowercase(1).minUppercase(1).minNumbers(1),
      repeatPassword: yup.string().oneOf([yup.ref("newPassword")]),
    });
  }

  const schema = yup.object().shape(rules);

  try {
    loading.value = true;
    Object.keys(errorMessage.value).forEach((k) => (errorMessage.value[k] = ""));

    await schema.validate(
      { email: email.value, contactNumber: contactNumber.value, newPassword: newPassword.value, repeatPassword: repeatPassword.value },
      {
        abortEarly: false,
      }
    );

    await authStore.register({
      email: email.value,
      password: newPassword.value,
      contactNumberCountryCode: "TH",
      contactNumber: contactNumber.value,
    });

    router.push({ name: "RegisterInformationView" });
  } catch (e: any) {
    if (e instanceof yup.ValidationError == false) {
      return;
    }

    e.inner?.forEach((inner) => {
      if (!inner.path) {
        return;
      }

      errorMessage.value[inner.path] = inner.message;
    });

    return;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  console.log("on mounted");
  try {
    console.log("authStore.getProfile");
    const profile = await authStore.getProfile();
    console.log({ profile });
    email.value = profile.email;
    contactNumber.value = profile.contactNumber || "";
  } catch (e) {
    console.error("authStore.getProfile failed", e);
  }

  try {
    console.log("FirebaseAuthentication.getCurrentUser");
    const { user } = await FirebaseAuthentication.getCurrentUser();
    console.log({ user });
    if (user?.providerData?.length) {
      email.value = user.email || "";
      const [provider] = user.providerData;
      setPassword.value = provider.providerId === "password";
    }
  } catch (e) {
    console.error("FirebaseAuthentication.getCurrentUser failed", e);
  }
});
</script>
