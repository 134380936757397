<script lang="ts">
import { defineComponent, ref, watch, type PropType } from "vue";
import { ENUM_SIZE } from "@/configs/enums/enum";
import type { $string, AllowedTypes } from "@/configs/types/Shared/typeShare";

const FormRadioDefaultComponent = defineComponent({
  name: "FormRadioDefaultComponent",
  components: {},
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    textError: {
      type: String,
      default: "",
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    items: {
      type: Array as PropType<{ label: $string; value: $string }[]>,
      required: false,
    },
    value: {
      type: [String, Number, Boolean, Array, Object, "", null] as PropType<AllowedTypes>,
      required: false,
      default: null,
    },
    hiddenValues: {
      type: [Array],
      required: false,
      default: [],
    },
    classEnumName: {
      type: String,
      required: false,
      default: "text-ttp-primary",
    },
    size: {
      type: String,
      required: false,
      default: ENUM_SIZE.NORMAL,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    const handleInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("updateValue", target.checked ? target.value : "");
    };

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      handleInput,
      ENUM_SIZE,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
        this.toggleVisible();
      }
    },
  },
});
export default FormRadioDefaultComponent;
</script>

<template>
  <div v-if="isVisible" :class="classTagName ? classTagName : 'w-full'">
    <label :for="name" class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ title }} <span v-if="isRequired && !isSpecial" class="text-ttp-error">*</span></label>
    <div class="relative flex gap-2">
      <div v-for="item in items" class="flex items-center mb-2">
        <input type="radio" :id="`${name}-${item.label}`" :name="`${name}`" :value="item.value" @input="handleInput" :disabled="isDisable" :readonly="isReadonly" :checked="item.value == value" :class="`${[classEnumName, 'w-4 h-4 border-gray-300 focus:ring-ttp-ring bg-white', , $inArray(item.value, hiddenValues) ? 'hidden' : ''].join(' ')}`" />
        <label :for="`${name}-${item.label}`" :class="['ms-2 ttp-text-xs text-ttp-secondary dark:text-gray-400', $inArray(item.value, hiddenValues) ? 'hidden' : ''].join(' ')">{{ item.label }}</label>
      </div>
    </div>
    <small v-if="isError" class="relative text-ttp-text-error">{{ textError }}</small>
  </div>
</template>
