<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from "@headlessui/vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { ENUM_SIZE, ENUM_COLOR } from "@/configs/enums/enum";

const PopupSuccessComponent = defineComponent({
  name: "PopupSuccessComponent",
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    ButtonDefaultComponent,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    textTitle: {
      type: String,
      default: "typeGetTitle",
    },
    textDetail: {
      type: String,
      default: "Detail",
    },
    functionProp: {
      type: Function,
      default: () => {},
    },
    functionCancelProp: {
      type: Function,
      default: () => {},
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    class: {
      type: String,
      required: false,
      default: "",
    },
    classEnumName: {
      type: String,
      required: false,
      default: ENUM_COLOR.NEW,
    },
    hasCancel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {};
  },
  setup(props, { emit }) {
    const isOpenLocal = ref(props.isOpen);

    watch(
      () => props.isOpen,
      (newValue) => {
        isOpenLocal.value = newValue;
      }
    );

    function toggleOpen() {
      isOpenLocal.value = !isOpenLocal.value;
      emit("updateIsOpen", isOpenLocal.value);
    }

    onMounted(() => {
      emit("sendToggleOpen", toggleOpen);
    });

    return {
      isOpen: isOpenLocal,
      ENUM_COLOR,
      ENUM_SIZE,
      props,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
      }
    },
    handleCancelClick(e: Event) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      if (typeof this.functionCancelProp === "function") {
        this.functionCancelProp(e);
      }
    },
  },
});
export default PopupSuccessComponent;
</script>

<template>
  <div>
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="" class="relative z-9999">
        <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-100" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm"></div>
        </TransitionChild>
        <div class="fixed inset-0 overflow-y-auto p-4 lg:p-8 ttp-fixed-tourist-margin-mobile-header">
          <TransitionChild as="template" enter="ease-out duration-200" enter-from="opacity-0 scale-125" enter-to="opacity-100 scale-100" leave="ease-in duration-100" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-125">
            <DialogPanel class="w-full max-w-md mx-auto flex flex-col rounded-lg shadow-md bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800">
              <div class="relative p-4">
                <div class="w-14 h-14 mx-auto flex items-center justify-center">
                  <svg v-if="!htmlIcon" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="Vector" d="M20.5 0C9.47207 0 0.5 8.97207 0.5 20C0.5 31.0279 9.47207 40 20.5 40C31.5279 40 40.5 31.0279 40.5 20C40.5 8.97207 31.5279 0 20.5 0ZM20.5 3C29.9066 3 37.5 10.5934 37.5 20C37.5 29.4066 29.9066 37 20.5 37C11.0934 37 3.5 29.4066 3.5 20C3.5 10.5934 11.0934 3 20.5 3ZM27.9707 13.9863C27.5811 13.9977 27.2113 14.1602 26.9395 14.4395L18 23.3789L14.0605 19.4395C13.9223 19.2955 13.7568 19.1806 13.5736 19.1014C13.3904 19.0222 13.1932 18.9804 12.9937 18.9784C12.7941 18.9763 12.5962 19.0141 12.4114 19.0896C12.2266 19.165 12.0588 19.2766 11.9177 19.4177C11.7766 19.5588 11.665 19.7266 11.5896 19.9114C11.5141 20.0962 11.4763 20.2941 11.4784 20.4937C11.4804 20.6932 11.5222 20.8904 11.6014 21.0736C11.6806 21.2568 11.7955 21.4223 11.9395 21.5605L16.9395 26.5605C17.2208 26.8417 17.6022 26.9997 18 26.9997C18.3978 26.9997 18.7792 26.8417 19.0605 26.5605L29.0605 16.5605C29.2769 16.3498 29.4247 16.0786 29.4844 15.7825C29.5442 15.4864 29.5131 15.1792 29.3953 14.901C29.2776 14.6228 29.0786 14.3867 28.8244 14.2235C28.5702 14.0603 28.2726 13.9776 27.9707 13.9863Z" fill="#00A860" />
                  </svg>
                  <span v-else-if="htmlIcon && isIcon" v-html="htmlIcon" :class="class"></span>
                </div>
                <div class="my-5">
                  <h4 class="text-center text-gray-800 ttp-text-base font-medium leading-normal">
                    {{ textTitle }}
                  </h4>
                  <div class="text-center text-gray-500 ttp-text-xs font-normal leading-normal">
                    {{ textDetail }}
                  </div>
                </div>
              </div>
              <div class="space-x-2 bg-gray-50 px-5 py-4 text-left dark:bg-gray-700/50">
                <ButtonDefaultComponent :isSpecial="false" :isVisible="true" :isIcon="true" :isDisable="false" :htmlIcon="''" :title="$t('btn.btn_submit')" :classEnumName="classEnumName ? classEnumName : ENUM_COLOR.GREEN" classTagName="w-full" :size="ENUM_SIZE.SMALL" @click="handleClick" @touchstart="handleClick" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div> 
</template>
