<script lang="ts">
import type { AllowedStringTypes } from "@/configs/types/Shared/typeShare";
import { defineComponent, ref, watch, type PropType } from "vue";

const FormTextareaDefaultComponent = defineComponent({
  name: "FormTextareaDefaultComponent",
  components: {},
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    textError: {
      type: String,
      required: false,
      default: "",
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number, undefined] as PropType<AllowedStringTypes>,
      required: false,
      default: null,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    isLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    const handleInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("updateValue", target.value);
    };

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      handleInput,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
        this.toggleVisible();
      }
    },
  },
});
export default FormTextareaDefaultComponent;
</script>

<template>
  <div v-if="isVisible" :class="classTagName ? classTagName : 'w-full'">
    <label v-if="isLabel" :for="name" class="block mb-1 ttp-text-xs text-gray-900 dark:text-gray-50" :class="isRequired ? 'font-medium' : 'font-normal'">{{ title }} <span v-if="isRequired && !isSpecial" class="text-ttp-error">*</span></label>
    <div class="relative">
      <textarea :id="name" :name="name" :value="value" @input="handleInput" :placeholder="placeholder" :disabled="isDisable" :readonly="isReadonly" autocomplete="off" :class="[{ 'border-ttp-error': isError }, { 'pr-10': isIcon }]" class="bg-white border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-xs rounded-lg disabled:cursor-not-allowed disabled:bg-ttp-new block w-full p-2.5 pr-10"></textarea>
    </div>
    <small v-if="isError" class="relative text-ttp-text-error">{{ textError }}</small>
  </div>
</template>
