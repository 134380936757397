<template>
  <BreadcrumbComponent title="Dashboard" :breadcrumbs="breadcrumbs">
    <template #right>
      <div class="flex items-center basis-1/2 max-w-80">
        <VueDatePicker v-model="dates" :format="dateFormat" :month-picker="timeGroup === 'month'" :year-picker="timeGroup === 'year'" range :enable-time-picker="false" />
      </div>
    </template>
  </BreadcrumbComponent>

  <div class="ttp-layout">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
      <section class="col-span-2">
        <div class="grid grid-cols-2 md:grid-cols-4">
          <div class="border-[#E2E8F0] border-r-2 text-center">
            <dl class="space-y-1 py-5 px-2">
              <dt class="text-3xl font-bold text-[#2C3483]">
                {{ store.channelCounterReport[1155] + store.channelCounterReport.APPLICATION + store.channelCounterReport.WALK_IN }}
              </dt>
              <dd class="font-medium text-black">จำนวนแจ้งเหตุทั้งหมด</dd>
            </dl>
          </div>
          <div class="border-[#E2E8F0] border-r-2 text-center">
            <dl class="space-y-1 py-5 px-2">
              <dt class="text-3xl font-bold text-[#2C3483]">
                {{ store.channelCounterReport[1155] }}
              </dt>
              <dd class="font-medium text-black">จำนวนแจ้งเหตุผ่านสายด่วน 1155</dd>
            </dl>
          </div>
          <div class="border-[#E2E8F0] border-r-2 text-center">
            <dl class="space-y-1 py-5 px-2">
              <dt class="text-3xl font-bold text-[#2C3483]">
                {{ store.channelCounterReport.APPLICATION }}
              </dt>
              <dd class="font-medium text-black">จำนวนแจ้งเหตุผ่าน TPB APP</dd>
            </dl>
          </div>
          <div class="border-[#E2E8F0] text-center">
            <dl class="space-y-1 py-5 px-2">
              <dt class="text-3xl font-bold text-[#2C3483]">
                {{ store.channelCounterReport.WALK_IN }}
              </dt>
              <dd class="font-medium text-black">จำนวนแจ้งเหตุผ่านสถานี</dd>
            </dl>
          </div>
        </div>
      </section>
      <section v-if="timeGroup === 'day'" class="col-span-2">
        <h4 class="text-black text-lg font-bold mb-6">จำนวนการรับแจ้งเหตุ แยกตามสถานะ</h4>
        <div>
          <Bar
            :options="{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }"
            :data="{
              labels: statuses,
              datasets: [
                {
                  data: [store.statusReport[ENUM_INCIDENT_STATUS.NEW], store.statusReport[ENUM_INCIDENT_STATUS.ACCEPTED], store.statusReport[ENUM_INCIDENT_STATUS.WAITING], store.statusReport[ENUM_INCIDENT_STATUS.IN_PROGRESS], store.statusReport[ENUM_INCIDENT_STATUS.COMPLETED], store.statusReport[ENUM_INCIDENT_STATUS.IN_REVIEW], store.statusReport[ENUM_INCIDENT_STATUS.REPORTED], store.statusReport[ENUM_INCIDENT_STATUS.CLOSED], store.statusReport[ENUM_INCIDENT_STATUS.REJECTED]],
                  backgroundColor: ['#E5E7EB', '#2C3483', '#2C3483', '#FB923C', '#16A34A', '#11e0f7', '#1f2937', '#6B7280', '#DC2626'],
                },
              ],
            }"
          />
        </div>
      </section>
      <section v-else class="col-span-2">
        <h4 class="text-black text-lg font-bold mb-6">การรับแจ้งเหตุทั้งหมด แยกตามช่องทาง</h4>
        <div>
          <Bar
            :options="{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  align: 'center',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                  },
                },
              },
            }"
            :data="{
              labels: store.channelReport.map((group) => group.groupName),
              datasets: [ENUM_CHANNEL._1155, ENUM_CHANNEL.APPLICATION, ENUM_CHANNEL.WALK_IN].map((channel) => ({
                label: channelLabel[channel],
                backgroundColor: channelColor[channel],
                data: store.channelReport.map((r) => r.report[channel]),
              })),
            }"
          />
        </div>
      </section>
      <section>
        <h4 class="text-black text-lg font-bold mb-6">สถิติการแจ้งเหตุ แยกตามเพศ</h4>
        <div>
          <Doughnut
            :options="{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'bottom',
                align: 'center',
                labels: {
                  usePointStyle: true,
                  pointStyle: 'circle',
                  generateLabels: (chart: Chart): LegendItem[] => {
                    const [datasets] = chart.data.datasets;
                    const total = datasets.data.reduce<number>((t: number, data) => {
                      t += (data as number)
                      return t
                    }, 0)
                    return datasets.data.map<LegendItem>((data, i) => {
                      let gender: string = ''
                      switch (chart?.data?.labels?.[i])  {
                        case 'NONE':
                          gender = 'ไม่ระบุ';
                          break;
                        case 'MALE':
                          gender = 'ชาย';
                          break;
                        case 'FEMALE':
                          gender = 'หญิง';
                          break;
                      }

                      return {
                        text: `${gender} ${total == 0 ? 0 : Math.round((data as number)/total * 100)}%`,
                        fillStyle: (datasets.backgroundColor as string[])[i],
                        strokeStyle: (datasets.backgroundColor as string[])[i],
                        index: i,
                      }
                    });
                  },
                },
              },
            },
          }"
            :data="{
              labels: genders,
              datasets: [
                {
                  data: genders.map((g) => store.genderReport[g]),
                  backgroundColor: ['#9CA3AF', '#3C50E0', '#80CAEE'],
                },
              ],
            }"
          />
        </div>
      </section>
      <section>
        <h4 class="text-black text-lg font-bold mb-6">สถิติการแจ้งเหตุ แยกตามสัญชาติ</h4>
        <div>
          <table class="table-auto w-full text-black">
            <thead>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td class="whitespace-nowrap w-20">จำนวน (คน)</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in store.nationalityReport">
                <td style="width: 25px">
                  <img :src="`https://cdn.jsdelivr.net/gh/hampusborgos/country-flags@main/svg/${item.type?.code?.toLowerCase()}.svg`" width="25" height="auto" />
                </td>
                <td class="px-2">{{ item.type?.content?.[0]?.name }}</td>
                <td class="px-2 w-3/5">
                  <div class="flex h-4 w-full items-center overflow-hidden rounded-full bg-blue-100 dark:bg-blue-950">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="flex items-center justify-center self-stretch rounded-full bg-blue-600 text-xs font-medium text-white transition-all duration-500 ease-out" :aria-valuenow="Math.round((item.total * 100) / totalNationality)" :style="`width: ${Math.round((item.total * 100) / totalNationality)}%`">{{ Math.round((item.total * 100) / totalNationality) }}%</div>
                  </div>
                </td>
                <td class="px-2 text-center">{{ item.total }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section class="max-xl:col-span-2">
        <h4 class="text-black text-lg font-bold mb-6">5 อันดับการแจ้งเหตุ แยกตามประเภท</h4>
        <div>
          <table class="table-auto w-full text-black">
            <thead>
              <tr>
                <td class="w-12 text-sm text-gray-400 text-center">อันดับ</td>
                <td class="text-sm text-gray-400">ประเภทเรื่อง</td>
                <td class="w-20 text-center whitespace-nowrap text-sm text-gray-400">จำนวน (เหตุ)</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in store.incidentCategotyReport.slice(0, 5)">
                <td class="text-center">{{ index + 1 }}</td>
                <td class="px-2">
                  <div class="flex h-10 w-full items-center overflow-hidden rounded-sm bg-white">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="flex items-center self-stretch rounded-sm bg-blue-100 text-xs text-black transition-all duration-500 ease-out whitespace-nowrap pl-4" :aria-valuenow="Math.round((item.total * 100) / totalIncidentCategory)" :style="`width: ${Math.round((item.total * 100) / totalIncidentCategory)}%`">{{ item.type?.content?.[0]?.title }} ({{ Math.round((item.total * 100) / totalIncidentCategory) }}%)</div>
                  </div>
                </td>
                <td class="px-2 text-center">{{ item.total }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section class="max-xl:col-span-2 flex flex-col">
        <div class="mb-6">
          <h4 class="text-black text-lg font-bold">5 อันดับการแจ้งเหตุ แยกตามหัวเรื่อง</h4>
          <VueSelect
            v-model="incidentCategory"
            :options="incidentCategories"
            :reduce="({ code }: SelectOption) => code"
            label="label"
            placeholder="ประเภทเรื่อง"
            class="border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-xs rounded-lg sm:w-3/5"
          />
        </div>
        <table v-if="incidentCategory" class="table-fixed w-full text-black">
          <thead>
            <tr>
              <td class="w-12 text-sm text-gray-400 text-center">อันดับ</td>
              <td class="text-sm text-gray-400">หัวเรื่อง</td>
              <td class="w-20 text-center whitespace-nowrap text-sm text-gray-400">จำนวน (เหตุ)</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in store.incidentTopicReport.slice(0, 5)">
              <td class="text-center">{{ index + 1 }}</td>
              <td class="px-2">
                <div class="flex h-10 w-full items-center overflow-hidden rounded-sm bg-white">
                  <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="flex items-center self-stretch rounded-sm bg-blue-100 text-xs text-black transition-all duration-500 ease-out whitespace-nowrap pl-4" :aria-valuenow="Math.round((item.total * 100) / totalIncidentTopic)" :style="`width: ${Math.round((item.total * 100) / totalIncidentTopic)}%`">{{ item.type?.content?.[0]?.title }} ({{ Math.round((item.total * 100) / totalIncidentTopic) }}%)</div>
                </div>
              </td>
              <td class="px-2 text-center">{{ item.total }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else class="flex flex-col h-full justify-center">
          <EmptyDataComponent  title='กรุณาเลือก “ประเภทเรื่อง”<br />เพื่อดูหัวเรื่องย่อยที่ต้องการ' />
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import dayjs from "dayjs";
import objectSupport from "dayjs/plugin/objectSupport";
import VueSelect from "vue-select";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { Bar, Doughnut } from "vue-chartjs";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Chart, type LegendItem } from "chart.js";
import BreadcrumbComponent, { type Breadcrumb } from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/Breadcrumb.vue";
import { useDashboardStore, type TimeGroup } from "@/stores/PoliceCommandCenter/useDashboardStore";
import { ENUM_CHANNEL, ENUM_GENDER, ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import EmptyDataComponent from '@/components/Empty/EmptyData.vue';

type SelectOption = { label: string; code: string };

dayjs.extend(objectSupport);
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const route = useRoute();
const store = useDashboardStore();
const { t } = useI18n();
const masterDataStore = useMasterDataStore();

const channelColor = {
  [ENUM_CHANNEL.APPLICATION]: "#3C50E0",
  [ENUM_CHANNEL._1155]: "#FAA500",
  [ENUM_CHANNEL.WALK_IN]: "#2C3483",
};

const channelLabel = {
  [ENUM_CHANNEL.APPLICATION]: "TPB APP",
  [ENUM_CHANNEL._1155]: "สายด่วน 1155",
  [ENUM_CHANNEL.WALK_IN]: "สถานี",
};

const breadcrumbs = computed((): Breadcrumb[] => [
  {
    title: t("layout.menu.DashboardView"),
  },
  {
    title: t(`layout.menu.DashboardView_${route.meta.timeGroup}`),
    route: {
      name: `DashboardView_${route.meta.timeGroup}`,
    },
  },
]);

const timeGroup = computed(() => store.timeGroup);
const dates = ref<Array<Date | { month: number; year: number } | number>>([]);
const incidentCategory = ref<string>('');
const statuses = [ENUM_INCIDENT_STATUS.NEW, ENUM_INCIDENT_STATUS.ACCEPTED, ENUM_INCIDENT_STATUS.WAITING, ENUM_INCIDENT_STATUS.IN_PROGRESS, ENUM_INCIDENT_STATUS.COMPLETED, ENUM_INCIDENT_STATUS.IN_REVIEW, ENUM_INCIDENT_STATUS.REPORTED, ENUM_INCIDENT_STATUS.CLOSED, ENUM_INCIDENT_STATUS.REJECTED];
const genders = [ENUM_GENDER.NONE, ENUM_GENDER.MALE, ENUM_GENDER.FEMALE];

const dateFormat = (dates: Date[]) =>
  dates
    .map((date: Date) => {
      const d = dayjs(date);
      switch (store.timeGroup) {
        case "day":
          return d.format("DD/MM/YYYY");
        case "month":
          return d.format("MMMM YYYY");
        case "year":
          return d.format("YYYY");
      }
    })
    .join(" - ");

const setupDefaultDates = () => {
  const today = dayjs();

  switch (store.timeGroup) {
    case "day":
      dates.value = [today.endOf("day").toDate(), today.endOf("day").toDate()];
      break;
    case "month":
      dates.value = [
        {
          month: today.startOf("month").startOf("day").month(),
          year: today.startOf("month").startOf("day").year(),
        },
        {
          month: today.endOf("month").endOf("day").month(),
          year: today.endOf("month").endOf("day").year(),
        },
      ];
      break;
    case "year":
      dates.value = [today.startOf("year").startOf("day").year(), today.endOf("year").endOf("day").year()];
      break;
  }
};

const totalNationality = computed(() => store.nationalityReport.reduce((s, r) => s + r.total, 0));
const totalIncidentCategory = computed(() => store.incidentCategotyReport.reduce((s, r) => s + r.total, 0));
const totalIncidentTopic = computed(() => store.incidentTopicReport.reduce((s, r) => s + r.total, 0));

const handleDate = () => {
  if (!dates.value) {
    setupDefaultDates();
  }
  incidentCategory.value = '';

  const [start, end] = formatDateRange();

  [store.getChannelCounterReport, store.getStatusReport, store.getGenderReport, store.getNationalityReport, store.getIncidentCategoryReport].forEach((c) => c(start, end));

  switch (store.timeGroup) {
    case "month":
      store.getChannelReport(start, end, "MMM YYYY");
      break;
    case "year":
      store.getChannelReport(start.startOf("year"), end.endOf("year"), "YYYY");
      break;
  }
};

const formatDateRange = () => {
  return [...dates.value].map((d, idx) => {
    if (store.timeGroup === "year") {
      if (idx == 0) {
        return dayjs()
          .year(d as number)
          .startOf("year");
      }
      return dayjs()
        .year(d as number)
        .endOf("year");
    }
    return dayjs(d);
  });
}

const incidentCategories = computed(
  (): Array<SelectOption> =>
    masterDataStore.incidentCategories.map((item) => ({
      code: item.code as string,
      label: item.content.find((c) => c.locale === "TH")?.title as string,
    }))
);

onMounted(() => {
  masterDataStore.listIncidentCategory();
  store.setTimeGroup(route.meta.timeGroup as TimeGroup);
  setupDefaultDates();
  handleDate();
});

watch(
  () => route.name,
  () => {
    store.setTimeGroup(route.meta.timeGroup as TimeGroup);
    setupDefaultDates();
    handleDate();
    incidentCategory.value = '';
  }
);

watch(() => dates.value, handleDate);

watch(() => incidentCategory.value, () => {
  const [start, end] = formatDateRange();
  if (incidentCategory.value == '') return;
  store.getIncidentTopicReport(start, end, incidentCategory.value);
});
</script>

<style lang="css" scoped>
section {
  @apply bg-white p-5 rounded-md shadow-sm;
}
</style>
