import { ENUM_APP_CATEGORY_NAME, ENUM_APP_NAME } from "@/configs/enums/enum";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import axios, { type AxiosInstance } from "axios";

export const axiosClient = (params: { isFile: boolean }, type: ENUM_APP_NAME, cat?: ENUM_APP_CATEGORY_NAME): AxiosInstance => {
  const baseURL = type == ENUM_APP_NAME.POLICE_COMMAND_CENTER ? import.meta.env.VITE_TTP_COMMAND_CENTER_API : cat == ENUM_APP_CATEGORY_NAME.AGENT ? import.meta.env.VITE_TTP_POLICE_API : import.meta.env.VITE_TTP_TOURIST_API;
  const instance = axios.create({
    baseURL,
    ...(params.isFile
      ? {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        }
      : {}),
  });

  instance.interceptors.request.use(async (config) => {
    try {
      console.log('interceptors', 'get token')
      const { token } = await FirebaseAuthentication.getIdToken();
      console.log('interceptors', { token })
      config.headers.Authorization = token ? `Bearer ${token}` : null;
    } catch (e) {
      console.error('FirebaseAuthentication.getIdToken failed', e)
    }

    return config;
  });

  return instance;
};
