<script lang="ts">
// @ts-nocheck
import { defineComponent } from "vue";
import { useSharedStore } from "@/stores/PoliceTouristAndAgent/useSharedStore";
import PageContentBreadcrumbComponent from "@/layouts/AuthenticationLayouts/PoliceTouristAndAgent/PageContentBreadcrumbComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import { Capacitor } from "@capacitor/core";
import { setLocalStorage } from "@/configs/helpers/MainHelper";

export default defineComponent({
  name: "PageContentComponent",
  components: {
    PageContentBreadcrumbComponent,
  },
  data() {
    return {};
  },
  computed: {
    defaultFormComponents() {
      return {
        button: {
          btnPermission: {
            component: ButtonDefaultComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isIcon: true,
              isLoading: false,
              isDisable: false,
              componentIcon: this.$outlineIcons.Cog6ToothIcon,
              classIcon: "ttp-icon-inside-box-03 inline-block",
              title: this.$t("btn.btn_permission"),
              classEnumName: this.$enums.ENUM_COLOR.ACCEPTED,
            } as typeof ButtonDefaultComponent.props,
            click: () => {
              this.openAppSetting();
            },
          },
          btnConfirm: {
            component: ButtonDefaultComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isIcon: true,
              isLoading: false,
              isDisable: false,
              htmlIcon: "",
              isSubmit: true,
              title: this.$t("btn.btn_submit"),
              classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
              classTagName: "w-full",
            } as typeof ButtonDefaultComponent.props,
            click: async (_e: Event) => {
              this.sharedStore.isShowPermission = false;
            },
          },
        },
      };
    },
  },
  methods: {
    changeBackground() {
      if (this.$route.name == "HomeView") {
        return "background background-main md:bg-cover bg-contain";
      } else if (this.$route.name == "PopularPlaceView") {
        return "background background-popular-place md:bg-cover bg-contain";
      } else if (this.$route.path.includes('tourist/setting')) {
        return "bg-white md:bg-cover bg-contain";
      }

      return "ttp-bg-gradient-1 md:bg-cover bg-contain";
    },
    async openAppSetting() {
      if (Capacitor.isNativePlatform()) {
        await this.$PermissionsPlugin.openAppSettings();
      }
    },
  },
  setup() {
    const sharedStore = useSharedStore();
    return {
      sharedStore,
      isApp: Capacitor.isNativePlatform(),
    };
  },
});
</script>

<template>
  <main id="page-content" class="relative flex flex-auto flex-col max-w-full bg-ttp-bg-theme" :class="$inArray($getProjectType(), [$enums.ENUM_APP_CATEGORY_NAME.TOURIST]) ? changeBackground() : ''">
    <div class="container xl:max-w-7xl mx-auto lg:px-8 w-full">
      <PageContentBreadcrumbComponent />
      <div :class="$route?.meta?.isBackground ? 'ttp-layout' : ''">
        <router-view />
      </div>
    </div>
    <div v-if="sharedStore.isShowPermission" class="absolute bg-white flex flex-col w-full h-full text-center p-4 items-center justify-center z-[99999] opacity-95">
      <div class="absolute top-4 right-4">
        <button @click="sharedStore.isShowPermission = false" type="button" class="inline-flex justify-center items-center space-x-2 border font-semibold rounded-lg px-3 py-2 leading-5 ttp-text-xs border-gray-200 bg-white text-gray-800 hover:border-gray-300 hover:text-gray-900 hover:shadow-md focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700">
          <component :is="$solidIcons.XMarkIcon" class="inline-block ttp-icon-inside-box-03 -mx-0.5" />
        </button>
      </div>
      <div class="w-full flex items-center justify-center py-4">
        <template v-for="(name, key) in sharedStore.permissionResults">
          <svg v-if="name == 'Location'" id="icon-location" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-indigo-900">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
          </svg>
          <svg v-else-if="name == 'Microphone'" id="icon-microphone" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-indigo-900">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
          </svg>
          <svg v-else-if="name == 'Photo'" id="icon-photo" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-indigo-900">
            <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>
          <svg v-else-if="name == 'Camera'" id="icon-camera" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-indigo-900">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
          </svg>
          <svg v-else-if="name == 'Notification'" id="icon-notification" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16 text-indigo-900">
            <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0M3.124 7.5A8.969 8.969 0 0 1 5.292 3m13.416 0a8.969 8.969 0 0 1 2.168 4.5" />
          </svg>
          <span v-if="key != sharedStore.permissionResults.length - 1" class="ttp-text-xl p-4">+</span>
        </template>
      </div>

      <div class="w-full font-medium ttp-text-base text-indigo-900" v-html="$t('page.PermissionView.app_detail')"></div>
      <div class="w-full font-medium ttp-text-base text-indigo-900">
        <span v-for="(name, key) in sharedStore.permissionResults">{{ name }}{{ key != sharedStore.permissionResults.length - 1 ? ", " : "" }}</span>
      </div>
      <component v-if="isApp" class="w-full mt-4" :is="defaultFormComponents.button.btnPermission.component" v-bind="defaultFormComponents.button.btnPermission.props" @click="defaultFormComponents.button.btnPermission.click" />
      <div v-else class="text-left ttp-text-sm text-indigo-900" v-html="$t('page.PermissionView.web_detail')"></div>
      <component class="w-full my-4" :is="defaultFormComponents.button.btnConfirm.component" v-bind="defaultFormComponents.button.btnConfirm.props" @click="defaultFormComponents.button.btnConfirm.click" />
    </div>
  </main>
</template>
