<script lang="ts">
import { defineComponent } from "vue";
import { useNewsFormStore } from "./stores/useNewsFormStore";
import { useNewsDeleteStore } from "./stores/useNewsDeleteStore";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";
import type { $string } from "@/configs/types/Shared/typeShare";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import ButtonStyle2Component from "@/components/Forms/Button/ButtonStyle2Component.vue";
import PopupCancelComponent from "@/components/Popups/PopupCancelComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import PopupDeleteComponent from "@/components/Popups/PopupDeleteComponent.vue";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormTextareaDefaultComponent from "@/components/Forms/Textarea/FormTextareaDefaultComponent.vue";
import FormTextareaEditorComponent from "@/components/Forms/Textarea/FormTextareaEditorComponent.vue";
import FormInputDatepickerComponent from "@/components/Forms/Datepicker/FormInputDatepickerComponent.vue";
import FormCheckBoxToggleComponent from "@/components/Forms/CheckBox/FormCheckBoxToggleComponent.vue";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import dayjs from "dayjs";

export default defineComponent({
  name: "NewsManageView",
  components: {
    ButtonDefaultComponent,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  data() {
    return {
      componentName: this.$options.name,
      thumbnail: "" as any,
      images: [] as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          contentAt: {
            component: FormInputDatepickerComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isError: (this.newsFormStore.error.contentAt || "").length > 0,
              isIcon: false,
              isDisable: this.loading(),
              isRequired: this.newsFormStore.isRequired.contentAt.is,
              textError: this.$t(this.newsFormStore.isRequired.contentAt.placeholder),
              title: this.$t(this.newsFormStore.isRequired.contentAt.label),
              placeholder: this.$t(this.newsFormStore.isRequired.contentAt.placeholder),
              value: this.newsFormStore.result.contentAt,
              name: this.newsFormStore.isRequired.contentAt.name,
              functionProp: async (date: Date) => {
                this.newsFormStore.result.contentAt = dayjs(date).tz("Asia/Bangkok").toISOString();
              },
            } as typeof FormInputDefaultComponent.props,
          },
          publishedAt: {
            component: FormInputDatepickerComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isError: (this.newsFormStore.error.publishedAt || "").length > 0,
              isIcon: false,
              isDisable: this.loading(),
              isRequired: this.newsFormStore.isRequired.publishedAt.is,
              textError: this.$t(this.newsFormStore.isRequired.publishedAt.placeholder),
              title: this.$t(this.newsFormStore.isRequired.publishedAt.label),
              placeholder: this.$t(this.newsFormStore.isRequired.publishedAt.placeholder),
              value: this.newsFormStore.result.publishedAt,
              name: this.newsFormStore.isRequired.publishedAt.name,
              functionProp: async (date: Date) => {
                this.newsFormStore.result.publishedAt = dayjs(date).tz("Asia/Bangkok").toISOString();
              },
            } as typeof FormInputDefaultComponent.props,
          },
          highlight: {
            component: FormCheckBoxToggleComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isError: (this.newsFormStore.error.highlight || "").length > 0,
              isIcon: false,
              isDisable: false,
              isRequired: this.newsFormStore.isRequired.highlight.is,
              textError: this.$t(this.newsFormStore.isRequired.highlight.placeholder),
              title: this.$t(this.newsFormStore.isRequired.highlight.label),
              placeholder: this.$t(this.newsFormStore.isRequired.highlight.placeholder),
              value: this.newsFormStore.result.highlight ? "true" : "false",
              items: [{ label: "true", value: "true" }],
              name: this.newsFormStore.isRequired.highlight.name,
            } as typeof FormCheckBoxToggleComponent.props,
            onUpdateValue: (value: $string) => {
              this.newsFormStore.result.highlight = !(value == "");
            },
          },
          published: {
            component: FormCheckBoxToggleComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isError: (this.newsFormStore.error.published || "").length > 0,
              isIcon: false,
              isDisable: false,
              isRequired: this.newsFormStore.isRequired.published.is,
              textError: this.$t(this.newsFormStore.isRequired.published.placeholder),
              title: this.$t(this.newsFormStore.isRequired.published.label),
              placeholder: this.$t(this.newsFormStore.isRequired.published.placeholder),
              value: this.newsFormStore.result.published ? "true" : "false",
              items: [{ label: "true", value: "true" }],
              name: this.newsFormStore.isRequired.published.name,
            } as typeof FormCheckBoxToggleComponent.props,
            onUpdateValue: (value: $string) => {
              this.newsFormStore.result.published = !(value == "");
            },
          },
          isInternal: {
            component: FormCheckBoxToggleComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isError: (this.newsFormStore.error.isInternal || "").length > 0,
              isIcon: false,
              isDisable: false,
              isRequired: this.newsFormStore.isRequired.isInternal.is,
              textError: this.$t(this.newsFormStore.isRequired.isInternal.placeholder),
              title: this.$t(this.newsFormStore.isRequired.isInternal.label),
              placeholder: this.$t(this.newsFormStore.isRequired.isInternal.placeholder),
              value: this.newsFormStore.result.isInternal ? "true" : "false",
              items: [{ label: "true", value: "true" }],
              name: this.newsFormStore.isRequired.isInternal.name,
            } as typeof FormCheckBoxToggleComponent.props,
            onUpdateValue: (value: $string) => {
              this.newsFormStore.result.isInternal = !(value == "");
            },
          },
          th: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[0].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[0].title.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[0].title.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[0].title.label)} (TH)`,
                name: `translations[0].title`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[0].title.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "TH")].title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[0].title = value;
              },
            },
            description: {
              component: FormTextareaDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[0].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[0].description.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[0].description.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[0].description.label)} (TH)`,
                name: `translations[0].description`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[0].description.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "TH")].description,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[0].description = value;
              },
            },
            detail: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[0].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[0].detail.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[0].detail.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[0].detail.label)} (TH)`,
                name: `translations[0].detail`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[0].detail.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "TH")].detail,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[0].detail = value;
              },
            },
          },
          en: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[1].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[1].title.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[1].title.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[1].title.label)} (EN)`,
                name: `translations[1].title`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[1].title.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "EN")].title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[1].title = value;
              },
            },
            description: {
              component: FormTextareaDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[1].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[1].description.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[1].description.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[1].description.label)} (EN)`,
                name: `translations[1].description`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[1].description.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "EN")].description,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[1].description = value;
              },
            },
            detail: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[1].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[1].detail.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[1].detail.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[1].detail.label)} (EN)`,
                name: `translations[1].detail`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[1].detail.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "EN")].detail,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[1].detail = value;
              },
            },
          },
          cn: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[2].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[2].title.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[2].title.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[2].title.label)} (CN)`,
                name: `translations[2].title`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[2].title.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "CN")].title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[2].title = value;
              },
            },
            description: {
              component: FormTextareaDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[2].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[2].description.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[2].description.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[2].description.label)} (CN)`,
                name: `translations[2].description`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[2].description.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "CN")].description,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[2].description = value;
              },
            },
            detail: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[2].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[2].detail.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[2].detail.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[2].detail.label)} (CN)`,
                name: `translations[2].detail`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[2].detail.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "CN")].detail,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[2].detail = value;
              },
            },
          },
          jp: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[3].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[3].title.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[3].title.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[3].title.label)} (JP)`,
                name: `translations[3].title`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[3].title.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "JP")].title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[3].title = value;
              },
            },
            description: {
              component: FormTextareaDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[3].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[3].description.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[3].description.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[3].description.label)} (JP)`,
                name: `translations[3].description`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[3].description.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "JP")].description,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[3].description = value;
              },
            },
            detail: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[3].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[3].detail.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[3].detail.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[3].detail.label)} (JP)`,
                name: `translations[3].detail`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[3].detail.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "JP")].detail,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[3].detail = value;
              },
            },
          },
          kr: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[4].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[4].title.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[4].title.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[4].title.label)} (KR)`,
                name: `translations[4].title`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[4].title.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "KR")].title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[4].title = value;
              },
            },
            description: {
              component: FormTextareaDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[4].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[4].description.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[4].description.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[4].description.label)} (KR)`,
                name: `translations[4].description`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[4].description.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "KR")].description,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[4].description = value;
              },
            },
            detail: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[4].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[4].detail.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[4].detail.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[4].detail.label)} (KR)`,
                name: `translations[4].detail`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[4].detail.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "KR")].detail,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[4].detail = value;
              },
            },
          },
          ru: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[5].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[5].title.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[5].title.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[5].title.label)} (RU)`,
                name: `translations[5].title`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[5].title.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "RU")].title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[5].title = value;
              },
            },
            description: {
              component: FormTextareaDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[5].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[5].description.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[5].description.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[5].description.label)} (RU)`,
                name: `translations[5].description`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[5].description.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "RU")].description,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[5].description = value;
              },
            },
            detail: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.newsFormStore.error[`translations[5].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.newsFormStore.isRequired.translations[5].detail.is,
                textError: this.$t(this.newsFormStore.isRequired.translations[5].detail.placeholder),
                title: `${this.$t(this.newsFormStore.isRequired.translations[5].detail.label)} (RU)`,
                name: `translations[5].detail`,
                placeholder: this.$t(this.newsFormStore.isRequired.translations[5].detail.placeholder),
                value: this.newsFormStore.result.translations[this.$findTranslationIndexByLocale(this.newsFormStore.result.translations, "RU")].detail,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.newsFormStore.result.translations[5].detail = value;
              },
            },
          },
          button: {
            btnUploadImage: {
              click: () => {
                (document?.getElementById("file") as HTMLButtonElement).click();
              },
            },
            btnUploadImages: {
              click: () => {
                (document?.getElementById("files") as HTMLButtonElement).click();
              },
            },
            btnDeleteImage: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.TrashIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: !this.thumbnail || this.loading(),
                title: this.$t("btn.btn_delete_file"),
                classEnumName: this.$enums.ENUM_COLOR.RED,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.newsFormStore.isDelete = !this.newsFormStore.isDelete;
              },
            },
            btnSave: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isSubmit: true,
                title: this.$t(this.newsFormStore.result?.id ? "btn.btn_save" : "btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnCancel: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                title: this.$t("btn.btn_cancel"),
                classEnumName: this.$enums.ENUM_COLOR.DARK,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.$router.push({ name: "NewsView" });
              },
            },
            btnDelete: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: this.loading(),
                title: this.$t("btn.btn_delete"),
                classEnumName: this.$enums.ENUM_COLOR.RED,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.deleteNewsStore.isDelete = !this.deleteNewsStore.isDelete;
              },
            },
          },
          popup: {
            deletePopup: {
              component: PopupDeleteComponent,
              props: {
                isOpen: this.deleteNewsStore.isDelete,
                textTitle: this.$t("popup.error.delete_title"),
                textDetail: this.$t("popup.error.delete_detail"),
                functionProp: () => {
                  this.deleteNewsStore.isDelete = false;
                },
                functionCancelProp: () => {
                  this.deleteNewsStore.isDelete = false;
                },
                submitEvent: this.deleteNewsStore.onSubmit,
              } as typeof PopupDeleteComponent.props,
            },
            deleteImagePopup: {
              component: PopupDeleteComponent,
              props: {
                isOpen: this.newsFormStore.isDelete,
                textTitle: this.$t("popup.error.delete_file_image_title"),
                textDetail: this.$t("popup.error.delete_file_image_detail"),
                functionProp: () => {
                  this.newsFormStore.isDelete = false;
                },
                functionCancelProp: () => {
                  this.newsFormStore.isDelete = false;
                },
                submitEvent: (e: any) => {
                  this.thumbnail = "";
                  this.newsFormStore.onDeleteImageSubmit(e);
                },
              } as typeof PopupDeleteComponent.props,
            },
            cancelPopup: {
              component: PopupCancelComponent,
              props: {
                isOpen: this.newsFormStore.isCancel,
                textTitle: this.$t("popup.cancel.cancel_title"),
                textDetail: this.$t("popup.cancel.cancel_detail"),
                functionProp: () => {
                  this.newsFormStore.isCancel = false;
                  this.$router.push({ name: "NewsView" });
                  this.reset();
                },
                functionCancelProp: async () => {
                  this.newsFormStore.isCancel = false;
                },
              } as typeof PopupCancelComponent.props,
            },
          },
          alert: {
            deleteSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.deleteNewsStore.isSuccess,
                textTitle: this.$t("popup.success.delete_title"),
                textDetail: this.$t("popup.success.delete_detail"),
                functionProp: () => {
                  this.deleteNewsStore.isSuccess = false;
                  this.reset();
                  this.$router.push({ name: "NewsView" });
                },
                functionCancelProp: () => {
                  this.deleteNewsStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            deleteCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.newsFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.delete_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.newsFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            deleteImageSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.newsFormStore.isSuccess,
                textTitle: this.$t("popup.success.delete_image_title"),
                textDetail: this.$t("popup.success.delete_image_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.newsFormStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            deleteImageCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.newsFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.delete_image_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.newsFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            formSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.newsFormStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.detail"),
                functionProp: () => {
                  this.setting();
                  this.$router.push({ name: "NewsView" });
                },
                functionCancelProp: () => {
                  this.newsFormStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            formCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.newsFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.duplicate_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.newsFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
        },
      };
    },
  },
  methods: {
    async uploadImage(e: any) {
      let listFiles = e.target.files;

      if (!listFiles?.length) {
        return;
      }

      const files: File[] = [];
      for (const f of listFiles) {
        files.push(f);
      }

      const f = new fetchFile();
      const response = await f.Upload(files);
      this.newsFormStore.result.thumbnail = response[0].id;
      this.thumbnail = response;

      e.target.value = "";
      this.$configLightbox();
    },
    async uploadImages(e: any) {
      let listFiles = e.target.files;
      if (!listFiles?.length) {
        return;
      }

      const files: File[] = [];
      for (const f of listFiles) {
        files.push(f);
      }

      const f = new fetchFile();
      const response = await f.Upload(files);

      if (!this.newsFormStore.result.images) {
        this.newsFormStore.result.images = [];
      }
      this.newsFormStore.result.images.push(...response.map(({ id }: any) => id));
      this.images.push(...response);

      e.target.value = "";
    },
    loading() {
      return this.newsFormStore.loading || this.deleteNewsStore.loading || this.newsFormStore.loading;
    },
    async fetch() {
      this.newsFormStore.loading = true;
      if (this.$route.params.id) {
        this.deleteNewsStore.result.id = String(this.$route.params.id);
        await Promise.all([
          (async () => {
            const result = await this.newsFormStore.fetchNews.Get(this.$route.params.id as string);
            this.newsFormStore.result = result;

            // Get Thumbnail
            if (this?.newsFormStore?.result?.thumbnail) {
              this.thumbnail = await Promise.all(await this.newsFormStore.fetchFile.Get([this?.newsFormStore?.result?.thumbnail]));
            }

            // Get Images
            if (this?.newsFormStore?.result?.images?.length) {
              this.images = await Promise.all(await this.newsFormStore.fetchFile.Get(this?.newsFormStore?.result?.images));
            }

            this.$configLightbox();
          })(),
        ]);
      } else {
        this.newsFormStore.result.contentAt = dayjs(new Date()).tz("Asia/Bangkok").toISOString();
        this.newsFormStore.result.publishedAt = dayjs(new Date()).tz("Asia/Bangkok").toISOString();
      }
      this.newsFormStore.loading = false;
    },
    async reset() {
      await this.deleteNewsStore.$reset();
      await this.newsFormStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
      await this.$configLightbox();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const masterDataStore = useMasterDataStore();
    const newsFormStore = useNewsFormStore();
    const deleteNewsStore = useNewsDeleteStore();

    return {
      masterDataStore,
      newsFormStore,
      deleteNewsStore,
    };
  },
});
</script>

<template>
  <div class="ttp-page-header-1">
    <h2>{{ $t(($route?.meta as any).i18n) }}</h2>
  </div>
  <form @submit.prevent="newsFormStore.onSubmit">
    <div class="grid grid-cols-12 gap-2 p-4">
      <div class="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4">
        <div class="text-gray-800 ttp-text-xs leading-tight font-medium">รูปภาพหน้าปก</div>
      </div>
      <div class="swipe-photo flex col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4">
        <div v-if="!newsFormStore?.result?.thumbnail" :class="{ 'inline-flex ttp-icon-box-04 items-center justify-center rounded-lg bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500': true, 'border border-ttp-error': (newsFormStore.error.thumbnail || '').length > 0 }">
          <component :is="$solidIcons.PhotoIcon" class="inline-block ttp-icon-box-01" />
        </div>
        <div v-else class="swipe-photo flex gap-2">
          <a :href="thumbnail[0]?.url" target="_blank" class="flex w-full">
            <img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right mr-2" :src="thumbnail[0]?.url" alt="" />
          </a>
        </div>
        <div class="px-4 inline-flex items-center justify-center">
          <div>
            <div class="flex gap-2">
              <ButtonDefaultComponent :isSpecial="false" :isVisible="true" :isIcon="true" :componentIcon="$outlineIcons.PhotoIcon" :classIcon="'ttp-icon-inside-box-03 inline-block'" :isLoading="loading()" :title="$t('btn.btn_select')" :classEnumName="$enums.ENUM_COLOR.DEFAULT_1" :size="$enums.ENUM_SIZE.SMALL" @click="defaultFormComponents.form.button.btnUploadImage.click" />
              <input id="file" type="file" accept="image/*" hidden @change="uploadImage" />
              <component :is="defaultFormComponents.form.button.btnDeleteImage.component" v-bind="defaultFormComponents.form.button.btnDeleteImage.props" @click="defaultFormComponents.form.button.btnDeleteImage.click" />
            </div>
            <div class="mt-2">
              <small>{{ $t("caution.picture_1_2") }}</small>
            </div>
            <div>
              <small>{{ $t("caution.picture_2_2") }}</small>
            </div>
          </div>
        </div>
      </div>
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-7 mt-4" :is="defaultFormComponents.form.contentAt.component" v-bind="defaultFormComponents.form.contentAt.props" />
      <component classTagName="col-start-1 col-end-13 md:col-start-7 md:col-end-13 mt-4" :is="defaultFormComponents.form.publishedAt.component" v-bind="defaultFormComponents.form.publishedAt.props" />
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.highlight.component" v-bind="defaultFormComponents.form.highlight.props" @updateValue="defaultFormComponents.form.highlight.onUpdateValue" />
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.published.component" v-bind="defaultFormComponents.form.published.props" @updateValue="defaultFormComponents.form.published.onUpdateValue" />
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13" :is="defaultFormComponents.form.isInternal.component" v-bind="defaultFormComponents.form.isInternal.props" @updateValue="defaultFormComponents.form.isInternal.onUpdateValue" />
      <div class="col-start-1 col-end-13 border-2 rounded-lg">
        <TabGroup>
          <!-- Nav Tabs -->
          <TabList class="flex items-center gap-1 text-sm md:gap-2">
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาไทย</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาอังกฤษ</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาจีน</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาญี่ปุ่น</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาเกาหลี</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษารัสเซีย</span>
              </button>
            </Tab>
          </TabList>
          <!-- END Nav Tabs -->

          <!-- Tab Content -->
          <TabPanels class="px-4 pb-4">
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.th.title.component" v-bind="defaultFormComponents.form.th.title.props" @updateValue="defaultFormComponents.form.th.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.th.description.component" v-bind="defaultFormComponents.form.th.description.props" @updateValue="defaultFormComponents.form.th.description.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.th.detail.component" v-bind="defaultFormComponents.form.th.detail.props" @updateValue="defaultFormComponents.form.th.detail.onUpdateValue" />
            </TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.en.title.component" v-bind="defaultFormComponents.form.en.title.props" @updateValue="defaultFormComponents.form.en.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.en.description.component" v-bind="defaultFormComponents.form.en.description.props" @updateValue="defaultFormComponents.form.en.description.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.en.detail.component" v-bind="defaultFormComponents.form.en.detail.props" @updateValue="defaultFormComponents.form.en.detail.onUpdateValue" />
            </TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.cn.title.component" v-bind="defaultFormComponents.form.cn.title.props" @updateValue="defaultFormComponents.form.cn.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.cn.description.component" v-bind="defaultFormComponents.form.cn.description.props" @updateValue="defaultFormComponents.form.cn.description.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.cn.detail.component" v-bind="defaultFormComponents.form.cn.detail.props" @updateValue="defaultFormComponents.form.cn.detail.onUpdateValue"
            /></TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.jp.title.component" v-bind="defaultFormComponents.form.jp.title.props" @updateValue="defaultFormComponents.form.jp.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.jp.description.component" v-bind="defaultFormComponents.form.jp.description.props" @updateValue="defaultFormComponents.form.jp.description.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.jp.detail.component" v-bind="defaultFormComponents.form.jp.detail.props" @updateValue="defaultFormComponents.form.jp.detail.onUpdateValue" />
            </TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.kr.title.component" v-bind="defaultFormComponents.form.kr.title.props" @updateValue="defaultFormComponents.form.kr.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.kr.description.component" v-bind="defaultFormComponents.form.kr.description.props" @updateValue="defaultFormComponents.form.kr.description.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.kr.detail.component" v-bind="defaultFormComponents.form.kr.detail.props" @updateValue="defaultFormComponents.form.kr.detail.onUpdateValue" />
            </TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.ru.title.component" v-bind="defaultFormComponents.form.ru.title.props" @updateValue="defaultFormComponents.form.ru.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.ru.description.component" v-bind="defaultFormComponents.form.ru.description.props" @updateValue="defaultFormComponents.form.ru.description.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.ru.detail.component" v-bind="defaultFormComponents.form.ru.detail.props" @updateValue="defaultFormComponents.form.ru.detail.onUpdateValue"
            /></TabPanel>
          </TabPanels>
          <!-- END Tab Content -->
        </TabGroup>
      </div>
      <div class="ttp-text-xs font-normal text-gray-900 dark:text-gray-50 leading-tight col-start-1 col-end-13 mt-4">
        <div class="mb-2 font-medium">{{ $t("form.gallery.label") }}</div>
        <div id="ttp-gallery" class="flex gap-2 mb-4">
          <div class="swipe-photo flex gap-2 w-full">
            <div v-if="loading()" class="flex flex-col justify-center items-center w-full">
              <div><LoadingFormComponent /></div>
              <div class="ttp-text-xs">{{ $t("layout.loading") }}</div>
            </div>
            <div v-else-if="images?.length" v-for="(item, index) in images" :key="index" class="relative">
              <div
                href="#"
                @click="
                  () => {
                    $findByIdAndRemove(images, item?.id);
                    $removeById(newsFormStore?.result?.images, item?.id);
                  }
                "
              >
                <component :is="$solidIcons.XMarkIcon" class="cursor-pointer inline-block ttp-icon-inside-box-03 border-2 rounded-full border-red-600 text-red-600 absolute right-0 top-0 z-10" />
              </div>
              <a :href="item?.url ? item?.url : '#'" target="_blank"><img class="object-cover object-center w-14 h-14 sm:w-36 sm:h-36 max-w-full rounded-lg float-right ml-2" :src="item?.url" alt="" /></a>
            </div>
            <div v-else>{{ $t("layout.not_found_image") }}</div>
          </div>
        </div>
        <div class="flex gap-2 mt-4">
          <ButtonDefaultComponent :isSpecial="false" :isVisible="true" :isIcon="true" :componentIcon="$outlineIcons.PhotoIcon" :classIcon="'ttp-icon-inside-box-03 inline-block'" :isLoading="loading()" :isDisable="loading()" :title="$t('btn.btn_select')" :classEnumName="$enums.ENUM_COLOR.DEFAULT_1" :size="$enums.ENUM_SIZE.SMALL" @click="defaultFormComponents.form.button.btnUploadImages.click" />
          <input id="files" type="file" accept="image/*" multiple hidden @change="uploadImages" />
        </div>
      </div>
    </div>
    <div class="ttp-between p-4 border-t-2">
      <div class="flex gap-2">
        <component :is="defaultFormComponents.form.button.btnSave.component" v-bind="defaultFormComponents.form.button.btnSave.props" />
        <component :is="defaultFormComponents.form.button.btnCancel.component" v-bind="defaultFormComponents.form.button.btnCancel.props" @click="defaultFormComponents.form.button.btnCancel.click" />
      </div>
      <div v-if="$route.params.id">
        <component :is="defaultFormComponents.form.button.btnDelete.component" v-bind="defaultFormComponents.form.button.btnDelete.props" @click="defaultFormComponents.form.button.btnDelete.click" />
      </div>
    </div>
  </form>

  <!-- Popup Delete -->
  <component :is="defaultFormComponents.form.popup.deletePopup.component" v-bind="defaultFormComponents.form.popup.deletePopup.props" />
  <!-- Popup Delete Image -->
  <component :is="defaultFormComponents.form.popup.deleteImagePopup.component" v-bind="defaultFormComponents.form.popup.deleteImagePopup.props" />
  <!-- Popup Cancel -->
  <component :is="defaultFormComponents.form.popup.cancelPopup.component" v-bind="defaultFormComponents.form.popup.cancelPopup.props" />

  <!-- Popup Delete Success & Caution -->
  <component v-if="deleteNewsStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.alert.deleteSuccess.component" v-bind="defaultFormComponents.form.alert.deleteSuccess.props" />
  <component v-else-if="deleteNewsStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.form.alert.deleteCaution.component" v-bind="defaultFormComponents.form.alert.deleteCaution.props" />

  <!-- Popup Delete Image Success & Caution -->
  <component v-if="newsFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.alert.deleteImageSuccess.component" v-bind="defaultFormComponents.form.alert.deleteImageSuccess.props" />
  <component v-else-if="newsFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.form.alert.deleteImageCaution.component" v-bind="defaultFormComponents.form.alert.deleteImageCaution.props" />

  <!-- Popup Form Success & Caution -->
  <component v-if="newsFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.alert.formSuccess.component" v-bind="defaultFormComponents.form.alert.formSuccess.props" />
  <component v-else-if="newsFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.form.alert.formCaution.component" v-bind="defaultFormComponents.form.alert.formCaution.props" />
</template>
