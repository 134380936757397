<script lang="ts">
import type { AllowedTypes } from "@/configs/types/Shared/typeShare";
import { defineComponent, ref, watch, type PropType } from "vue";

const FormInputDefaultComponent = defineComponent({
  name: "FormInputDefaultComponent",
  props: {
    isSpecial: {
      type: Boolean,
      required: false,
      default: false,
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisable: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isStartIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    textError: {
      type: String,
      default: "",
    },
    htmlIcon: {
      type: String,
      required: false,
      default: "",
    },
    componentIcon: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    componentIcon_2: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    componentIcon_3: {
      type: [Object, Function],
      required: false,
      default: function () {
        return {};
      },
    },
    classIcon: {
      type: String,
      required: false,
      default: "",
    },
    classTagName: {
      type: String,
      required: false,
      default: "",
    },
    classInputName: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
    isFlipType: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: [String, Number, Boolean, Array, Object, "", null] as PropType<AllowedTypes>,
      required: false,
      default: null,
    },
    functionProp: {
      type: Function,
      required: false,
      default: () => {},
    },
    isKeydownNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
    isKeydownText: {
      type: Boolean,
      required: false,
      default: false,
    },
    isKeydownEnglish: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isVisible = ref(props.isVisible);

    watch(
      () => props.isVisible,
      () => {
        isVisible.value = !isVisible.value;
      }
    );

    const handleInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("updateValue", target.value);
      emit("update:modelValue", target.value);
    };
    const handleBlur = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit("blur", target.value);
    };
    const handleChangeType = () => {
      emit("type", props.type ? "password" : "text");
    };

    return {
      isVisible,
      toggleVisible: () => {
        isVisible.value = !isVisible.value;
      },
      handleInput,
      handleBlur,
      handleChangeType,
    };
  },
  methods: {
    handleClick(e: Event) {
      if (typeof this.functionProp === "function") {
        this.functionProp(e);
        this.toggleVisible();
      }
    },
  },
});
export default FormInputDefaultComponent;
</script>

<template>
  <div v-if="isVisible" :class="classTagName ? classTagName : 'w-full'">
    <label v-if="isLabel && !isSpecial" :for="name" class="block mb-1 ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ title }} <span v-if="isRequired" class="text-ttp-error">*</span></label>
    <div class="relative">
      <div v-if="isIcon" class="absolute inset-y-0 flex items-center pointer-events-none" :class="isStartIcon ? 'start-2' : 'end-2'">
        <span v-if="htmlIcon" v-html="htmlIcon"></span>
        <component v-if="!$isObjectEmpty(componentIcon)" :is="componentIcon" :class="classIcon" />
      </div>
      <input
        @keydown="(e:any)=>{
          if(isKeydownNumber){
            $handleKeydown(e, 'number');
            handleInput(e)
          }
          if(isKeydownText){
            $handleKeydown(e, 'text');
            handleInput(e)
          }
          if(isKeydownEnglish){
            $handleKeydown(e, 'english');
            handleInput(e)
          }
      }"
        step="any"
        :id="name"
        :type="type"
        :name="name"
        :value="value"
        @input="handleInput"
        @blur="handleBlur"
        :placeholder="isLabel ? placeholder : title"
        :disabled="isDisable"
        :readonly="isReadonly"
        :maxlength="maxLength"
        autocomplete="off"
        :class="[{ 'border-ttp-error': isError }, { 'pl-10': isIcon && isStartIcon }, { 'pr-10': isIcon && !isStartIcon }, classInputName]"
        class="z-0 bg-white border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-xs rounded-lg disabled:cursor-not-allowed disabled:bg-ttp-new block w-full p-2.5"
      />
      <div
        v-if="isIcon"
        @click.prevent="
          () => {
            handleChangeType();
          }
        "
        class="absolute inset-y-0 flex items-center z-50"
        :class="[!isStartIcon ? 'start-2' : 'end-2', 'cursor-pointer']"
      >
        <span v-if="htmlIcon" v-html="htmlIcon"></span>
        <component v-if="type == 'password' && !$isObjectEmpty(componentIcon_2)" :is="componentIcon_2" :class="[classIcon]" />
        <component v-else-if="type == 'text'" :is="componentIcon_3" :class="[classIcon]" />
      </div>
    </div>
    <small v-if="isError && isLabel" class="relative text-ttp-text-error z-0">{{ textError }}</small>
  </div>
</template>
