export default {
  layout: {
    appName: "관광 경찰",
    menu: {
      AlertView: `알림 관리`,
      AlertManageView: `알림`,
      PreviewView: `미리보기`,
      HomeView: `홈`,
      LogoutView: `로그아웃`,
      NotFoundView: `페이지를 찾을 수 없음`,
      MaintenanceView: `유지 관리`,
      LoginView: `로그인`,
      ForgotPasswordView: `비밀번호 변경`,
      AllIncidentView: `모든 사건`,
      IncidentView: `사건 관리`,
      NewIncidentView: `새로운 사건`,
      AcceptedIncidentView: `수락된 사건`,
      WaitingIncidentView: `대기 중인 사건`,
      CurrentIncidentView: `현재 사건`,
      ReportingIncidentView: `보고된 사건`,
      ClosingIncidentView: `종료 중인 사건`,
      ClosedIncidentView: `종료된 사건`,
      RejectedIncidentView: `거부된 사건`,
      ConferenceView: `NT 컨퍼런스`,
      OverviewView: `개요`,
      DashboardView: `대시보드`,
      DashboardIncidentTypeView: `사건 유형`,
      ProfileView: `내 계정`,
      SettingMenuView: `내 계정`,
      ProfileEditView: `사용자 프로필`,
      PasswordSettingView: `비밀번호 설정`,
      UserView: `사용자 관리`,
      UserManageView: `사용자 프로필`,
      TouristView: `관광객 관리`,
      TouristManageView: `관광객 프로필`,
      ChatView: `채팅`,
      IncidentManageView: `사건 관리`,
      IncidentNoticeView: `사건 통지서`,
      IncidentReportView: `보고서`,
      HistoryView: `기록`,
      HistoryNoticeView: `사건 통지서`,
      HistoryReportView: `온라인 시스템에서 접수된 사건에 대한 예비 보고서`,
      ReportIncidentView: `보고서`,
      VoiceCallView: `음성 통화`,
      OverviewReportView: `보고서`,
      NewsView: "뉴스 관리",
      NewsManageView: "뉴스",
      NewsInternalView: "내부 뉴스",
      PopularPlaceView: "인기 장소",
    },
    menu_head: {
      PreviewTitle: "예시",
      AccountTitle: "계정",
      UserTitle: "회원",
      TouristTitle: "관광객",
      IncidentTitle: "사건 보고",
      SupportTitle: "지원 프로그램",
      ProfileTitle: "프로필",
      HistoryTitle: "사건 보고",
      DashboardTitle: "계시판",
      NewsTitle: "Announcement",
    },
    menu_bottom: {
      home_title: "홈",
      report_title: "보고서 보내",
      history_title: "기록",
      profile_title: "프로필",
    },
    by: "Powered by",
    create: "만들기",
    update: "변경",
    edit_address: "주소 변경",
    edit_address_placeholder: "주소를 입력하십시오",
    please_input: "입력해 주십시오.",
    not_found: "정보를 찾을 수 없습니다.",
    not_found_image: "사진을 없습니다.",
    loading: "로드중",
    all: "전체 보기",
    case: "사건",
    non: "지정하지 않았습니다.",
    non_image: "아니요, 원하지 않습니다",
    non_location: "위치를 공유하고 싶지 않아요",
    select_picture: "사진 선택",
    result: "결과",
    anonymous: "익명",
    gender: {
      male: "남성",
      female: "여성",
    },
    date: `Date`,
    error_detail: `An error has occurred. Please contact the public relations department.`,
  },
  divider: {
    or_login_with: "...으로 로그인",
  },
  page: {
    AllIncidentView: {
      title_1: `직원이 도착하는 데 걸리는 시간:`,
      title_2: `직원이 도착할 예상 시간:`,
      title_3: `직원이 현장에 도착한 시간:`,
      title_4: `현장에서 걸린 시간:`,
      waiting_1: `직원이 도착 중`,
      waiting_2: `잠시만 기다려주세요...`,
      status_1: `사고 해결 성공`,
      status_2: `작업 종료`,
      status_3: `보고서 취소됨`,
    },
    PermissionView: {
      app_detail: `도움말에 접근하시려면, 다음과 같이 항상 애플리케이션 접근권한을 활성화해 주시기 바랍니다.`,
      web_detail: `<hr class="my-2" />
      <h2 class="ttp-text-lg font-medium text-center">브라우저에서 권한을 여는 단계</h2>
      <hr class="my-2" />
      <div>1. 브라우저 열기</div>
      <div>2. 화면 오른쪽 상단의 세 점을 클릭하여 메뉴를 엽니다</div>
      <div>3. "설정" (Settings) 선택</div>
      <div>4. 아래로 스크롤하여 "개인정보 및 보안" (Privacy and security) 클릭</div>
      <div>5. "사이트 설정" (Site settings) 선택</div>
      <hr class="my-2" />
      <h3 class="ttp-text-base font-medium">권한:</h3>
      <div>- 위치: "위치" 클릭하고 "사이트가 위치 정보를 요청할 수 있음" 설정</div>
      <div>- 카메라: "카메라" 클릭하고 "사이트가 카메라 사용을 요청할 수 있음" 설정</div>
      <div>- 마이크: "마이크" 클릭하고 "사이트가 마이크 사용을 요청할 수 있음" 설정</div>
      <div>- 알림: "알림" 클릭하고 "사이트가 알림을 보낼 수 있음" 설정</div>
      <div>- 사진: 사이트가 파일에 접근할 수 있는 권한을 요청할 경우, 각 사이트에 대해 파일 접근 권한을 설정할 수 있습니다</div>
   `,
    },
    NewsInternalDetailView: {
      not_found_gallery: `앨범을 찾을 수 없음`,
      gallery: `갤러리`,
    },
    NewsDetailView: {
      not_found_gallery: `앨범을 찾을 수 없음`,
    },
    NewsInternalView: {
      gallery: `갤러리`,
    },
    NewsView: {
      title: `뉴스`,
      not_found: `뉴스를 찾을 수 없음`,
    },
    PreviewView: {
      title: `미리보기`,
    },
    HomeView: {
      title: `홈`,
      hello_txt: `안녕하세요`,
      welcome_txt: `태국에 오신 것을 환영합니다.`,
      welcome_detail_txt: `관광 경찰, 당신의 첫 번째 친구`,
      incident_status: `사건 상태`,
      popular_places: `인기 장소`,
      news: `뉴스`,
      box_title: `관광 경찰에 사건을 신고하시겠습니까?`,
      box_detail: `관광 경찰에게 질문에 답하려면 버튼을 누르세요`,
      sos_title: `긴급 도움이 필요하십니까?`,
      sos_detail: `긴급 전화를 걸려면 버튼을 누르세요`,
      sos_btn: `SOS`,
      see_more: `더보기`,
    },
    LoginView: {
      title: "로그인",
      subtitle: "Command 센터에 환영합니다.",
      agent_subtitle: "Welcome to Agent",
      lang: "언어 선택",
    },
    ForgotPasswordView: {
      title: "비밀번호를 잊으셨나요?",
      detail_step_1: "Don't worry, we'll get you back! Your email.",
      detail_step_2: "이메일로 보낸 OTP를 입력하세요.",
      detail_step_3: "비밀번호를 입력하세요.",
      detail_step_verify: "본인확인",
      create: "새 비밀번호 만들기",
      desc: "증명하기 위해 이메일을 입력해 주세요.",
      email: "We'll send a 6-digit code to your email.",
      otp: "OTP 번호 확인",
      otp_send_to: "비밀번호는 …. 으로 보냈습니다.",
      otp_resend: "(เวลา)분 후 다시 보내",
    },
    ResetPasswordView: {
      title: "비밀번호 변경되었습니다.",
      detail: "비밀번호 변경되었습니다.",
    },
    NotFoundView: {
      status_number: "404",
      title: "어색하네",
      detail: `찾고 있는 페이지를 찾을 수 없는 것 같습니다. 이동되었거나 삭제되었을 수 있습니다. 죄송합니다! 웹 애플리케이션에서 검색을 시도해 주세요.`,
    },
    MaintenanceView: {
      title: "정비중입니다.",
      detail: `잠시만 기다려 주세요, 저희 웹사이트가 개선 중이며 곧 더 맛있는 베이글보다 더 멋진 모습으로 돌아올 예정입니다. 기다려 주셔서 감사합니다. 정말 멋진 것을 곧 선보일게요!`,
    },
    TouristManageView: {
      record_title: "연락한 기록(최근)",
    },
    OverviewView: {
      title_left: "알림",
      title_right: "긴근 통보 알림",
      tab_left: "새 신고 제출",
      tab_right: "관리됨",
      not_found: "새 신고 없음",
      tab_new: "새",
      tab_in_progress: "진행중",
      tab_successful: "변경완료",
    },
    IncidentManageView: {
      title: "사건 관리",
      document_number: "사건 번호",
      receive_date: "신고 날짜",
      information: "신고자 정보",
      history: "관리기록",
      emergency_contact: "긴급 상황에서 연락 가능한 사람",
    },
    IncidentNoticeView: {
      history: "기록",
      number_code: "사건번호",
    },
    RegisterView: {
      title: "회원가입",
      desc: "이메일로",
      create: "계정 만들기",
      login: "계정이 있으셨나요?",
    },
    ChatView: {
      title: "보호자",
      location_title: "위치",
      reply_message: `메시지에 답장`,
      make_a_call: `전화 걸기`,
      hang_up: `전화 끊기`,
      answer_a_call: `전화 받기`,
      response_voice_call: `음성 통화로 응답`,
      response_bot: `보호자(봇)의 응답`,
      response_police: `경찰의 응답`,
      end_conversation: `대화 종료`,
      detail: `새로운 사건 보고가 있으면 저희에게 연락주세요`,
    },
    ChatManageView: {
      title: "수호자",
      location_title: "위치",
    },
    MessageView: {
      title: "메시지",
    },
    PreChatView: {
      title: "사건 신고",
      sub_title: "사건 신고",
      detail: "사건을 신고하고 태국 관광 경찰에 도움을 요청하세요. 알리는 것이 아닙니다.",
    },
    VoiceCallView: {
      hungUp: {
        hero: 'The call has ended.',
        title: 'Have you finished reporting your issue?',
        thankYou: {
          title: 'Thank you for information',
          reportOn: 'Your report no. is',
          description: 'Our tourist police will assist you as soon as possible. You can check the status of your report in history',
        },
        button: {
          confirm: 'Yes, I have',
          callAgain: 'Call 1155',
        }
      }
    },
    HistoryPoliceTouristAndAgentView: {
      title: "사건 기록",
    },
    HistoryDetailPoliceTouristAndAgentView: {
      title: "사건 기록",
      report_no: "사건번호",
      report_info: "사건번호",
      not_found_detail: "사건기록이 없습니다.",
    },
    ReportIncidentView: {
      title: "보고서 보내",
      guardian_chatbot: "챗봇 수호자",
      report_incident_with_chatbot: "챗봇으로 사건 보고",
      sos_report: "SOS신고",
      share_location: "이 위치를 관광 경찰과 공유",
    },
    ProfileMenuView: {
      title: "프로필",
    },
    ProfileEditTouristView: {
      title: "프로필 변경",
    },
    ProfileSocialMediaAccountView: {
      title: "소셜미디어 계정",
      link_txt: "링크",
    },
    ProfileUnlinkView: {
      link_google_txt: `Googleアカウントに接続されています`,
      link_fb_txt: `Facebookアカウントに接続されています`,
      link_apple_txt: `Appleアカウントに接続されています`,
    },
    SettingContactUsView: {
      title: `お問い合わせ`,
    },
    SettingLanguageView: {
      title: `言語`,
    },
    SettingNotificationView: {
      title: `通知`,
      push_notifications_txt: `プッシュ通知`,
      chat_txt: `チャット通知`,
    },
    SettingSecurityPrivacyChangePasswordView: {
      title: `パスワードを変更する`,
    },
    SettingSecurityPrivacyView: {
      title: `セキュリティとプライバシー`,
      allow_location: `私の位置の追跡を許可する`,
      allow_camera: `カメラへのアクセスを許可する`,
      allow_photo: `写真アルバムへのアクセスを許可する`,
      allow_mic: `マイクへのアクセスを許可する`,
    },
    PopularPlaceView: {
      not_found: `場所が見つかりません`,
    },
    RegisterSuccessView: {
      head: `登録完了！`,
      title: `確認完了！`,
      welcome: `ようこそ`,
      detail_1: `全力でサポートさせていただきます。私たちを信頼していただきありがとうございます`,
      detail_2: `あなたのアカウントが準備完了しました。クリックするとホームページに移動します`,
    },
  },
  btn: {
    btn_permission: `개인정보 설정`,
    btn_readmore: `더 읽기`,
    btn_skip: `건너뛰기`,
    btn_go_home: `홈페이지로 돌아가기`,
    btn_background_check: `배경 확인`,
    btn_unlink: `연결 해제`,
    btn_destination_stoped_incident: `목적지에 도착하여 사건을 중지`,
    btn_repair_report: `수정`,
    btn_google_map: `Google 지도 보기`,
    btn_reset: `초기화`,
    btn_receieve_report: `작업 종료`,
    btn_map: `지도`,
    btn_share: `공유`,
    btn_register: `가입하기`,
    btn_create_user: `사용자 만들기`,
    btn_submit: `제출`,
    btn_confirm: `확인`,
    btn_select: `파일 선택`,
    btn_delete: `이 데이터 삭제`,
    btn_delete_file: `파일 삭제`,
    btn_delete_image: `이미지 삭제`,
    btn_delete_account: `계정 삭제`,
    btn_send_email: `초대 이메일 보내기`,
    btn_cancel: `취소`,
    btn_save: `저장`,
    btn_next: `다음`,
    btn_accept: `수락`,
    btn_confirm_save: `저장 확인`,
    btn_incident: `사건`,
    btn_record: `관리 기록`,
    btn_assign: `할당`,
    btn_report: `보고서 제출`,
    btn_edit: `수정`,
    btn_back: `뒤로`,
    btn_manage: `관리`,
    btn_export: `데이터 내보내기`,
    btn_new_case: `사건 만들기`,
    btn_view_report: `보고서 보기`,
    btn_back_dashboard: `대시보드로 돌아가기`,
    btn_forgot_password: `비밀번호를 잊으셨나요`,
    btn_sign_in: `로그인`,
    btn_change_password: `비밀번호 변경`,
    btn_back_login: `로그인 페이지로 돌아가기`,
    btn_not_receive: `코드를 받지 못하셨나요?`,
    btn_resend_code: `코드 재전송`,
    btn_search: `검색`,
    btn_reject: `거부`,
    btn_all: `모두 보기`,
    btn_receive: `수락`,
    btn_view_notification: `알림 보기`,
    btn_manage_history: `관리 기록`,
    btn_chat: `채팅으로 이동`,
    btn_login_with_phone: `휴대폰 번호로 계속하기`,
    btn_fb: `Facebook으로 계속하기`,
    btn_google: `Google로 계속하기`,
    btn_apple: `Apple로 계속하기`,
    btn_successful: `사건 보고서 작성`,
    btn_edit_completed: `작업 결과 저장`,
    btn_location_completed: `목적지에 도착하여 사건을 중지`,
    btn_edit_report: `보고서 제출`,
    btn_approve_report: `보고서 승인`,
    btn_cancel_report: `보고서 취소`,
    btn_select_default: `선택`,
    btn_non: `지정되지 않음`,
    btn_no: `아니요, 아니에요`,
    btn_fill_in_manually: `수동으로 입력`,
    btn_start_report: `보고 시작`,
    btn_get_report: `긴급 사건 보고`,
    btn_logout: `네, 로그아웃`,
    btn_news: `뉴스 만들기`,
    btn_submit_work: `작업 결과 저장`,
    btn_alert: `알림 만들기`,
    btn_download: `다운로드`,
  },
  popup: {
    cancel: {
      cancel_title: "취소 확인",
      cancel_detail: "정보 업데이트 취소를 확인하십니까?",
    },
    reject: {
      reject_title: `보고서 취소 확인`,
      reject_detail: `이 보고서를 취소하시겠습니까?`,
    },
    error: {
      delete_title: "삭제 확인",
      delete_detail: "이 정보를 삭제하십니까?",
      delete_file_image_title: "사진 삭제 확인",
      delete_file_image_detail: "이 사진을 삭제하십니까?",
    },
    success: {
      no_criminal: `범죄 기록을 찾을 수 없음`,
      title: `저장 완료`,
      detail: `다음 단계를 진행할 수 있습니다`,
      history_detail: `사건 보고서를 다시 볼 수 있습니다`,
      receive_title: `성공적으로 수신됨`,
      receive_detail: `다음 단계를 진행할 수 있습니다`,
      edit_receive_title: `책임자 수정 성공`,
      edit_receive_detail: `책임자가 다음 단계로 진행할 수 있습니다`,
      reject_title: `보고서 거부 성공`,
      email_title: `이메일이 성공적으로 수정됨`,
      email_detail: `새 이메일로 로그인할 수 있습니다. 다시 로그인해 주세요`,
      delete_title: `데이터 삭제 성공`,
      delete_detail: `데이터가 삭제되었습니다`,
      delete_user_title: `계정 삭제 성공`,
      delete_user_detail: `계정이 삭제되었습니다`,
      delete_image_title: `이미지 삭제 성공`,
      delete_image_detail: `이미지가 삭제되었습니다`,
      close_title: `작업이 성공적으로 완료되었습니다`,
      close_detail: ``,
      edit_title: `수정 성공`,
      edit_detail: ``,
      send_title: `보고서 전송 성공`,
      send_detail: ``,
      waiting_title: `성공적으로 제출됨`,
      closed_title: `작업이 성공적으로 완료되었습니다`,
      closed_detail: `다음 단계를 진행할 수 있습니다`,
      confirm_title: `보고서 승인 성공`,
      confirm_detail: ``,
      receive_suppressed_title: `사건 해결 성공`,
      receive_suppressed_detail: `다음 단계를 진행할 수 있습니다`,
      submit_title: `접수 성공`,
      submit_detail: `다음 단계를 진행할 수 있습니다`,
      copy_title: `복사 성공`,
      send_wait_title: `저장 완료`,
      save_receive_title: `작업이 성공적으로 저장되었습니다`,
      tel1155_title: `알림`,
      tel1155_detail: `이 통화는 유료입니다. 수락하시겠습니까?`,
    },
    caution: {
      default_title: "죄송합니다.",
      delete_title: "죄송합니다.",
      default_detail: "신고가 없습니다.",
      recipient_detail: "접수됨 및 정보 수정됨",
      completed_detail: "처라할 수 없습니다.",
      delete_image_detail: "사진을 삭제할 수 없습니다.",
      delete_detail: "삭제할 수 없습니다.",
      delete_user_detail: "이 계정을 삭제할 수 없습니다.",
      error_detail: "오류가 발생했습니다.",
      duplicate_detail: "시스템에 정보가 있습니다.",
    },
    textarea: {
      process_title: "진행 결과",
      process_placeholder: "진행 결과를 입력해 주세요.",
      reject_title: "진행 결과를 거절했습니다.",
    },
    logout: {
      title: "로그아웃",
      detail: "계정에서 로그아웃하시겠어요?",
    },
  },
  form: {
    address: {
      label: "주소",
      placeholder: "주소를 입력하세요",
    },
    opening_hours: {
      label: "영업 시간",
      placeholder: "영업 시간을 입력하세요",
    },
    tourist_types: {
      label: "관광객 유형",
      placeholder: "관광객 유형을 입력하세요",
    },
    types: {
      label: "유형",
      placeholder: "유형을 입력하세요",
    },
    map: {
      label: "지도",
      placeholder: "지도를 입력하세요",
    },
    latitude: {
      label: "위도",
      placeholder: "위도를 입력하세요",
    },
    longitude: {
      label: "경도",
      placeholder: "경도를 입력하세요",
    },
    area: {
      label: "지역",
      placeholder: "지역을 입력하세요",
    },
    metadata: {
      label: "사건 현장까지 몇 분이 걸립니까 (분 단위로 숫자를 입력하세요)",
      placeholder: "사건 현장까지 몇 분이 걸리는지 입력하세요",
    },
    contentAt: {
      label: "뉴스 날짜",
      placeholder: "뉴스 날짜를 선택하세요",
    },
    publishedAt: {
      label: "게시 날짜",
      placeholder: "게시 날짜를 선택하세요",
    },
    thumbnail: {
      label: "이미지",
      placeholder: "이미지를 선택하세요",
    },
    gallery: {
      label: "사진 앨범",
      placeholder: "이미지를 선택하세요",
    },
    published: {
      label: "게시 상태",
      placeholder: "게시 상태를 선택하세요",
    },
    isInternal: {
      label: "내부 뉴스 상태",
      placeholder: "내부 뉴스 상태를 선택하세요",
    },
    translations: {
      label: "태국어 제목",
      placeholder: "태국어 제목을 입력하세요",
    },
    translations_cn: {
      label: "중국어 기사",
      placeholder: "중국어 기사를 입력하세요",
    },
    translations_en: {
      label: "영어 기사",
      placeholder: "영어 기사를 입력하세요",
    },
    translations_jp: {
      label: "일본어 기사",
      placeholder: "일본어 기사를 입력하세요",
    },
    translations_kr: {
      label: "한국어 기사",
      placeholder: "한국어 기사를 입력하세요",
    },
    translations_ru: {
      label: "러시아어 기사",
      placeholder: "러시아어 기사를 입력하세요",
    },
    translations_th: {
      label: "태국어 기사",
      placeholder: "태국어 기사를 입력하세요",
    },
    channel: {
      label: `채널`,
      placeholder: `선택하세요`,
    },
    is_track_location: {
      label: `위치 추적 허용`,
      placeholder: `선택하세요`,
    },
    is_camera: {
      label: `카메라 접근 허용`,
      placeholder: `선택하세요`,
    },
    is_photo: {
      label: `사진 접근 허용`,
      placeholder: `선택하세요`,
    },
    is_microphone: {
      label: `마이크 접근 허용`,
      placeholder: `선택하세요`,
    },
    is_push_notification: {
      label: `푸시 알림 허용`,
      placeholder: `선택하세요`,
    },
    is_chat: {
      label: `채팅 접근 허용`,
      placeholder: `선택하세요`,
    },
    emergency_contact: {
      label: `긴급 연락처`,
      placeholder: `긴급 연락처를 입력하세요`,
    },
    images: {
      label: `이미지`,
      placeholder: `이미지를 선택하세요`,
    },
    region: {
      label: `지역`,
      placeholder: `지역을 선택하세요`,
    },
    message: {
      label: `Aa`,
      placeholder: `Aa`,
    },
    numberic: {
      label: ``,
      placeholder: ``,
    },
    location_search: {
      label: `사건 장소`,
      placeholder: `사건 장소 검색`,
    },
    notification_date: {
      label: `알림 날짜`,
      placeholder: `알림 날짜를 선택하세요`,
    },
    case_number: {
      label: `보고서 번호`,
      placeholder: `보고서 번호를 입력하세요`,
    },
    friendly_category: {
      label: `관광객으로부터의 사건 유형`,
      placeholder: `-`,
    },
    story_type: {
      label: `사건 유형`,
      placeholder: `사건 유형을 선택하세요`,
    },
    province_area: {
      label: `도/지역`,
      placeholder: `도/지역을 선택하세요`,
    },
    recipient: {
      label: `수신자`,
      placeholder: `수신자를 선택하세요`,
    },
    passport_number: {
      label: `여권 번호`,
      placeholder: `여권 번호를 입력하세요`,
    },
    number: {
      label: `번호`,
      placeholder: `번호를 입력하세요`,
    },
    gender: {
      label: `성별`,
      placeholder: `성별을 입력하세요`,
    },
    nationality: {
      label: `국적`,
      placeholder: `국적을 선택하세요`,
    },
    country: {
      label: `국가`,
      placeholder: `국가를 선택하세요`,
    },
    app_channel: {
      label: `가입 채널`,
      placeholder: `가입 채널을 선택하세요`,
    },
    notification_channel: {
      label: `알림 채널`,
      placeholder: `알림 채널을 선택하세요`,
    },
    phone: {
      label: `전화번호`,
      placeholder: `전화번호를 입력하세요`,
    },
    informer_phone: {
      label: `신고자 전화번호`,
      placeholder: `신고자 전화번호를 입력하세요`,
    },
    identification_type: {
      label: `신분 확인 유형`,
      placeholder: `신분 확인 유형을 선택하세요`,
    },
    type_code: {
      label: ``,
      placeholder: ``,
    },
    id: {
      label: `ID`,
      placeholder: `ID를 입력하세요`,
    },
    email: {
      label: `이메일`,
      placeholder: `이메일을 입력하세요`,
    },
    nowPassword: {
      label: `현재 비밀번호`,
      placeholder: `현재 비밀번호를 입력하세요`,
    },
    password: {
      label: `비밀번호`,
      placeholder: `비밀번호를 입력하세요`,
    },
    newPassword: {
      label: `새 비밀번호`,
      placeholder: `새 비밀번호를 입력하세요`,
    },
    repeatPassword: {
      label: `새 비밀번호 확인`,
      placeholder: `새 비밀번호를 확인하세요`,
    },
    remember_me: {
      label: `로그인 상태 유지`,
      placeholder: ``,
    },
    status: {
      label: `상태`,
      placeholder: `상태를 선택하세요`,
    },
    role: {
      label: `권한`,
      placeholder: `권한을 선택하세요`,
    },
    division: {
      label: "부서",
      placeholder: "부서를 선택하세요",
    },
    subdivision: {
      placeholder: "하위 부서를 선택하세요",
    },
    station: {
      placeholder: "경찰서를 선택하세요",
    },
    responsibleArea: {
      label: "책임 지역",
      placeholder: "책임 지역을 선택하세요",
    },
    keyword: {
      label: `검색...`,
      placeholder: ``,
    },
    image_name: {
      label: `이미지`,
      placeholder: `이미지 이름을 입력하세요`,
    },
    informer_name: {
      label: `신고자 이름`,
      placeholder: `신고자 이름을 입력하세요`,
    },
    informer_type: {
      label: `신고자 유형`,
      placeholder: `신고자 유형을 선택하세요`,
    },
    name: {
      label: `이름`,
      placeholder: `이름을 입력하세요`,
    },
    title: {
      label: `제목`,
      placeholder: `제목을 입력하세요`,
    },
    title_news: {
      label: `제목`,
      placeholder: `텍스트를 입력하세요`,
    },
    title_alert: {
      label: `제목`,
      placeholder: `텍스트를 입력하세요`,
    },
    description: {
      label: `설명`,
      placeholder: `텍스트를 입력하세요`,
    },
    detail: {
      label: `기사`,
      placeholder: `텍스트를 입력하세요`,
    },
    firstName: {
      label: `이름`,
      placeholder: `이름을 입력하세요`,
    },
    lastName: {
      label: `성`,
      placeholder: `성을 입력하세요`,
    },
    idCard: {
      label: `주민등록번호`,
      placeholder: `주민등록번호를 입력하세요`,
    },
    isOpenAgent: {
      label: `에이전트 플랫폼 활성화`,
      placeholder: ``,
    },
    touristDetail: {
      label: `사건 세부사항 (관광객)`,
      placeholder: `사건 세부사항을 입력하세요 (관광객)`,
    },
    notificationChannel: {
      label: `알림 채널`,
      placeholder: ``,
    },
    officerDetail: {
      label: `사건 세부사항 (경찰)`,
      placeholder: `세부사항을 입력하세요`,
    },
    no: {
      label: `코드`,
      placeholder: `코드를 입력하세요`,
    },
    subject: {
      label: `주제`,
      placeholder: `주제를 선택하세요`,
    },
    important: {
      label: `중요도`,
      placeholder: `중요도를 선택하세요`,
    },
    district: {
      label: `구`,
      placeholder: `구를 선택하세요`,
    },
    sub_district: {
      label: `동`,
      placeholder: `동을 선택하세요`,
    },
    location_detail: {
      label: `위치 세부사항`,
      placeholder: `위치 세부사항을 입력하세요`,
    },
    pin_location: {
      label: `위치 핀`,
      placeholder: ``,
    },
    submit_story: {
      label: `이야기 제출`,
      placeholder: ``,
    },
    close_case: {
      label: `사건 닫기`,
      placeholder: ``,
    },
    send_responsibility: {
      label: `책임 지역으로 전송`,
      placeholder: `책임 지역을 선택하세요`,
    },
    agency: {
      label: `기관`,
      placeholder: ``,
    },
    police_inspector: {
      label: `경찰 검사`,
      placeholder: ``,
    },
    result: {
      label: `결과`,
      placeholder: ``,
    },
    responsible_person: {
      label: `책임자`,
      placeholder: `책임자를 선택하세요`,
    },
    updated_date: {
      label: `최신 업데이트`,
      placeholder: ``,
    },
    estimate_time: {
      label: `예상 시간`,
      placeholder: ``,
    },
    subject_user: {
      label: `담당자`,
      placeholder: ``,
    },
    assign_user: {
      label: `할당자`,
      placeholder: ``,
    },
    inspector_user: {
      label: `경찰 검사`,
      placeholder: ``,
    },
    type: {
      label: `사건 유형`,
      placeholder: `사건 유형을 선택하세요`,
    },
    startAt: {
      label: `시작 날짜`,
      placeholder: `시작 날짜를 선택하세요`,
    },
    endAt: {
      label: `종료 날짜`,
      placeholder: `종료 날짜를 선택하세요`,
    },
    information_user: {
      label: `신고자 정보`,
      placeholder: `신고자 정보를 선택하세요`,
    },
    admin_user: {
      label: `관리자`,
      placeholder: `관리자를 선택하세요`,
    },
    control_user: {
      label: `감독자`,
      placeholder: `감독자를 선택하세요`,
    },
    responsibleAreaCodes: {
      label: "책임 지역",
      placeholder: "책임 지역을 선택하세요",
    },
    locales: {
      label: "언어",
      placeholder: "언어를 선택하세요",
    },
  },
  error: {
    password: "비밀번호는 6자이상 있어야 합니다.",
    invalid_credential: "로그인을 할 수 없습니다.다시 해 보세요.",
  },
  caution: {
    user_1: `참고: 에이전트 운영 시스템이 활성화된 경우 책임 지역을 선택해야 합니다`,
    password_1: `비밀번호는 대문자, 소문자 및 숫자를 포함하여 최소 6자 이상이어야 합니다`,
    picture_1: `권장: 이미지 크기 200 x 200 픽셀`,
    picture_2: `(최대 5MB) 파일 유형: JPG (JPEG), PNG`,
    picture_1_2: `권장: 이미지 크기 490 x 385 픽셀`,
    picture_2_2: `(최대 5MB) 파일 유형: JPG (JPEG), PNG`,
    delete_title: ``,
    email_1: `이 이메일을 사용하여 계정을 확인합니다`,
  },
  role: {
    ADMIN: "시스템 관리자",
    CENTRAL_OFFICER: "콜센터 1155 직원",
    OFFICER: "서장",
    REPORTER: "리포터 ",
    AGENT: "순찰대",
  },
  incident_status: {
    waiting: "진행 기다리는 중",
    in_progress: "진행중",
    canceled: "완료됨",
  },
  lang: {
    th: "ไทย",
    en: "영어",
    cn: "中国大陆",
    ru: "Россия",
    kr: "대한민국",
    jp: "日本",
  },
  progress: {
    processing_time: "처리 시간",
    period_time: "기간",
  },
  go_to_chat: "Go back to chat",
  sos: {
    title: '긴급상황(SOS) 시스템 요청 사용하기의 경우가 아래 바와 경우만 제공합니다.',
    conditions: [
      '삶에 대한 위험 즉 폭행',
      '재물에 가해하기 사고 즉 절도및 강도',
      '중대사고',
      '중증질환',
    ],
    description: '위에서 말한 바와 긴급상황이 아니였으면 본인은 핫라인 1155 이나 tourist police 앱의채팅으로 신고하십시오.',
    button: {
      confirm: '확인',
      cancel: '취소',
    },
  },
  errorMessage: {
    duplicateEmail: '중복된 이메일'
  }
};
