<script lang="ts">
import { defineComponent } from "vue";
import { useAlertFormStore } from "./stores/useAlertFormStore";
import { useAlertDeleteStore } from "./stores/useAlertDeleteStore";
import type { $number, $string } from "@/configs/types/Shared/typeShare";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import ButtonStyle2Component from "@/components/Forms/Button/ButtonStyle2Component.vue";
import PopupCancelComponent from "@/components/Popups/PopupCancelComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import PopupDeleteComponent from "@/components/Popups/PopupDeleteComponent.vue";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormInputDatepickerComponent from "@/components/Forms/Datepicker/FormInputDatepickerComponent.vue";
import FormCheckBoxToggleComponent from "@/components/Forms/CheckBox/FormCheckBoxToggleComponent.vue";
import FormTextareaEditorComponent from "@/components/Forms/Textarea/FormTextareaEditorComponent.vue";
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from "@headlessui/vue";
import dayjs from "dayjs";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";

export default defineComponent({
  name: "AlertManageView",
  components: {
    ButtonDefaultComponent,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  data() {
    return {
      componentName: this.$options.name,
      thumbnail: "" as any,
      images: [] as any,
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          publishedAt: {
            component: FormInputDatepickerComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isError: (this.alertFormStore.error.publishedAt || "").length > 0,
              isIcon: false,
              isDisable: this.loading(),
              isRequired: this.alertFormStore.isRequired.publishedAt.is,
              textError: this.$t(this.alertFormStore.isRequired.publishedAt.placeholder),
              title: this.$t(this.alertFormStore.isRequired.publishedAt.label),
              placeholder: this.$t(this.alertFormStore.isRequired.publishedAt.placeholder),
              value: this.alertFormStore.result.publishedAt,
              name: this.alertFormStore.isRequired.publishedAt.name,
              format: "DD/MM/YYYY HH:mm",
              functionProp: async (date: Date) => {
                this.alertFormStore.result.publishedAt = dayjs(date).tz("Asia/Bangkok").toISOString();
              },
            } as typeof FormInputDefaultComponent.props,
          },
          expiryAt: {
            component: FormInputDatepickerComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isError: (this.alertFormStore.error.expiryAt || "").length > 0,
              isIcon: false,
              isDisable: this.loading(),
              isRequired: this.alertFormStore.isRequired.expiryAt.is,
              textError: this.$t(this.alertFormStore.isRequired.expiryAt.placeholder),
              title: this.$t(this.alertFormStore.isRequired.expiryAt.label),
              placeholder: this.$t(this.alertFormStore.isRequired.expiryAt.placeholder),
              value: this.alertFormStore.result.expiryAt,
              name: this.alertFormStore.isRequired.expiryAt.name,
              format: "DD/MM/YYYY HH:mm",
              functionProp: async (date: Date) => {
                this.alertFormStore.result.expiryAt = dayjs(date).tz("Asia/Bangkok").toISOString();
              },
            } as typeof FormInputDefaultComponent.props,
          },
          published: {
            component: FormCheckBoxToggleComponent,
            props: {
              isSpecial: false,
              isVisible: true,
              isError: (this.alertFormStore.error.published || "").length > 0,
              isIcon: false,
              isDisable: this.loading(),
              isRequired: this.alertFormStore.isRequired.published.is,
              textError: this.$t(this.alertFormStore.isRequired.published.placeholder),
              title: this.$t(this.alertFormStore.isRequired.published.label),
              placeholder: this.$t(this.alertFormStore.isRequired.published.placeholder),
              value: this.alertFormStore.result.published ? "true" : "false",
              items: [{ label: "true", value: "true" }],
              name: this.alertFormStore.isRequired.published.name,
            } as typeof FormCheckBoxToggleComponent.props,
            onUpdateValue: (value: $string) => {
              this.alertFormStore.result.published = !(value == "");
            },
          },
          latitude: {
            component: FormInputDefaultComponent,
            props: {
              type: "number",
              isSpecial: false,
              isVisible: true,
              isError: (this.alertFormStore.error.latitude || "").length > 0,
              isIcon: false,
              isDisable: this.loading(),
              isRequired: this.alertFormStore.isRequired.latitude.is,
              textError: this.$t(this.alertFormStore.isRequired.latitude.placeholder),
              title: `${this.$t(this.alertFormStore.isRequired.latitude.label)}`,
              name: this.alertFormStore.isRequired.latitude.name,
              placeholder: this.$t(this.alertFormStore.isRequired.latitude.placeholder),
              value: this.alertFormStore.result.latitude,
            } as typeof FormInputDefaultComponent.props,
            onUpdateValue: (value: $number) => {
              this.alertFormStore.result.latitude = Number(value);
            },
          },
          longitude: {
            component: FormInputDefaultComponent,
            props: {
              type: "number",
              isSpecial: false,
              isVisible: true,
              isError: (this.alertFormStore.error.longitude || "").length > 0,
              isIcon: false,
              isDisable: this.loading(),
              isRequired: this.alertFormStore.isRequired.longitude.is,
              textError: this.$t(this.alertFormStore.isRequired.longitude.placeholder),
              title: `${this.$t(this.alertFormStore.isRequired.longitude.label)}`,
              name: this.alertFormStore.isRequired.longitude.name,
              placeholder: this.$t(this.alertFormStore.isRequired.longitude.placeholder),
              value: this.alertFormStore.result.longitude,
            } as typeof FormInputDefaultComponent.props,
            onUpdateValue: (value: $number) => {
              this.alertFormStore.result.longitude = Number(value);
            },
          },
          area: {
            component: FormInputDefaultComponent,
            props: {
              type: "number",
              isSpecial: false,
              isVisible: true,
              isError: (this.alertFormStore.error.area || "").length > 0,
              isIcon: false,
              isDisable: this.loading(),
              isRequired: this.alertFormStore.isRequired.area.is,
              textError: this.$t(this.alertFormStore.isRequired.area.placeholder),
              title: `${this.$t(this.alertFormStore.isRequired.area.label)}`,
              name: this.alertFormStore.isRequired.area.name,
              placeholder: this.$t(this.alertFormStore.isRequired.area.placeholder),
              value: this.alertFormStore.result.area,
            } as typeof FormInputDefaultComponent.props,
            onUpdateValue: (value: $number) => {
              this.alertFormStore.result.area = Number(value);
            },
          },
          th: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[0].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[0].title.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[0].title.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[0].title.label)} (TH)`,
                name: `translations[0].title`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[0].title.placeholder),
                value: this.alertFormStore.result.translations[0]?.title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[0].title = value;
              },
            },
            detail: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[0].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[0].detail.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[0].detail.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[0].detail.label)} (TH)`,
                name: `translations[0].detail`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[0].detail.placeholder),
                value: this.alertFormStore.result.translations[0]?.detail,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[0].detail = value;
              },
            },
            description: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[0].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[0].description.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[0].description.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[0].description.label)} (TH)`,
                name: `translations[0].description`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[0].description.placeholder),
                value: this.alertFormStore.result.translations[0]?.description,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[0].description = value;
              },
            },
          },
          en: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[1].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[1].title.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[1].title.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[1].title.label)} (EN)`,
                name: `translations[1].title`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[1].title.placeholder),
                value: this.alertFormStore.result.translations[1]?.title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[1].title = value;
              },
            },
            detail: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[1].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[1].detail.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[1].detail.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[1].detail.label)} (EN)`,
                name: `translations[1].detail`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[1].detail.placeholder),
                value: this.alertFormStore.result.translations[1]?.detail,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[1].detail = value;
              },
            },
            description: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[1].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[1].description.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[1].description.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[1].description.label)} (EN)`,
                name: `translations[1].description`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[1].description.placeholder),
                value: this.alertFormStore.result.translations[1]?.description,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[1].description = value;
              },
            },
          },
          cn: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[2].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[2].title.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[2].title.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[2].title.label)} (CN)`,
                name: `translations[2].title`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[2].title.placeholder),
                value: this.alertFormStore.result.translations[2]?.title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[2].title = value;
              },
            },
            detail: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[2].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[2].detail.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[2].detail.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[2].detail.label)} (CN)`,
                name: `translations[2].detail`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[2].detail.placeholder),
                value: this.alertFormStore.result.translations[2]?.detail,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[2].detail = value;
              },
            },
            description: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[2].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[2].description.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[2].description.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[2].description.label)} (CN)`,
                name: `translations[2].description`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[2].description.placeholder),
                value: this.alertFormStore.result.translations[2]?.description,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[2].description = value;
              },
            },
          },
          jp: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[3].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[3].title.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[3].title.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[3].title.label)} (JP)`,
                name: `translations[3].title`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[3].title.placeholder),
                value: this.alertFormStore.result.translations[3]?.title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[3].title = value;
              },
            },
            detail: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[3].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[3].detail.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[3].detail.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[3].detail.label)} (JP)`,
                name: `translations[3].detail`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[3].detail.placeholder),
                value: this.alertFormStore.result.translations[3]?.detail,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[3].detail = value;
              },
            },
            description: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[3].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[3].description.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[3].description.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[3].description.label)} (JP)`,
                name: `translations[3].description`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[3].description.placeholder),
                value: this.alertFormStore.result.translations[3]?.description,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[3].description = value;
              },
            },
          },
          kr: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[4].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[4].title.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[4].title.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[4].title.label)} (KR)`,
                name: `translations[4].title`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[4].title.placeholder),
                value: this.alertFormStore.result.translations[4]?.title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[4].title = value;
              },
            },
            detail: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[4].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[4].detail.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[4].detail.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[4].detail.label)} (KR)`,
                name: `translations[4].detail`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[4].detail.placeholder),
                value: this.alertFormStore.result.translations[4]?.detail,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[4].detail = value;
              },
            },
            description: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[4].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[4].description.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[4].description.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[4].description.label)} (KR)`,
                name: `translations[4].description`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[4].description.placeholder),
                value: this.alertFormStore.result.translations[4]?.description,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[4].description = value;
              },
            },
          },
          ru: {
            title: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[5].title`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[5].title.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[5].title.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[5].title.label)} (RU)`,
                name: `translations[5].title`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[5].title.placeholder),
                value: this.alertFormStore.result.translations[5]?.title,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[5].title = value;
              },
            },
            detail: {
              component: FormInputDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[5].detail`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[5].detail.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[5].detail.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[5].detail.label)} (RU)`,
                name: `translations[5].detail`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[5].detail.placeholder),
                value: this.alertFormStore.result.translations[5]?.detail,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[5].detail = value;
              },
            },
            description: {
              component: FormTextareaEditorComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.alertFormStore.error[`translations[5].description`] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.alertFormStore.isRequired.translations[5].description.is,
                textError: this.$t(this.alertFormStore.isRequired.translations[5].description.placeholder),
                title: `${this.$t(this.alertFormStore.isRequired.translations[5].description.label)} (RU)`,
                name: `translations[5].description`,
                placeholder: this.$t(this.alertFormStore.isRequired.translations[5].description.placeholder),
                value: this.alertFormStore.result.translations[5]?.description,
              } as typeof FormTextareaEditorComponent.props,
              onUpdateValue: (value: $string) => {
                this.alertFormStore.result.translations[5].description = value;
              },
            },
          },
          button: {
            btnUploadImage: {
              click: () => {
                (document?.getElementById("file") as HTMLButtonElement).click();
              },
            },
            btnUploadImages: {
              click: () => {
                (document?.getElementById("files") as HTMLButtonElement).click();
              },
            },
            btnDeleteImage: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.TrashIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: !this.thumbnail || this.loading(),
                title: this.$t("btn.btn_delete_file"),
                classEnumName: this.$enums.ENUM_COLOR.RED,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.alertFormStore.isDelete = !this.alertFormStore.isDelete;
              },
            },
            btnSave: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                isSubmit: true,
                title: this.$t(this.alertFormStore.result?.id ? "btn.btn_save" : "btn.btn_save"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnCancel: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                title: this.$t("btn.btn_cancel"),
                classEnumName: this.$enums.ENUM_COLOR.DARK,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.$router.push({ name: "AlertView" });
              },
            },
            btnDelete: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: this.loading(),
                title: this.$t("btn.btn_delete"),
                classEnumName: this.$enums.ENUM_COLOR.RED,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.deleteAlertStore.isDelete = !this.deleteAlertStore.isDelete;
              },
            },
          },
          popup: {
            deletePopup: {
              component: PopupDeleteComponent,
              props: {
                isOpen: this.deleteAlertStore.isDelete,
                textTitle: this.$t("popup.error.delete_title"),
                textDetail: this.$t("popup.error.delete_detail"),
                functionProp: async (e: Event) => {
                  await this.deleteAlertStore.onSubmit(e);
                  this.deleteAlertStore.isDelete = false;
                  this.$router.push({ name: "AlertView" });
                },
                functionCancelProp: () => {
                  this.deleteAlertStore.isDelete = false;
                },
              } as typeof PopupDeleteComponent.props,
            },
            deleteImagePopup: {
              component: PopupDeleteComponent,
              props: {
                isOpen: this.alertFormStore.isDelete,
                textTitle: this.$t("popup.error.delete_file_image_title"),
                textDetail: this.$t("popup.error.delete_file_image_detail"),
                functionProp: () => {
                  this.alertFormStore.isDelete = false;
                },
                functionCancelProp: () => {
                  this.alertFormStore.isDelete = false;
                },
                submitEvent: (e: any) => {
                  this.alertFormStore.result.thumbnail = "";
                  this.alertFormStore.onDeleteImageSubmit(e);
                },
              } as typeof PopupDeleteComponent.props,
            },
            cancelPopup: {
              component: PopupCancelComponent,
              props: {
                isOpen: this.alertFormStore.isCancel,
                textTitle: this.$t("popup.cancel.cancel_title"),
                textDetail: this.$t("popup.cancel.cancel_detail"),
                functionProp: () => {
                  this.alertFormStore.isCancel = false;
                  this.$router.push({ name: "AlertView" });
                  this.reset();
                },
                functionCancelProp: async () => {
                  this.alertFormStore.isCancel = false;
                },
              } as typeof PopupCancelComponent.props,
            },
          },
          alert: {
            deleteSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.deleteAlertStore.isSuccess,
                textTitle: this.$t("popup.success.delete_title"),
                textDetail: this.$t("popup.success.delete_detail"),
                functionProp: () => {
                  this.deleteAlertStore.isSuccess = false;
                  this.reset();
                  this.$router.push({ name: "AlertView" });
                },
                functionCancelProp: () => {
                  this.deleteAlertStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            deleteCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.alertFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.delete_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.alertFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            deleteImageSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.alertFormStore.isSuccess,
                textTitle: this.$t("popup.success.delete_image_title"),
                textDetail: this.$t("popup.success.delete_image_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.alertFormStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            deleteImageCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.alertFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.delete_image_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.alertFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            formSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.alertFormStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.detail"),
                functionProp: () => {
                  this.setting();
                  this.$router.push({ name: "AlertView" });
                },
                functionCancelProp: () => {
                  this.alertFormStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            formCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.alertFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.duplicate_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.alertFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
        },
      };
    },
  },
  methods: {
    async uploadImage(e: any) {
      let listFiles = e.target.files;

      if (!listFiles?.length) {
        return;
      }

      const files: File[] = [];
      for (const f of listFiles) {
        files.push(f);
      }

      const f = new fetchFile();
      const response = await f.Upload(files);
      this.alertFormStore.result.thumbnail = response[0].id;
      this.thumbnail = response;

      e.target.value = "";
      this.$configLightbox();
    },
    loading() {
      return this.alertFormStore.loading || this.deleteAlertStore.loading || this.alertFormStore.loading;
    },
    async fetch() {
      this.alertFormStore.loading = true;
      if (this.$route.params.id) {
        this.deleteAlertStore.result.id = String(this.$route.params.id);
        await Promise.all([
          (async () => {
            const result = await this.alertFormStore.fetchAlert.Get(this.$route.params.id as string);
            this.alertFormStore.result = result;

            // Get Thumbnail
            if (this?.alertFormStore?.result?.thumbnail) {
              this.thumbnail = await Promise.all(await this.alertFormStore.fetchFile.Get([this?.alertFormStore?.result?.thumbnail]));
            }

            this.$configLightbox();
          })(),
        ]);
      } else {
        this.alertFormStore.result.publishedAt = dayjs(new Date()).tz("Asia/Bangkok").toISOString();
        this.alertFormStore.result.area = 5000; // default as 5 kilimeter
      }
      this.alertFormStore.loading = false;
    },
    async reset() {
      await this.deleteAlertStore.$reset();
      await this.alertFormStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
      await this.$configLightbox();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  setup() {
    const masterDataStore = useMasterDataStore();
    const alertFormStore = useAlertFormStore();
    const deleteAlertStore = useAlertDeleteStore();

    return {
      masterDataStore,
      alertFormStore,
      deleteAlertStore,
    };
  },
});
</script>

<template>
  <div class="ttp-page-header-1">
    <h2>{{ $t(($route?.meta as any).i18n) }}</h2>
  </div>
  <form @submit.prevent="alertFormStore.onSubmit">
    <div class="grid grid-cols-12 gap-2 p-4">
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-7" :is="defaultFormComponents.form.publishedAt.component" v-bind="defaultFormComponents.form.publishedAt.props" />
      <component classTagName="col-start-1 col-end-13 md:col-start-7 md:col-end-13" :is="defaultFormComponents.form.expiryAt.component" v-bind="defaultFormComponents.form.expiryAt.props" />
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.published.component" v-bind="defaultFormComponents.form.published.props" @updateValue="defaultFormComponents.form.published.onUpdateValue" />
      <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-4 mt-4" :is="defaultFormComponents.form.latitude.component" v-bind="defaultFormComponents.form.latitude.props" @updateValue="defaultFormComponents.form.latitude.onUpdateValue" />
      <component classTagName="col-start-1 col-end-13 md:col-start-4 md:col-end-8 mt-4" :is="defaultFormComponents.form.longitude.component" v-bind="defaultFormComponents.form.longitude.props" @updateValue="defaultFormComponents.form.longitude.onUpdateValue" />
      <component classTagName="col-start-1 col-end-13 md:col-start-8 md:col-end-13 mt-4" :is="defaultFormComponents.form.area.component" v-bind="defaultFormComponents.form.area.props" @updateValue="defaultFormComponents.form.area.onUpdateValue" />
      <div class="col-start-1 col-end-13 border-2 rounded-lg">
        <TabGroup>
          <!-- Nav Tabs -->
          <TabList class="flex items-center gap-1 text-sm md:gap-2">
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาไทย</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาอังกฤษ</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาจีน</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาญี่ปุ่น</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษาเกาหลี</span>
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                class="-mb-px flex grow items-center justify-center gap-2 border-b-2 px-3 py-3 font-medium focus:outline-none focus:ring focus:ring-blue-500/25 md:px-5"
                :class="{
                  'border-blue-500 text-blue-600 dark:border-blue-500 dark:text-blue-400': selected,
                  'border-transparent text-gray-600 hover:border-gray-200 hover:text-blue-500 active:text-gray-600 dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-blue-400': !selected,
                }"
              >
                <span class="hidden sm:inline-block">ภาษารัสเซีย</span>
              </button>
            </Tab>
          </TabList>
          <!-- END Nav Tabs -->

          <!-- Tab Content -->
          <TabPanels class="px-4 pb-4">
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.th.title.component" v-bind="defaultFormComponents.form.th.title.props" @updateValue="defaultFormComponents.form.th.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.th.detail.component" v-bind="defaultFormComponents.form.th.detail.props" @updateValue="defaultFormComponents.form.th.detail.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.th.description.component" v-bind="defaultFormComponents.form.th.description.props" @updateValue="defaultFormComponents.form.th.description.onUpdateValue" />
            </TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.en.title.component" v-bind="defaultFormComponents.form.en.title.props" @updateValue="defaultFormComponents.form.en.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.en.detail.component" v-bind="defaultFormComponents.form.en.detail.props" @updateValue="defaultFormComponents.form.en.detail.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.en.description.component" v-bind="defaultFormComponents.form.en.description.props" @updateValue="defaultFormComponents.form.en.description.onUpdateValue" />
            </TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.cn.title.component" v-bind="defaultFormComponents.form.cn.title.props" @updateValue="defaultFormComponents.form.cn.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.cn.detail.component" v-bind="defaultFormComponents.form.cn.detail.props" @updateValue="defaultFormComponents.form.cn.detail.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.cn.description.component" v-bind="defaultFormComponents.form.cn.description.props" @updateValue="defaultFormComponents.form.cn.description.onUpdateValue" />
            </TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.jp.title.component" v-bind="defaultFormComponents.form.jp.title.props" @updateValue="defaultFormComponents.form.jp.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.jp.detail.component" v-bind="defaultFormComponents.form.jp.detail.props" @updateValue="defaultFormComponents.form.jp.detail.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.jp.description.component" v-bind="defaultFormComponents.form.jp.description.props" @updateValue="defaultFormComponents.form.jp.description.onUpdateValue" />
            </TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.kr.title.component" v-bind="defaultFormComponents.form.kr.title.props" @updateValue="defaultFormComponents.form.kr.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.kr.detail.component" v-bind="defaultFormComponents.form.kr.detail.props" @updateValue="defaultFormComponents.form.kr.detail.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.kr.description.component" v-bind="defaultFormComponents.form.kr.description.props" @updateValue="defaultFormComponents.form.kr.description.onUpdateValue" />
            </TabPanel>
            <TabPanel>
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.ru.title.component" v-bind="defaultFormComponents.form.ru.title.props" @updateValue="defaultFormComponents.form.ru.title.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.ru.detail.component" v-bind="defaultFormComponents.form.ru.detail.props" @updateValue="defaultFormComponents.form.ru.detail.onUpdateValue" />
              <component classTagName="col-start-1 col-end-13 md:col-start-1 md:col-end-13 mt-4" :is="defaultFormComponents.form.ru.description.component" v-bind="defaultFormComponents.form.ru.description.props" @updateValue="defaultFormComponents.form.ru.description.onUpdateValue" />
            </TabPanel>
          </TabPanels>
          <!-- END Tab Content -->
        </TabGroup>
      </div>
    </div>
    <div class="ttp-between p-4 border-t-2">
      <div class="flex gap-2">
        <component :is="defaultFormComponents.form.button.btnSave.component" v-bind="defaultFormComponents.form.button.btnSave.props" />
        <component :is="defaultFormComponents.form.button.btnCancel.component" v-bind="defaultFormComponents.form.button.btnCancel.props" @click="defaultFormComponents.form.button.btnCancel.click" />
      </div>
      <div v-if="$route.params.id">
        <component :is="defaultFormComponents.form.button.btnDelete.component" v-bind="defaultFormComponents.form.button.btnDelete.props" @click="defaultFormComponents.form.button.btnDelete.click" />
      </div>
    </div>
  </form>

  <!-- Popup Delete -->
  <component :is="defaultFormComponents.form.popup.deletePopup.component" v-bind="defaultFormComponents.form.popup.deletePopup.props" />
  <!-- Popup Delete Image -->
  <component :is="defaultFormComponents.form.popup.deleteImagePopup.component" v-bind="defaultFormComponents.form.popup.deleteImagePopup.props" />
  <!-- Popup Cancel -->
  <component :is="defaultFormComponents.form.popup.cancelPopup.component" v-bind="defaultFormComponents.form.popup.cancelPopup.props" />

  <!-- Popup Delete Success & Caution -->
  <component v-if="deleteAlertStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.alert.deleteSuccess.component" v-bind="defaultFormComponents.form.alert.deleteSuccess.props" />
  <component v-else-if="deleteAlertStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.form.alert.deleteCaution.component" v-bind="defaultFormComponents.form.alert.deleteCaution.props" />

  <!-- Popup Delete Image Success & Caution -->
  <component v-if="alertFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.alert.deleteImageSuccess.component" v-bind="defaultFormComponents.form.alert.deleteImageSuccess.props" />
  <component v-else-if="alertFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.form.alert.deleteImageCaution.component" v-bind="defaultFormComponents.form.alert.deleteImageCaution.props" />

  <!-- Popup Form Success & Caution -->
  <component v-if="alertFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.form.alert.formSuccess.component" v-bind="defaultFormComponents.form.alert.formSuccess.props" />
  <component v-else-if="alertFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.form.alert.formCaution.component" v-bind="defaultFormComponents.form.alert.formCaution.props" />
</template>
