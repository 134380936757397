<script lang="ts">
import { computed, defineComponent, watch } from "vue";
import { Geolocation } from "@capacitor/geolocation";
import { useIncidentFormStore } from "./stores/useIncidentFormStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useIncidentAssigneeFormStore } from "./stores/useIncidentAssigneeFormStore";
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";

import { TransitionRoot, Dialog, DialogPanel } from "@headlessui/vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import ButtonStyle2Component from "@/components/Forms/Button/ButtonStyle2Component.vue";
import PopupAssignTextareaComponent from "@/components/Popups/PopupAssignTextareaComponent.vue";
import PopupCancelComponent from "@/components/Popups/PopupCancelComponent.vue";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import { deleteKeysFromObject } from "@/configs/helpers/MainHelper";
import FormTextareaDefaultComponent from "@/components/Forms/Textarea/FormTextareaDefaultComponent.vue";
import type { $string } from "@/configs/types/Shared/typeShare";
import { PERMISSION_INCIDENT_APPROVE, PERMISSION_INCIDENT_CLOSE, PERMISSION_INCIDENT_CREATE, PERMISSION_INCIDENT_IN_REVIEW } from "@/configs/permission";
import type { ENUM_FIELD_ROLE } from "@/configs/enums/enum";
import type { IncidentReport } from "@/configs/types/Incident/typeIncident";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";

export default defineComponent({
  name: "IncidentReportView",
  components: {
    TransitionRoot,
    Dialog,
    DialogPanel,
  },
  data() {
    return {
      componentName: this.$options.name,
      endDateTime: new Date(),
      timeLeft: "",
      intervalId: "" as any,
      isVisible: false,
      updatingReport: false,
      editingReport: false,
      report: <IncidentReport>{
        detail: "",
        images: [],
      },
      reportImages: <string[]>[],
    };
  },
  computed: {
    assigneeFormComponents() {
      return {
        form: {
          button: {
            btnSave: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: Boolean(this.$inArray(this.incidentFormStore?.result?.status, [this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW]) && this.$inArray(this.authStore.displayRole, PERMISSION_INCIDENT_IN_REVIEW)),
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                title: this.$t("btn.btn_repair_report"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                this.incidentAssigneeFormStore.result.status = this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW;
                this.incidentFormStore.isButtonState = this.$enums.ENUM_BUTTON_STATE.SAVE;
                const validate = await this.incidentAssigneeFormStore.onCompletedSubmit(e);
                if (validate) {
                  this.handleAssignSubmit();
                }
              },
            },
          },
          textarea: {
            detail: {
              component: FormTextareaDefaultComponent,
              props: {
                classTagName: "w-full",
                isSpecial: false,
                isVisible: true,
                isError: (this.incidentAssigneeFormStore.error[this.incidentAssigneeFormStore.isRequired.detail.name] || "").length > 0,
                isIcon: false,
                isDisable: false,
                isRequired: this.incidentAssigneeFormStore.isRequired.detail.is,
                textError: this.$t(this.incidentAssigneeFormStore.isRequired.detail.placeholder),
                title: this.$t(this.incidentAssigneeFormStore.isRequired.detail.label),
                name: this.incidentAssigneeFormStore.isRequired.detail.name,
                placeholder: this.$t(this.incidentAssigneeFormStore.isRequired.detail.placeholder),
                value: this.incidentAssigneeFormStore?.result?.detail,
                functionProp: () => {},
              } as typeof FormTextareaDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.incidentAssigneeFormStore.result.detail = value;
              },
            },
          },
          popup: {
            assigneeSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.incidentFormStore.isSuccess || this.incidentAssigneeFormStore.isProgressSuccess,
                textTitle: this.$t(this.alertTextTitle()),
                textDetail: this.$t(this.alertTextDetail()),
                functionProp: () => {
                  this.incidentFormStore.isSuccess = false;
                  this.incidentAssigneeFormStore.isProgressSuccess = false;
                  if (this.incidentFormStore.isButtonState == this.$enums.ENUM_BUTTON_STATE.IN_REVIEW) {
                    this.$router.push({ name: "ReportingIncidentView" });
                  } else {
                    this.setting();
                  }
                },
                functionCancelProp: () => {
                  this.incidentFormStore.isSuccess = false;
                  this.incidentAssigneeFormStore.isProgressSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            assigneeCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.incidentFormStore.isSuccess || this.incidentAssigneeFormStore.isProgressSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.error_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.incidentFormStore.isSuccess = false;
                  this.incidentAssigneeFormStore.isProgressSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            progressSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.incidentAssigneeFormStore.isResponsibleSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.detail"),
                functionProp: () => {
                  this.incidentAssigneeFormStore.isResponsibleSuccess = false;
                  this.$router.push({
                    name: "IncidentNoticeView",
                    params: { id: this.$route.params.id },
                  });
                  this.setting();
                },
                functionCancelProp: () => {
                  this.incidentAssigneeFormStore.isResponsibleSuccess = false;
                },
              },
            },
            progressCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.incidentAssigneeFormStore.isResponsibleSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.recipient_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.incidentAssigneeFormStore.isResponsibleSuccess = false;
                },
              },
            },
          },
        },
      };
    },
    defaultFormComponents() {
      return {
        form: {
          textarea: {
            information: {
              detail: {
                component: FormTextareaDefaultComponent,
                props: {
                  isSpecial: false,
                  isVisible: true,
                  isLabel: false,
                  isError: (this.incidentAssigneeFormStore.error[this.incidentAssigneeFormStore.isRequired.detail.name] || "").length > 0,
                  isIcon: false,
                  isDisable: this.loading(),
                  isRequired: this.incidentAssigneeFormStore.isRequired.detail.is,
                  textError: this.$t(this.incidentAssigneeFormStore.isRequired.detail.placeholder),
                  title: this.$t(this.incidentAssigneeFormStore.isRequired.detail.label),
                  name: this.incidentAssigneeFormStore.isRequired.detail.name,
                  placeholder: this.$t(this.incidentAssigneeFormStore.isRequired.detail.placeholder),
                  value: this.incidentAssigneeFormStore?.result?.detail,
                } as typeof FormTextareaDefaultComponent.props,
                onUpdateValue: (value: $string) => {
                  this.incidentAssigneeFormStore.result.detail = value;
                },
              },
            },
          },
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
          button: {
            btnReport: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.DocumentMagnifyingGlassIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: Boolean(!this.incidentFormStore.result?.progress?.length || this.loading()),
                title: this.$t("btn.btn_manage_history"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
            },
            btnManage: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: this.$inArray(this.authStore.displayRole, PERMISSION_INCIDENT_CREATE) && this.$inArray(this.incidentFormStore?.result?.status, [this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW]),
                isIcon: true,
                componentIcon: this.$outlineIcons.PencilSquareIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: false,
                isDisable: !this.$inArray(this.incidentFormStore?.result?.status, [this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW]) || this.incidentAssigneeFormStore.loading,
                title: this.$t("btn.btn_approve_report"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
            },
            btnApprove: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: !this.$inArray(this.incidentFormStore?.result?.status, [this.$enums.ENUM_INCIDENT_STATUS.REPORTED, this.$enums.ENUM_INCIDENT_STATUS.CLOSED, this.$enums.ENUM_INCIDENT_STATUS.REJECTED]),
                isIcon: true,
                componentIcon: this.$outlineIcons.DocumentCheckIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: false,
                isDisable: this.$inArray(this.incidentFormStore?.result?.status, [this.$enums.ENUM_INCIDENT_STATUS.REPORTED, this.$enums.ENUM_INCIDENT_STATUS.CLOSED, this.$enums.ENUM_INCIDENT_STATUS.REJECTED]) || this.incidentAssigneeFormStore.loading,
                title: this.$t("btn.btn_approve_report"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async () => {
                await this.incidentAssigneeFormStore
                  .updateProgress(String(this.$route.params.id), {
                    status: this.$enums.ENUM_INCIDENT_STATUS.REPORTED,
                  })
                  .then(() => {
                    this.incidentFormStore.isSuccess = true;
                    this.incidentAssigneeFormStore.isProgressSuccess = true;
                    this.incidentFormStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
                    this.incidentAssigneeFormStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
                    this.incidentAssigneeFormStore.action = this.$enums.ENUM_MUTATION.RECEIVE;
                    this.incidentFormStore.isButtonState = this.$enums.ENUM_BUTTON_STATE.IN_REVIEW;
                  });
              },
            },
            btnClose: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: this.$inArray(this.authStore.displayRole, PERMISSION_INCIDENT_CLOSE) && this.$inArray(this.incidentFormStore?.result?.status, [this.$enums.ENUM_INCIDENT_STATUS.REPORTED]),
                isIcon: true,
                componentIcon: this.$outlineIcons.DocumentCheckIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: false,
                isDisable: !this.$inArray(this.incidentFormStore?.result?.status, [this.$enums.ENUM_INCIDENT_STATUS.REPORTED]) || this.incidentAssigneeFormStore.loading,
                title: this.$t("btn.btn_receieve_report"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async () => {
                this.incidentAssigneeFormStore.action = this.$enums.ENUM_MUTATION.RECEIVE;
                this.incidentFormStore.isButtonState = this.$enums.ENUM_BUTTON_STATE.REPORTED;
                await this.incidentAssigneeFormStore.updateProgress(String(this.$route.params.id), {
                  status: this.$enums.ENUM_INCIDENT_STATUS.CLOSED,
                });
              },
            },
            btnPrint: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.PrinterIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: false,
                isDisable: false,
                title: "Print",
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                const printableContent = document.querySelector(".print-content");
                if (!printableContent) return;

                window.print();
              },
            },
          },
          popup: {
            assigneeTextarea: {
              component: PopupAssignTextareaComponent,
              props: {
                isLoading: false,
                textTitle: this.incidentFormStore?.action == this.$enums.ENUM_MUTATION.REJECT ? this.$t("popup.textarea.reject_title") : this.$t("popup.textarea.process_title"),
                componentName: this.assigneeFormComponents.form.textarea.detail,
                textPlaceholder: this.$t("popup.textarea.process_placeholder"),
                functionProp: (e: Event) => {
                  this.incidentAssigneeFormStore.result.validate = true;
                  this.incidentAssigneeFormStore.result.status = this.$enums.ENUM_INCIDENT_STATUS.REPORTED;
                  this.incidentAssigneeFormStore.result.assigneeID = String(this?.authStore?.user?.id);
                  this.handleSubmitSaveForm(e);
                },
                functionCancelProp: () => {
                  this.isVisible = !this.isVisible;
                },
              } as typeof PopupAssignTextareaComponent.props,
            },
            cancelPopup: {
              component: PopupCancelComponent,
              props: {
                isOpen: this.incidentFormStore.isCancel,
                textTitle: this.$t("popup.cancel.cancel_title"),
                textDetail: this.$t("popup.cancel.cancel_detail"),
                functionProp: () => {
                  this.incidentFormStore.isCancel = false;
                  this.$router.push({ name: "IncidentView" });
                  this.reset();
                },
                functionCancelProp: async () => {
                  this.incidentFormStore.isCancel = false;
                },
              } as typeof PopupCancelComponent.props,
            },
            formSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.incidentFormStore.isSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.incidentFormStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            formCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.incidentFormStore.isSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("layout.error_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.incidentFormStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
        },
      };
    },
    hasApprovePermission() {
      return this.authStore.displayRole && PERMISSION_INCIDENT_APPROVE.includes(this.authStore.displayRole as ENUM_FIELD_ROLE);
    },
  },
  methods: {
    alertTextTitle() {
      if (this.incidentFormStore.isButtonState == this.$enums.ENUM_BUTTON_STATE.SAVE) return "popup.success.edit_title";
      else if (this.incidentFormStore.isButtonState == this.$enums.ENUM_BUTTON_STATE.IN_REVIEW) return "popup.success.confirm_title";
      else if (this.incidentFormStore.isButtonState == this.$enums.ENUM_BUTTON_STATE.REPORTED) return "popup.success.close_title";
      return "popup.success.edit_title";
    },
    alertTextDetail() {
      if (this.incidentFormStore.isButtonState == this.$enums.ENUM_BUTTON_STATE.SAVE) return "popup.success.edit_detail";
      else if (this.incidentFormStore.isButtonState == this.$enums.ENUM_BUTTON_STATE.IN_REVIEW) return "popup.success.confirm_detail";
      else if (this.incidentFormStore.isButtonState == this.$enums.ENUM_BUTTON_STATE.REPORTED) return "popup.success.close_detail";
      return "popup.success.edit_detail";
    },
    async handleSubmitSaveForm(e: Event) {
      const validate = await this.incidentAssigneeFormStore.onSubmit(e);

      this.incidentAssigneeFormStore.sendResult.status = this.$enums.ENUM_INCIDENT_STATUS.REPORTED;

      if (validate) {
        if (this.$route.params.id) {
          this.incidentAssigneeFormStore.sendResult = deleteKeysFromObject(this.incidentAssigneeFormStore.sendResult, ["validate", "routeName"]);
          await this.incidentAssigneeFormStore.updateProgress(String(this.$route.params.id), this.incidentAssigneeFormStore.sendResult);

          this.setting();
          this.isVisible = false;
        }
      }
    },
    async initializeMap() {
      const google = await this.$asyncGoogleMapsLoader();
      const { Map } = (await google.maps.importLibrary("maps")) as any;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as any;

      var thailandCoordinates = { lat: 13.736717, lng: 100.523186 };
      const map = new Map(document.getElementById("map") as HTMLElement, {
        center: this.$route.params.id
          ? {
              lat: Number(this.incidentFormStore.result.information.latitude),
              lng: Number(this.incidentFormStore.result.information.longitude),
            }
          : thailandCoordinates,
        zoom: 14,
        mapId: "4504f8b37365c3d0",
        mapTypeId: "roadmap",
      });

      const draggableMarker = new AdvancedMarkerElement({
        map,
        position: this.$route.params.id
          ? {
              lat: Number(this.incidentFormStore.result.information.latitude),
              lng: Number(this.incidentFormStore.result.information.longitude),
            }
          : thailandCoordinates,
        gmpDraggable: true,
        title: "This marker is draggable.",
      });
      draggableMarker.addListener("dragend", async (e: any) => {
        const position = draggableMarker.position as any;
        this.incidentFormStore.result.information.latitude = position.lat;
        this.incidentFormStore.result.information.longitude = position.lng;

        const newPosition = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        } as any;

        const geocoder = new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: newPosition },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("information.location") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.incidentFormStore.result.information.location = result.results[0].formatted_address;
      });
      if (!this.$route.params.id && navigator.geolocation) {
        const position = await Geolocation.getCurrentPosition();
        var selfCoordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.incidentFormStore.result.information.latitude = position.coords.latitude;
        this.incidentFormStore.result.information.longitude = position.coords.longitude;

        map.setCenter(selfCoordinates);
        draggableMarker.position = selfCoordinates;

        const geocoder = new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: selfCoordinates },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("information.location") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.incidentFormStore.result.information.location = result.results[0].formatted_address;
      }

      const input = document.getElementById("information.location") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as any);
      });

      let markers: any[] = [];
      searchBox.addListener("places_changed", () => {
        if (!this.incidentFormStore.isMap) {
          const places = searchBox.getPlaces();

          if (places.length == 0) return;
          markers.forEach((marker) => {
            marker.setMap(null);
          });
          markers = [];
          const bounds = new google.maps.LatLngBounds();

          places.forEach((place: any) => {
            if (!place.geometry || !place.geometry.location) return;

            draggableMarker.position = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };

            this.incidentFormStore.result.information.latitude = place.geometry.location.lat();
            this.incidentFormStore.result.information.longitude = place.geometry.location.lng();

            this.incidentFormStore.result.information.location = `${place.name}, ${place.formatted_address}`;

            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        }
      });
    },
    async handleAssignSubmit() {
      if (this.$route.params.id) {
        this.incidentAssigneeFormStore.sendResult = deleteKeysFromObject(this.incidentAssigneeFormStore.sendResult, ["validate", "routeName"]);
        await this.incidentAssigneeFormStore.updateProgress(String(this.$route.params.id), this.incidentAssigneeFormStore.sendResult);
      }
    },
    color() {
      return `text-[${this.$getColorStatus(new Date(this.incidentFormStore.metadata.createdAt), new Date(this.incidentFormStore.metadata.eta), new Date(this.incidentFormStore.metadata.arrived), "TH").color}]`;
    },
    loading() {
      return this.incidentFormStore.loading || this.incidentAssigneeFormStore.loading;
    },
    async fetch() {
      if (this.$route.params.id) {
        await Promise.all([
          (async () => {
            this.incidentFormStore.result = await this.incidentFormStore.fetchIncident.Get(this.$route.params.id as string);

            // Get Image
            if (this?.incidentFormStore?.result?.images?.length) {
              this.incidentFormStore.result.images = await Promise.all(await this.incidentFormStore.fetchFile.Get(this?.incidentFormStore?.result?.images));
            }

            //  Check Process
            this.incidentAssigneeFormStore.isShowTime = this.$inArray(this.incidentFormStore?.result?.status, [this.$enums.ENUM_INCIDENT_STATUS.WAITING]);
            this.incidentAssigneeFormStore.metadata = this.$extractMetadata(this.incidentFormStore?.result?.progress || []);
            this.incidentAssigneeFormStore.isETA = Boolean(this.incidentAssigneeFormStore?.metadata?.eta);
            this.incidentAssigneeFormStore.isArrive = Boolean(this.incidentAssigneeFormStore?.metadata?.arrived);
            this.incidentAssigneeFormStore.result.assigneeID = this.authStore.user?.id;
            this.incidentAssigneeFormStore.isRequired.metadata.is = this.incidentAssigneeFormStore.isShowTime;

            //  Check Process
            this.incidentAssigneeFormStore.metadata = this.$extractMetadata(this.incidentFormStore?.result?.progress || []);
            if (this.incidentAssigneeFormStore.metadata) {
              this.incidentAssigneeFormStore.calculate = await this.$calculateTimeColor(new Date().toISOString(), this.incidentAssigneeFormStore?.metadata?.arrived, this.incidentAssigneeFormStore?.metadata?.createdAt, this.$calculateMinutesDifference(this.incidentAssigneeFormStore?.metadata?.createdAt, this.incidentAssigneeFormStore?.metadata?.eta));
            }

            this.$configLightbox();
          })(),
          (async () => {
            this.incidentAssigneeFormStore.results = this.$transformUserData(
              await this.incidentAssigneeFormStore.fetchUser.List({
                isAgent: true,
                size: Number(import.meta.env.VITE_PAGE_SIZE),
              })
            );
          })(),
        ]);

        if (this.incidentFormStore?.result?.progress?.length) {
          this.incidentAssigneeFormStore.result.detail = this.$convertString(this.$findStatusDetail(this.incidentFormStore?.result?.progress, this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW));
        }

        if (this.incidentFormStore?.result?.report) {
          this.report = this.incidentFormStore?.result?.report;

          const file = new fetchFile();
          const reportImages = await Promise.all((this.report?.images || []).map((i: any) => file.GetUrl(i)));
          this.reportImages = reportImages.filter((s) => s?.length) as string[];
        }
      }

      this.incidentFormStore.loading = false;
    },
    async reset() {
      await this.incidentFormStore.$reset();
      await this.incidentAssigneeFormStore.$reset();
      this.report = {
        detail: "",
        images: [],
      };
    },
    async setting() {
      await this.reset();
      await this.fetch();
      await this.initializeMap();
      await this.$configLightbox();

      this.incidentAssigneeFormStore.isRequired.assigneeID.is = false;
      this.incidentAssigneeFormStore.result.routeName = "IncidentNoticeView";
    },
    async handleEditReport() {
      if (!this.editingReport) {
        this.editingReport = true;
        return;
      }

      try {
        this.updatingReport = true;
        await this.incidentAssigneeFormStore.updateProgress(this.$route.params.id as string, {
          status: this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW,
          detail: "แก้ไขรายงาน",
        });

        this.incidentAssigneeFormStore.putReport(this.$route.params.id as string, {
          detail: this.report.detail,
          images: this.report.images,
        });

        this.editingReport = false;
      } catch (e) {
      } finally {
        this.updatingReport = false;
      }
    },
  },
  async mounted() {
    await this.setting();
    this.intervalId = setInterval(async () => {
      const now = new Date();
      this.incidentAssigneeFormStore.calculate = await this.$calculateTimeColor(now.toISOString(), this.incidentAssigneeFormStore?.metadata?.arrived, this.incidentAssigneeFormStore?.metadata?.createdAt, this.$calculateMinutesDifference(this.incidentAssigneeFormStore?.metadata?.createdAt, this.incidentAssigneeFormStore?.metadata?.eta));
      this.timeLeft = this.$calculateTimeDifference(now, new Date(this.incidentAssigneeFormStore?.metadata?.eta), this.masterDataStore.lang());
      if (now >= new Date(this.incidentAssigneeFormStore?.metadata?.eta)) {
        clearInterval(this.intervalId);
        this.timeLeft = "None";
      }
    }, 1000);
  },
  async unmounted() {
    await this.reset();
  },
  created() {
    watch([() => this.$route.query.page], async () => {
      await this.fetch();
    });
  },
  setup() {
    const masterDataStore = useMasterDataStore();
    const incidentFormStore = useIncidentFormStore();
    const incidentAssigneeFormStore = useIncidentAssigneeFormStore();
    const authStore = useAuthStore();

    const addColor = computed(() => {
      switch (incidentAssigneeFormStore?.calculate?.now?.colorSegment) {
        case "Green":
          return "text-green-600";
        case "Yellow":
          return "text-yellow-300";
        case "Red":
          return "text-red-600";
        default:
          return "text-gray-600";
      }
    });

    const addArriveColor = computed(() => {
      switch (incidentAssigneeFormStore?.calculate?.arrived?.colorSegment) {
        case "Green":
          return "text-green-600";
        case "Yellow":
          return "text-yellow-300";
        case "Red":
          return "text-red-600";
        default:
          return "text-gray-600";
      }
    });
    return {
      masterDataStore,
      incidentFormStore,
      incidentAssigneeFormStore,
      authStore,
      addColor,
      addArriveColor,
    };
  },
});
</script>

<template>
  <div class="print-content grid grid-cols-12 lg:gap-2">
    <div class="col-start-1 col-end-13">
      <div class="ttp-layout px-0 md:px-6 mt-6 mb-0 md:pt-14">
        <div class="p-4">
          <div class="xl:flex gap-2 items-center mb-6">
            <router-link
              :to="{
                name: 'IncidentNoticeView',
                params: $route.params,
              }"
              class="inline-flex items-center justify-center xl:absolute"
            >
              <component :is="$solidIcons.ChevronLeftIcon" class="inline-block ttp-icon-inside-box-03" />
              กลับไปดูใบแจ้งเหตุ
            </router-link>
            <h1 class="ttp-text-lg text-black font-medium text-center mx-auto">
              {{ $t("layout.menu.HistoryReportView") }}
            </h1>
          </div>
          <div class="flex gap-2 w-full justify-center items-center mb-6">
            <div>
              <component :is="$outlineIcons.DocumentTextIcon" class="flex-none inline-block ttp-icon-inside-box-02 text-gray-600" />
            </div>
            <div class="flex flex-col w-full">
              <div>
                <div class="text-gray-600 ttp-text-base font-semibold leading-tight mb-1 text-left">
                  <span class="font-medium"><component :is="$solidIcons.IdentificationIcon" class="flex-none inline-block ttp-icon-inside-box-03" /> {{ $t("page.IncidentManageView.document_number") }}</span>
                  <span class="ml-1"> {{ $convertString(incidentFormStore?.result?.incidentNumber) }}</span>
                </div>
              </div>
              <div class="flex justify-start items-start md:flex-row gap-2 w-full text-gray-600 ttp-text-xs text-left">
                <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03" /><span class="font-medium">{{ $t("page.IncidentManageView.receive_date") }}</span>
                <div class="leading-0 flex justify-center items-center">
                  <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(incidentFormStore?.result?.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
                </div>
              </div>
              <div class="flex justify-start items-start md:flex-row gap-2 w-full text-gray-600 ttp-text-xs text-left">
                <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03" /><span class="font-medium">{{ $t("form.updated_date.label") }}</span>
                <div class="leading-0 flex justify-center items-center">
                  <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(incidentFormStore?.result?.updatedAt).format("DD/MM/YYYY HH:mm") }}</span>
                </div>
              </div>
            </div>
            <div class="flex w-full">
              <div class="flex flex-col md:flex-row gap-2 w-full">
                <component v-if="hasApprovePermission" @click="defaultFormComponents.form.button.btnApprove.click" :classTagName="'w-full h-12 col-end-0 col-span-3 md:col-end-7 md:col-span-2 lg:col-end-7 lg:col-span-1'" :is="defaultFormComponents.form.button.btnApprove.component" v-bind="defaultFormComponents.form.button.btnApprove.props" />
                <component :classTagName="'w-full h-12 col-end-0 col-span-3 md:col-end-5 md:col-span-2 lg:col-end-6 lg:col-span-1'" v-bind="defaultFormComponents.form.button.btnClose.props" :is="defaultFormComponents.form.button.btnClose.component" @click="defaultFormComponents.form.button.btnClose.click" />
                <component :classTagName="'w-full h-12 col-end-0 col-span-3 md:col-end-5 md:col-span-2 lg:col-end-6 lg:col-span-1'" :is="defaultFormComponents.form.button.btnReport.component" v-bind="defaultFormComponents.form.button.btnReport.props" @click="incidentFormStore.openHistoryModal" />
                <component :classTagName="'w-full h-12 col-end-0 col-span-3 md:col-end-5 md:col-span-2 lg:col-end-6 lg:col-span-1'" :is="defaultFormComponents.form.button.btnPrint.component" v-bind="defaultFormComponents.form.button.btnPrint.props" @click="defaultFormComponents.form.button.btnPrint.click" />
              </div>
            </div>
          </div>
          <hr class="w-full my-4" />
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.status.label") }} : </span><span class="text-gray-500 ttp-text-xs"><component :is="defaultFormComponents.form.badge.status.component" v-bind="defaultFormComponents.form.badge.status.props" :title="$formattedStatus($classStatus(incidentFormStore?.result?.status))" :classEnumName="$classStatus(incidentFormStore?.result?.status)" /></span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.notification_channel.label") }} : </span><span class="text-gray-500 ttp-text-xs">{{ incidentFormStore?.result?.channel == "1155" ? $t("role.CENTRAL_OFFICER") : $convertString(incidentFormStore?.result?.channel) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">ชื่อผู้ติดต่อในกรณีฉุกเฉิน / ผู้แจ้ง : </span>
            <span class="text-gray-500 ttp-text-xs">{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.emergencyContact?.name) : $convertString(incidentFormStore?.result?.tourist?.emergencyContact?.name) }}</span>
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">เบอร์ติดต่อ : </span>
            <span class="text-gray-500 ttp-text-xs">{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.emergencyContact?.contactNumber) : $convertString(incidentFormStore?.result?.tourist?.emergencyContact?.contactNumber) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.touristDetail.label") }} : </span><span class="text-gray-500 ttp-text-xs">{{ $convertString(incidentFormStore?.result?.information?.detail) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.officerDetail.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString($findStatusDetail(incidentFormStore?.result?.progress || [], $enums.ENUM_INCIDENT_STATUS.WAITING)) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.no.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(incidentFormStore?.result?.information?.incidentCode) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.story_type.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(masterDataStore.getIncidentCategoryByCodeAndLocale(incidentFormStore?.result?.information?.categoryCode, $enums.ENUM_FIELD_LOCALE.TH)) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.subject.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(masterDataStore.getIncidentTopicByCodeAndLocale(incidentFormStore?.result?.information?.topicCode, $enums.ENUM_FIELD_LOCALE.TH)) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.important.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(masterDataStore.getInformationPriorityName(incidentFormStore?.result?.information?.priority)) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.province_area.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(incidentFormStore?.result?.information?.province?.name?.TH) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.district.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(incidentFormStore?.result?.information?.district?.name?.TH) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.location_detail.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs"> {{ $convertString(incidentFormStore?.result?.information?.location) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.officerDetail.label") }}:</span>
            <span class="text-gray-500 ttp-text-xs"> {{ incidentFormStore?.result?.progress?.find((p) => p.status === $enums.ENUM_INCIDENT_STATUS.WAITING)?.detail || "-" }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">รายงาน:</span>
            <span class="ml-4">
              <button class="rounded bg-yellow-300 px-2 py-1 ttp-text-xs font-semibold leading-4 text-yellow-800" @click="handleEditReport">{{ editingReport ? "บันทึก" : "แก้ไข" }}</button>
            </span>
          </div>
          <div class="w-full mb-2">
            <div v-if="!editingReport" class="text-gray-900 dark:text-gray-50 whitespace-pre-wrap">{{ report.detail }}</div>
            <textarea v-else v-model="report.detail" class="text-gray-900 dark:text-gray-50 block w-full rounded-lg border border-gray-200 px-3 py-2 leading-6 placeholder-gray-500 focus:border-blue-500 focus:ring focus:ring-blue-500/50 dark:border-gray-600 dark:bg-gray-800 dark:placeholder-gray-400 dark:focus:border-blue-500" />
          </div>
          <div v-if="!reportImages.length" class="w-full mb-2">
            <p class="text-gray-500 mb-2">รูปภาพรายงาน</p>
            <div class="grid sm:grid-cols-2 gap-4">
              <div v-for="url in incidentFormStore?.result?.images" class="w-full">
                <img :src="url.url" class="object-center object-contain rounded-lg aspect-[16/9] w-full bg-white" />
              </div>
            </div>
          </div>
          <hr class="w-full my-4" />
          <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50 mb-2">
            {{ $t("page.IncidentManageView.information") }}
          </div>
          <div class="flex w-full">
            <div v-if="$inArray(incidentFormStore?.result?.informerType, [$enums.ENUM_INFORMER_TYPE.GUEST, $enums.ENUM_INFORMER_TYPE.TOURIST])" class="w-full">
              <div class="flex flex-col xl:flex-row">
                <div class="w-full">
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.name.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.name) : $convertString(incidentFormStore?.result?.tourist?.name) }}</span>
                  </div>
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.nationality.label") }}:</span> <span class="text-gray-500">{{ $convertString(masterDataStore.itemNationalities().find(({ value }: any) => value === (incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.nationality) : $convertString(incidentFormStore?.result?.tourist?.nationality)))?.label) }}</span>
                  </div>
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.country.label") }}:</span> <span class="text-gray-500">{{ $convertString(masterDataStore.itemCountries().find(({ value }: any) => value === (incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.country) : $convertString(incidentFormStore?.result?.tourist?.country)))?.label) }}</span>
                  </div>
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.phone.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.contactNumber) : $convertString(incidentFormStore?.result?.tourist?.contactNumber) }}</span>
                  </div>
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.email.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.email) : $convertString(incidentFormStore?.result?.tourist?.email) }}</span>
                  </div>
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.gender.label") }}:</span> <span class="text-gray-500">{{ $convertString(incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? masterDataStore.getGenderName(incidentFormStore?.result?.guest?.gender as typeof $enums.ENUM_GENDER) : "") }}</span>
                  </div>
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.passport_number.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.identificationNumber) : $convertString(incidentFormStore?.result?.tourist?.identificationNumber) }}</span>
                  </div>
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.notification_channel.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.channel == "1155" ? $t("role.CENTRAL_OFFICER") : $convertString(incidentFormStore?.result?.channel) }}</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-col xl:flex-row">
                <div class="w-full">
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.emergency_contact.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.emergencyContact?.name) : $convertString(incidentFormStore?.result?.tourist?.emergencyContact?.name) }}</span>
                  </div>
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.phone.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.emergencyContact?.contactNumber) : $convertString(incidentFormStore?.result?.tourist?.emergencyContact?.contactNumber) }}</span>
                  </div>
                  <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                    <span class="font-medium">{{ $t("form.passport_number.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.emergencyContact?.identificationNumber) : $convertString(incidentFormStore?.result?.tourist?.emergencyContact?.identificationNumber) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="w-full my-4" />
          <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50 mb-2">
            {{ $t("form.admin_user.label") }}
          </div>
          <div class="flex flex-col xl:flex-row">
            <div class="w-full">
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.subject_user.label") }}:</span> <span class="text-gray-500">{{ $convertString(incidentFormStore?.result?.responsible?.content?.[0]?.fullName) }}</span>
              </div>
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.phone.label") }}:</span> <span class="text-gray-500">{{ $convertString(incidentFormStore?.result?.responsible?.contactNumber) }}</span>
              </div>
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.division.label") }}:</span> <span class="text-gray-500">{{ $convertString(masterDataStore.getOrganizationByCode(incidentFormStore?.result?.responsible?.organization)) }}</span>
              </div>
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.responsibleArea.label") }}:</span> <span class="text-gray-500">{{ $convertString(masterDataStore.getAllResponsibleAreaFullNames(incidentFormStore?.result?.responsible?.responsibleAreas)) }}</span>
              </div>
            </div>
          </div>
          <div v-if="false" class="flex flex-col xl:flex-row">
            <div class="w-full">
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.control_user.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.channel == "1155" ? $t("role.CENTRAL_OFFICER") : $convertString(incidentFormStore?.result?.channel) }}</span>
              </div>
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.phone.label") }}:</span> <span class="text-gray-500">{{ $convertString(incidentFormStore?.result?.responsible?.contactNumber) }}</span>
              </div>
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.division.label") }}:</span> <span class="text-gray-500">{{ $convertString(masterDataStore.getOrganizationByCode(incidentFormStore?.result?.responsible?.organization)) }}</span>
              </div>
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.responsibleArea.label") }}:</span> <span class="text-gray-500">{{ $convertString(masterDataStore.getAllResponsibleAreaFullNames(incidentFormStore?.result?.responsible?.responsibleAreas)) }}</span>
              </div>
            </div>
          </div>
          <div v-if="false" class="flex flex-col xl:flex-row">
            <div class="w-full">
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.responsible_person.label") }}:</span> <span class="text-gray-500">{{ incidentFormStore?.result?.channel == "1155" ? $t("role.CENTRAL_OFFICER") : $convertString(incidentFormStore?.result?.channel) }}</span>
              </div>
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.phone.label") }}:</span> <span class="text-gray-500">{{ $convertString(incidentFormStore?.result?.responsible?.contactNumber) }}</span>
              </div>
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.division.label") }}:</span> <span class="text-gray-500">{{ $convertString(masterDataStore.getOrganizationByCode(incidentFormStore?.result?.responsible?.organization)) }}</span>
              </div>
              <div class="ttp-text-xs text-gray-900 dark:text-gray-50 mb-2 mr-3 float-left">
                <span class="font-medium">{{ $t("form.responsibleArea.label") }}:</span> <span class="text-gray-500">{{ $convertString(masterDataStore.getAllResponsibleAreaFullNames(incidentFormStore?.result?.responsible?.responsibleAreas)) }}</span>
              </div>
            </div>
          </div>
          <hr class="ttp-google-map w-full my-4" />
          <div class="flex mb-4 w-full">
            <div class="mt-2 w-full">
              <div class="ttp-google-map flex ttp-text-xs font-medium text-gray-900 dark:text-gray-50 leading-tight">
                <button
                  type="button"
                  @click="
                    () => {
                      incidentFormStore.isMap = !incidentFormStore.isMap;
                      if (incidentFormStore.isMap && !incidentFormStore.isMapGenerate) {
                        initializeMap();
                      }
                    }
                  "
                  class="flex w-full items-center justify-center"
                >
                  <div class="flex w-full ttp-text-xs gap-2">{{ $t("form.pin_location.label") }} <component :is="incidentFormStore.isMap ? $solidIcons.ChevronDownIcon : $solidIcons.ChevronUpIcon" class="inline-block ttp-icon-inside-box-03 opacity-40" /></div>
                </button>
                <!-- Google Map Link -->
                <div v-if="!$inArray(incidentFormStore?.result?.information?.latitude, [13.736717, null])" class="w-full text-right ttp-text-xs">
                  <a :href="`https://maps.google.com/?q=${incidentFormStore?.result?.information?.latitude},${incidentFormStore?.result?.information?.longitude}`" target="_blank"
                    ><div class="inline-flex rounded bg-blue-200 px-2 py-1 ttp-text-xs font-semibold leading-4 text-blue-700">{{ $t("btn.btn_google_map") }}</div></a
                  >
                </div>
              </div>
              <div :class="[{ 'flex w-full h-60 mt-2 relative bg-white border border-gray-200': true, hidden: !incidentFormStore.isMap }]">
                <div class="flex w-full h-full">
                  <div v-if="incidentFormStore.isMap" :class="{ 'absolute flex w-full h-full z-10 items-center justify-center': true, 'bg-black opacity-80': $route.params.id && incidentFormStore.result.information.latitude == 13.736717 }">
                    <div v-if="$route.params.id && incidentFormStore.result.information.latitude == 13.736717" class="text-white">ไม่พบพิกัดสถานที่</div>
                  </div>
                  <div id="map"></div>
                </div>
              </div>
            </div>
          </div>
          <hr class="w-full my-4" />
          <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50 mb-3">รูปภาพ</div>
          <div id="ttp-gallery" class="flex gap-2 mb-4">
            <div class="swipe-photo flex flex-col sm:flex-row gap-5 w-full">
              <div v-if="$isArrayOfStringsOrObjects(incidentFormStore?.result?.images)" class="flex flex-col justify-center items-center w-full">
                <div><LoadingFormComponent /></div>
                <div class="ttp-text-xs">{{ $t("layout.loading") }}</div>
              </div>
              <a v-else-if="incidentFormStore?.result?.images?.length" v-for="(_item, index) in incidentFormStore?.result?.images" :key="index" :href="_item?.url ? _item?.url : '#'" target="_blank" class="w-full">
                <img class="object-center object-contain rounded-lg aspect-[16/9] w-full bg-white" :src="_item?.url" alt="" />
              </a>
              <div v-else>-</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TransitionRoot appear :show="incidentFormStore.isHistory" as="template">
    <Dialog as="div" @close="incidentFormStore.closeHistoryModal" class="relative z-9999" :show="incidentFormStore.isHistory">
      <div class="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm" />
      <div class="fixed inset-0 overflow-y-auto p-4 lg:p-8">
        <DialogPanel class="w-full max-w-xl mx-auto flex flex-col rounded-lg shadow-md bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800">
          <div class="relative p-4">
            <div class="flex">
              <div class="w-full text-gray-800 ttp-text-base font-medium leading-normal">
                <div class="inline-flex items-center justify-center px-3 py-2">{{ $t("page.IncidentNoticeView.history") }} ({{ $t("page.IncidentNoticeView.number_code") }} {{ $convertString(incidentFormStore?.result?.incidentNumber) }})</div>
              </div>
              <div class="text-right">
                <button @click="incidentFormStore.closeHistoryModal" type="button" class="inline-flex items-center justify-center space-x-2 rounded-lg border border-transparent px-3 py-2 ttp-text-xs font-semibold leading-5 text-gray-800 hover:text-gray-900 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700">
                  <component :is="$solidIcons.XMarkIcon" class="inline-block ttp-icon-inside-box-03" />
                </button>
              </div>
            </div>
            <div class="my-5">
              <div class="relative py-5 dark:text-gray-100">
                <div class="w-6 md:w-12 lg:ml-40 absolute top-0 left-0 bottom-0 flex flex-col justify-center" aria-hidden="true">
                  <div class="mx-auto w-1 h-2.5 grow-0 bg-gradient-to-b from-transparent to-teal-100 rounded-t dark:to-teal-900"></div>
                  <div class="mx-auto w-1 grow bg-ttp-new dark:bg-ttp-new"></div>
                  <div class="mx-auto w-1 h-2.5 grow-0 bg-gradient-to-t from-transparent to-teal-100 rounded-b dark:to-teal-900"></div>
                </div>
                <ul class="relative space-y-4 pl-10 md:pl-12 lg:ml-40">
                  <li v-for="(data, n) in incidentFormStore?.result?.progress" :key="n" class="relative">
                    <div class="w-14 md:w-12 absolute top-0 left-0 bottom-0 -translate-x-full flex justify-center mt-3">
                      <div v-if="n == 0" class="ttp-icon-inside-box-03 text-white bg-ttp-success rounded-full flex items-center justify-center dark:text-teal-900 dark:bg-teal-300 dark:ring-teal-900 dark:ring-offset-gray-900">
                        <component :is="$solidIcons.CheckIcon" class="inline-block h-4 w-4" />
                      </div>

                      <div v-else class="ttp-icon-inside-box-04 bg-ttp-new rounded-full dark:bg-teal-300 dark:ring-teal-900 dark:ring-offset-gray-900"></div>
                    </div>
                    <div class="bg-gray-100 hover:ring hover:ring-gray-100 hover:ring-offset-2 rounded-xl p-4 dark:bg-gray-800 dark:hover:ring-gray-700 dark:ring-offset-gray-900">
                      <div class="px-1 ttp-text-xs leading-[14px] mb-2">
                        <component :is="defaultFormComponents.form.badge.status.component" v-bind="defaultFormComponents.form.badge.status.props" :title="$formattedStatus($classStatus(data?.status))" :classEnumName="$classStatus(data?.status)" />
                      </div>
                      <div class="px-1 mt-2 ttp-text-xs leading-[14px]">
                        {{ masterDataStore.getStatusByCode($classStatus(data?.status), false) }}
                      </div>
                      <div class="px-1 mt-2 ttp-text-xs text-gray-500 leading-[14px]">
                        {{ $t("layout.result") }} :
                        {{ $convertString(data?.detail) }}
                      </div>
                      <div class="px-1 mt-2 ttp-text-xs text-gray-500 leading-[14px]">
                        {{ masterDataStore.getStatusDetailByCode($classStatus(data?.status)) }}
                        :
                        {{ $convertString(data?.createdBy?.content[0]?.shortName) }}
                      </div>
                    </div>
                    <div class="px-4 py-2 lg:w-40 lg:absolute lg:top-0 lg:left-0 lg:bottom-0 lg:-translate-x-full lg:flex lg:flex-col lg:text-right lg:mt-4 lg:-ml-12 lg:p-0">
                      <div class="ttp-text-xs font-bold text-[#2c3483]">{{ $t("progress.processing_time") }}</div>
                      <div class="ttp-text-xs mb-2">{{ $dayjs(data?.createdAt).format("DD/MM/YYYY") }} {{ $dayjs(data?.createdAt).format("HH:mm") }}</div>
                      <div v-if="n + 1 != incidentFormStore?.result?.progress?.length" class="ttp-text-xs font-bold text-[#2c3483]">{{ $t("progress.period_time") }}</div>
                      <div v-if="n + 1 != incidentFormStore?.result?.progress?.length && incidentFormStore?.result?.progress?.[n + 1]" class="ttp-text-xs">{{ $calculateTimeDifference(new Date(incidentFormStore?.result?.progress[n + 1]?.createdAt), new Date(data?.createdAt), masterDataStore.lang()) }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Assignee Popup -->
  <component :isOpen="isVisible" :is="defaultFormComponents.form.popup.assigneeTextarea.component" v-bind="defaultFormComponents.form.popup.assigneeTextarea.props" />

  <!-- Cancel Popup -->
  <component :is="defaultFormComponents.form.popup.cancelPopup.component" v-bind="defaultFormComponents.form.popup.cancelPopup.props" />

  <!-- Assignee Success & Caution -->
  <component v-if="incidentFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS || incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="assigneeFormComponents.form.popup.assigneeSuccess.component" v-bind="assigneeFormComponents.form.popup.assigneeSuccess.props" />
  <component v-else-if="incidentFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR || incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="assigneeFormComponents.form.popup.assigneeCaution.component" v-bind="assigneeFormComponents.form.popup.assigneeCaution.props" />

  <!-- Progress -->
  <component v-if="incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="assigneeFormComponents.form.popup.progressSuccess.component" v-bind="assigneeFormComponents.form.popup.progressSuccess.props" />
  <component v-else-if="incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="assigneeFormComponents.form.popup.progressCaution.component" v-bind="assigneeFormComponents.form.popup.progressCaution.props" />
</template>

<style style="scss">
@media print {
  @page {
    size: A4;
    margin: 0;
  }
  .print-content,
  .ttp-layout {
    width: 100% !important;
    height: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  button,
  header,
  h2,
  nav,
  .ttp-google-map {
    display: none !important;
  }
  .ttp-layout {
    border: none;
    box-shadow: none;
  }
  #page-content,
  .container {
    padding: 0;
    margin: 0;
    display: contents;
  }
}
</style>
