import { defineStore } from "pinia";
import * as yup from "yup";
import { fetchUser } from "@/configs/fetches/PoliceCommandCenter/fetchUser";
import { ENUM_FIELD_LOCALE, ENUM_FIELD_STATUS, ENUM_MUTATION, ENUM_MUTATION_STATUS } from "@/configs/enums/enum";
import type { typeGetUser } from "@/configs/types/User/typeUser";
import { convertToValues, convertToNumber } from "@/configs/helpers/MainHelper";
import { fetchFile } from "@/configs/fetches/PoliceCommandCenter/fetchFile";

const storeName = `useUserFormStore-${import.meta.env.VITE_APP_VERSION}`;

const isRequired = {
  email: {
    is: true,
    name: `email`,
    label: `form.email.label`,
    placeholder: `form.email.placeholder`,
    value: null,
  },
  password: {
    is: true,
    name: `password`,
    label: `form.password.label`,
    placeholder: `form.password.placeholder`,
    value: null,
  },
  titleCode: {
    is: true,
    name: `titleCode`,
    label: `form.title.label`,
    placeholder: `form.title.placeholder`,
    value: null,
  },
  role: {
    is: true,
    name: `role`,
    label: `form.role.label`,
    placeholder: `form.role.placeholder`,
    value: null,
  },
  status: {
    is: false,
    name: `status`,
    label: `form.status.label`,
    placeholder: `form.status.placeholder`,
    value: null,
  },
  responsibleAreaCodes: {
    is: true,
    name: "responsibleAreaCodes",
    label: "form.responsibleAreaCodes.label",
    placeholder: "form.responsibleAreaCodes.placeholder",
    value: null,
  },
  locales: {
    is: true,
    name: "locales",
    label: "form.locales.label",
    placeholder: "form.locales.placeholder",
    value: null,
  },
  idCard: {
    is: true,
    name: `idCard`,
    label: `form.idCard.label`,
    placeholder: `form.idCard.placeholder`,
    value: null,
  },
  isAgent: {
    is: false,
    name: `isAgent`,
    label: `form.isOpenAgent.label`,
    placeholder: `form.isOpenAgent.placeholder`,
    value: false,
  },
  contactNumber: {
    is: false,
    name: `contactNumber`,
    label: `form.phone.label`,
    placeholder: `form.phone.placeholder`,
    value: null,
  },
  content: [
    {
      locale: {
        is: true,
        name: `content[0].locale`,
        label: `form.isOpenAgent.label`,
        placeholder: `form.isOpenAgent.placeholder`,
        value: `TH`,
      },
      firstName: {
        is: true,
        name: `content[0].firstName`,
        label: `form.firstName.label`,
        placeholder: `form.firstName.placeholder`,
        value: null,
      },
      lastName: {
        is: true,
        name: `content[0].lastName`,
        label: `form.lastName.label`,
        placeholder: `form.lastName.placeholder`,
        value: null,
      },
    },
    {
      locale: {
        is: true,
        name: `content[1].locale`,
        label: `form.isOpenAgent.label`,
        placeholder: `form.isOpenAgent.placeholder`,
        value: `EN`,
      },
      firstName: {
        is: false,
        name: `content[1].firstName`,
        label: `form.firstName.label`,
        placeholder: `form.firstName.placeholder`,
        value: null,
      },
      lastName: {
        is: false,
        name: `content[1].lastName`,
        label: `form.lastName.label`,
        placeholder: `form.lastName.placeholder`,
        value: null,
      },
    },
  ],
};
export const useUserFormStore = defineStore(storeName, {
  state: () => ({
    fetchUser: new fetchUser(),
    fetchFile: new fetchFile(),
    isOpen: false,
    isSuccess: false,
    isCancel: false,
    isDelete: false,
    loading: true,
    isRequired,
    action: null as ENUM_MUTATION | null,
    actionStatus: null as ENUM_MUTATION_STATUS | null,
    results: [] as any,
    result: convertToValues(isRequired) as typeGetUser,
    error: {} as { [key: string]: string | null },
    validationSchema: yup.object({
      email: isRequired.email.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      password: yup.string().when(["id"], (id) => {
        return id[0] === undefined ? yup.string().nullable().required() : yup.string().nullable().notRequired();
      }),
      titleCode: isRequired.titleCode.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      role: isRequired.role.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      status: isRequired.status.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      idCard: isRequired.idCard.is ? yup.string().required() : yup.string().nullable().notRequired(),
      isAgent: isRequired.status.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
      content: yup.array().of(
        yup.lazy((value) => {
          if (value && value.locale === ENUM_FIELD_LOCALE.TH) {
            return yup.object().shape({
              locale: isRequired.content[0].locale.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              firstName: isRequired.content[0].firstName.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              lastName: isRequired.content[0].lastName.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
            });
          } else if (value && value.locale === ENUM_FIELD_LOCALE.EN) {
            return yup.object().shape({
              locale: isRequired.content[1].locale.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              firstName: isRequired.content[1].firstName.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
              lastName: isRequired.content[1].lastName.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
            });
          }
          return yup.object();
        })
      ),
      contactNumber: isRequired.contactNumber.is ? yup.string().nullable().required() : yup.string().nullable().notRequired(),
    }),
  }),
  actions: {
    async onDeleteImageSubmit(e: Event) {
      if (e) e.preventDefault();
      this.result.image = "";
      return await this.update(this.result);
    },
    async onSubmit(e: Event) {
      if (e) e.preventDefault();

      const keysToConvert: string[] = [];
      this.result = convertToNumber(this.result, keysToConvert);

      Object.keys(this.error).forEach((key) => {
        this.error[key] = null;
      });

      if (!this.result.status) {
        this.result.status = ENUM_FIELD_STATUS.INACTIVE;
      }

      try {
        await this.validationSchema.validate(this.result, {
          abortEarly: false,
        });

        if (!this.result.id?.length) {
          return await this.create(this.result);
        } else {
          return await this.update(this.result);
        }
      } catch (error: any) {
        (error.inner || []).map((inner: any) => {
          if (inner.message.length) {
            this.error[inner.path] = inner.message;
          }
        });
      }
    },

    async create(result: typeGetUser) {
      try {
        this.loading = true;
        const data = await this.fetchUser.Create(result);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }
    },
    async update(result: typeGetUser): Promise<typeGetUser> {
      try {
        this.loading = true;
        const data = await this.fetchUser.Update(result.id || "", result);
        this.actionStatus = ENUM_MUTATION_STATUS.SUCCESS;
        this.loading = false;

        this.isSuccess = true;
        return data;
      } catch (error: any) {
        this.actionStatus = ENUM_MUTATION_STATUS.ERROR;
        this.loading = false;

        this.isSuccess = true;
        throw error;
      }
    },
  },
});
