<script lang="ts">
import { computed, defineComponent, watch } from "vue";
import { Geolocation } from "@capacitor/geolocation";
import { useIncidentShareSearchStore } from "./stores/useIncidentShareSearchStore";
import { useIncidentFormStore } from "./stores/useIncidentFormStore";
import { useIncidentFormStore as useComponentIncidentFormStore } from "../../../components/CommandCenter/stores/useIncidentFormStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { useIncidentAssigneeFormStore } from "./stores/useIncidentAssigneeFormStore";
import { useAuthStore } from "@/views/PoliceCommandCenter/Authentication/stores/useAuthStore";
import { deleteKeysFromObject } from "@/configs/helpers/MainHelper";
import type { $string } from "@/configs/types/Shared/typeShare";

import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import ButtonStyle2Component from "@/components/Forms/Button/ButtonStyle2Component.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import FormTextareaDefaultComponent from "@/components/Forms/Textarea/FormTextareaDefaultComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import ChatDialogComponent from "@/components/CommandCenter/ChatDialog.vue";

import { TransitionRoot, Dialog, DialogPanel } from "@headlessui/vue";
import { PERMISSION_INCIDENT_CLOSE, PERMISSION_INCIDENT_CLOSED, PERMISSION_INCIDENT_REASSIGN, PERMISSION_INCIDENT_IN_REVIEW } from "@/configs/permission";
import Responsible from "./Notice/Responsible.vue";
import { ENUM_FIELD_ROLE, ENUM_INCIDENT_STATUS } from "@/configs/enums/enum";

export default defineComponent({
  name: "IncidentNoticeView",
  components: {
    TransitionRoot,
    Dialog,
    DialogPanel,
    ChatDialogComponent,
    Responsible,
  },
  data() {
    return {
      componentName: this.$options.name,
      endDateTime: new Date(),
      timeLeft: "",
      intervalId: "" as any,
      currentAssigneeID: "",
      currentStatus: undefined as ENUM_INCIDENT_STATUS | undefined,
      headers: [
        {
          key: "createdAt",
          label: this.$t("form.notification_date.label"),
          parser: (v: string) => this.$dayjs(v).format("DD/MM/YYYY HH:mm"),
        },
        {
          key: "incidentNumber",
          label: this.$t("form.case_number.label"),
          parser: (v: any) => this.$convertString(v),
        },
        {
          key: "incidentCategory",
          label: this.$t("form.story_type.label"),
          parser: (v: any) => {
            const masterDataStore = useMasterDataStore();
            return this.$convertString(masterDataStore.getIncidentCategoryByCodeAndLocale(v, this.$enums.ENUM_FIELD_LOCALE.TH));
          },
        },
        {
          key: "status",
          label: this.$t("form.status.label"),
          parser: (v: any) => this.$convertString(v),
        },
        { key: "action", label: "" },
      ],
    };
  },
  computed: {
    assigneeFormComponents() {
      return {
        form: {
          input: {},
          select: {
            assigneeID: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isError: (this.incidentAssigneeFormStore?.error?.assigneeID || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: true,
                textError: this.$t(this.incidentAssigneeFormStore.isRequired.assigneeID.placeholder),
                title: this.$t(this.incidentAssigneeFormStore.isRequired.assigneeID.label),
                placeholder: this.$t(this.incidentAssigneeFormStore.isRequired.assigneeID.placeholder),
                value: this.incidentAssigneeFormStore?.result?.assigneeID,
                items: this.incidentAssigneeFormStore.results,
                name: this.incidentAssigneeFormStore.isRequired.assigneeID.name,
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.incidentAssigneeFormStore.result.assigneeID = value;
                if ((value as string) === this.currentAssigneeID && this.currentStatus) {
                  this.incidentAssigneeFormStore.result.status = this.currentStatus;
                }
              },
            },
          },
          textarea: {
            detail: {
              component: FormTextareaDefaultComponent,
              props: {
                classTagName: "w-full",
                isSpecial: false,
                isVisible: true,
                isError: (this.incidentAssigneeFormStore.error[this.incidentAssigneeFormStore.isRequired.detail.name] || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.incidentAssigneeFormStore.isRequired.detail.is,
                textError: this.$t(this.incidentAssigneeFormStore.isRequired.detail.placeholder),
                title: this.$t(this.incidentAssigneeFormStore.isRequired.detail.label),
                name: this.incidentAssigneeFormStore.isRequired.detail.name,
                placeholder: this.$t(this.incidentAssigneeFormStore.isRequired.detail.placeholder),
                value: this.incidentAssigneeFormStore?.result?.detail,
                functionProp: () => {},
              } as typeof FormTextareaDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.incidentAssigneeFormStore.result.detail = value;
              },
            },
          },
          button: {
            btnReport: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: this.$inArray(this.incidentFormStore?.result?.status, this.isDisableReportStatus()),
                isIcon: true,
                componentIcon: this.$outlineIcons.ClipboardDocumentListIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: !this.$inArray(this.incidentFormStore?.result?.status, this.isDisableReportStatus()) || this.loading(),
                title: this.$t("btn.btn_view_report"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: () => {
                this.$router.push({ name: "IncidentReportView", params: { id: this.$route.params.id } });
              },
            },
            btnEdit: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: !this.incidentAssigneeFormStore.isEditAssign && this.assignable(),
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                title: this.$t("btn.btn_edit"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
              click: () => {
                this.incidentAssigneeFormStore.isEditAssign = !this.incidentAssigneeFormStore.isEditAssign;
              },
            },
            btnAssignee: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                title: this.$t("btn.btn_assign"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                const validate = await this.incidentAssigneeFormStore.onSubmit(e);
                if (validate) {
                  this.incidentAssigneeFormStore.result.validate = true;
                  await this.handleAssignSubmit();
                } else {
                  this.incidentAssigneeFormStore.result.validate = false;
                }
              },
            },
          },
          popup: {
            assigneeSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.incidentAssigneeFormStore.isProgressSuccess,
                textTitle: this.$t(this.showTitleTextCloseWithStatus()),
                textDetail: this.$t(this.showDetailTextCloseWithStatus()),
                hasCancel: false,
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: async () => {
                  this.incidentAssigneeFormStore.isProgressSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            assigneeCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.incidentAssigneeFormStore.isProgressSuccess,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.recipient_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: async () => {
                  this.incidentAssigneeFormStore.isProgressSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
            assignResponsible: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.incidentAssigneeFormStore.isResponsibleSuccess,
                textTitle: this.$t("popup.success.title"),
                textDetail: this.$t("popup.success.detail"),
                hasCancel: false,
                functionProp: () => {
                  this.setting();
                  this.incidentAssigneeFormStore.isResponsibleSuccess = false;
                },
                functionCancelProp: async () => {
                  this.incidentAssigneeFormStore.isResponsibleSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            }
          },
        },
      };
    },
    searchFormComponents() {
      return {
        form: {
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.searchStore.error.keyword || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.searchStore.isRequired.keyword.is,
                textError: this.$t(this.searchStore.isRequired.keyword.placeholder),
                title: this.$t(this.searchStore.isRequired.keyword.label),
                placeholder: this.$t(this.searchStore.isRequired.keyword.placeholder),
                value: this.searchStore.result.keyword,
                name: this.searchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.searchStore.result.keyword = value;
              },
            },
          },
          button: {
            btnSearch: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_search"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
          },
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
        },
      };
    },
    defaultFormComponents() {
      return {
        form: {
          button: {
            btnManage: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.PencilSquareIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: Boolean(this.$inArray(this.incidentFormStore?.result?.status, this.isDisableManageStatus()) || this.loading()),
                title: this.$t("btn.btn_manage"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnReport: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.DocumentMagnifyingGlassIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: Boolean(!this.incidentFormStore.result?.progress?.length || this.loading()),
                title: this.$t("btn.btn_manage_history"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
            },
            btnChat: {
              component: ButtonStyle2Component,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.ChatBubbleLeftEllipsisIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: this.loading(),
                title: this.$t("btn.btn_chat"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonStyle2Component.props,
            },
            btnClose: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                componentIcon: this.$outlineIcons.DocumentCheckIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block",
                isLoading: this.loading(),
                isDisable: this.loading() || !this.isBtnClosePermission(),
                title: this.$t("btn.btn_receieve_report"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async () => {
                await this.incidentAssigneeFormStore
                  .updateProgress(String(this.$route.params.id), {
                    status: this.$enums.ENUM_INCIDENT_STATUS.CLOSED,
                  })
                  .then(() => {
                    this.incidentFormStore.isSuccess = true;
                    this.incidentAssigneeFormStore.isProgressSuccess = true;
                    this.incidentFormStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
                    this.incidentAssigneeFormStore.actionStatus = this.$enums.ENUM_MUTATION_STATUS.SUCCESS;
                    this.incidentAssigneeFormStore.action = this.$enums.ENUM_MUTATION.CLOSE;
                    this.incidentFormStore.isButtonState = this.$enums.ENUM_BUTTON_STATE.REPORTED;
                  });
              },
            },
          },
          popup: {
            defaultCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.searchStore.isOpenDefault,
                textTitle: this.$t("popup.caution.default_title"),
                textDetail: this.$t("popup.caution.default_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.searchStore.isOpenDefault = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
        },
      };
    },
  },
  methods: {
    showTitleTextCloseWithStatus() {
      switch (this.incidentAssigneeFormStore.action) {
        case this.$enums.ENUM_MUTATION.CLOSE:
          return "popup.success.closed_title";
        case this.$enums.ENUM_MUTATION.RECEIVE:
          return "popup.success.edit_receive_title";
        default:
          return "popup.success.title";
      }
    },
    showDetailTextCloseWithStatus() {
      switch (this.incidentAssigneeFormStore.action) {
        case this.$enums.ENUM_MUTATION.CLOSE:
          return "popup.success.closed_detail";
        case this.$enums.ENUM_MUTATION.RECEIVE:
          return "popup.success.edit_receive_detail";
        default:
          return "popup.success.detail";
      }
    },
    showTextWithStatus() {
      switch (this.incidentFormStore.result.status) {
        case this.$enums.ENUM_INCIDENT_STATUS.REJECTED:
          return "popup.textarea.reject_title";
        case this.$enums.ENUM_INCIDENT_STATUS.CLOSED:
          return "popup.textarea.close_title";
        case this.$enums.ENUM_INCIDENT_STATUS.WAITING:
        case this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW:
          return "popup.textarea.assignee_title";
        default:
          return "popup.textarea.process_title";
      }
    },
    isDisableReportStatus() {
      return [this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW, this.$enums.ENUM_INCIDENT_STATUS.FORWARDED, this.$enums.ENUM_INCIDENT_STATUS.REPORTED, this.$enums.ENUM_INCIDENT_STATUS.CLOSED];
    },
    isDisableManageStatus() {
      return [this.$enums.ENUM_INCIDENT_STATUS.FORWARDED, this.$enums.ENUM_INCIDENT_STATUS.CLOSED, this.$enums.ENUM_INCIDENT_STATUS.REPORTED, this.$enums.ENUM_INCIDENT_STATUS.REJECTED];
    },
    assignableStatus() {
      return [this.$enums.ENUM_INCIDENT_STATUS.WAITING, this.$enums.ENUM_INCIDENT_STATUS.IN_PROGRESS, this.$enums.ENUM_INCIDENT_STATUS.COMPLETED, this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW];
    },
    assignable() {
      if (![this.$enums.ENUM_INCIDENT_STATUS.WAITING, this.$enums.ENUM_INCIDENT_STATUS.IN_PROGRESS, this.$enums.ENUM_INCIDENT_STATUS.COMPLETED, this.$enums.ENUM_INCIDENT_STATUS.IN_REVIEW].includes(this.incidentFormStore?.result?.status as ENUM_INCIDENT_STATUS)) {
        return false;
      }

      if (!PERMISSION_INCIDENT_REASSIGN.includes(this.authStore.displayRole as ENUM_FIELD_ROLE)) {
        return false;
      }

      if (this.authStore.displayRole === (ENUM_FIELD_ROLE.DUTY_OFFICER as string) && !this.authStore.user?.responsibleAreas?.find((r) => r.code === this.incidentFormStore?.result?.responsibleAreaCode)) {
        return false;
      }

      return true;
    },
    isPermissionEditAssigneeWithStationStatus() {
      return PERMISSION_INCIDENT_REASSIGN;
    },
    isBtnClosePermission() {
      return this.incidentFormStore?.result?.status !== "CLOSED" && this.$inArray(this.authStore.displayRole, PERMISSION_INCIDENT_CLOSE);
    },
    isBtnReportPermission() {
      return this.$inArray(this.authStore.displayRole, [...PERMISSION_INCIDENT_IN_REVIEW, ...PERMISSION_INCIDENT_CLOSE, ...PERMISSION_INCIDENT_CLOSED]);
    },
    async initializeMap() {
      this.incidentFormStore.isMapGenerate = true;
      const google = await this.$asyncGoogleMapsLoader();
      const { Map } = (await google.maps.importLibrary("maps")) as any;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as any;

      var thailandCoordinates = { lat: 13.736717, lng: 100.523186 };

      const map = new Map(document.getElementById("map") as HTMLElement, {
        center: this.$route.params.id
          ? {
              lat: Number(this.incidentFormStore.result.information.latitude),
              lng: Number(this.incidentFormStore.result.information.longitude),
            }
          : thailandCoordinates,
        zoom: 14,
        mapId: "4504f8b37365c3d0",
        mapTypeId: "roadmap",
      });

      const draggableMarker = new AdvancedMarkerElement({
        map,
        position: this.$route.params.id
          ? {
              lat: Number(this.incidentFormStore.result.information.latitude),
              lng: Number(this.incidentFormStore.result.information.longitude),
            }
          : thailandCoordinates,
        gmpDraggable: true,
        title: "This marker is draggable.",
      });
      draggableMarker.addListener("dragend", async (e: any) => {
        const position = draggableMarker.position as any;
        this.incidentFormStore.result.information.latitude = position.lat;
        this.incidentFormStore.result.information.longitude = position.lng;

        const newPosition = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        } as any;

        const geocoder = new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: newPosition },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("information.location") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.incidentFormStore.result.information.location = result.results[0].formatted_address;
      });
      if (!this.$route.params.id && navigator.geolocation) {
        const position = await Geolocation.getCurrentPosition();
        var selfCoordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.incidentFormStore.result.information.latitude = position.coords.latitude;
        this.incidentFormStore.result.information.longitude = position.coords.longitude;

        map.setCenter(selfCoordinates);
        draggableMarker.position = selfCoordinates;

        const geocoder = new google.maps.Geocoder();
        const result = await geocoder.geocode(
          { location: selfCoordinates },
          function (results: any, status: any) {
            if (status === "OK") {
              if (results[0]) {
                const address = results[0].formatted_address;

                (document.getElementById("information.location") as HTMLInputElement).value = address;
              }
            } else {
              console.error("Geocoder failed due to: " + status);
            }
          }.bind(this)
        );
        this.incidentFormStore.result.information.location = result.results[0].formatted_address;
      }

      const input = document.getElementById("information.location") as HTMLInputElement;
      const searchBox = new google.maps.places.SearchBox(input);
      map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds() as any);
      });

      let markers: any[] = [];
      searchBox.addListener("places_changed", () => {
        if (!this.incidentFormStore.isMap) {
          const places = searchBox.getPlaces();

          if (places.length == 0) return;
          markers.forEach((marker) => {
            marker.setMap(null);
          });
          markers = [];
          const bounds = new google.maps.LatLngBounds();

          places.forEach((place: any) => {
            if (!place.geometry || !place.geometry.location) return;

            draggableMarker.position = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };

            this.incidentFormStore.result.information.latitude = place.geometry.location.lat();
            this.incidentFormStore.result.information.longitude = place.geometry.location.lng();

            this.incidentFormStore.result.information.location = `${place.name}, ${place.formatted_address}`;

            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        }
      });
    },
    async handleAssignSubmit() {
      if (this.$route.params.id) {
        this.incidentAssigneeFormStore.action = this.$enums.ENUM_MUTATION.RECEIVE;
        this.incidentAssigneeFormStore.sendResult = deleteKeysFromObject(this.incidentAssigneeFormStore.sendResult, ["validate", "routeName"]);
        if (this.incidentFormStore.result.status === ENUM_INCIDENT_STATUS.IN_REVIEW) {
          this.incidentAssigneeFormStore.sendResult.status = ENUM_INCIDENT_STATUS.COMPLETED;
        } else {
          this.incidentAssigneeFormStore.sendResult.status = this.incidentFormStore.result.status;
        }

        await this.incidentAssigneeFormStore.updateProgress(String(this.$route.params.id), this.incidentAssigneeFormStore.sendResult);

        await this.fetch();
      }
    },
    color() {
      return `text-[${this.$getColorStatus(new Date(this.incidentFormStore.metadata.createdAt), new Date(this.incidentFormStore.metadata.eta), new Date(this.incidentFormStore.metadata.arrived), "TH").color}]`;
    },
    loading() {
      return this.incidentFormStore.loading || this.searchStore.loading || this.incidentAssigneeFormStore.loading;
    },
    async fetch() {
      if (this.$route.params.id) {
        await Promise.all([
          (async () => {
            this.incidentFormStore.result = await this.incidentFormStore.fetchIncident.Get(this.$route.params.id as string);

            // Get Image
            if (this?.incidentFormStore?.result?.images?.length) {
              this.incidentFormStore.result.images = await Promise.all(await this.incidentFormStore.fetchFile.Get(this?.incidentFormStore?.result?.images));
            }

            // Get Profile Image
            if (this?.incidentFormStore?.result?.tourist?.image) {
              this.incidentFormStore.result.tourist.image = await Promise.all(await this.incidentFormStore.fetchFile.Get([this?.incidentFormStore?.result?.tourist?.image]));
            }

            // Latitude & Longitude Not Found
            if (!this.incidentFormStore.result.information.latitude) {
              this.incidentFormStore.result.information.latitude = 13.736717;
              this.incidentFormStore.result.information.longitude = 100.523186;
            }

            // Responsible Area Code
            if (this.incidentFormStore.result.responsibleAreaCode) {
              this.masterDataStore.station = await this.masterDataStore.getStation(this.incidentFormStore.result.responsibleAreaCode);
            }

            //  Check Process
            this.incidentAssigneeFormStore.isShowTime = this.$inArray(this.incidentFormStore?.result?.status, [this.$enums.ENUM_INCIDENT_STATUS.WAITING]);
            this.incidentAssigneeFormStore.metadata = this.$extractMetadata(this.incidentFormStore?.result?.progress || []);
            this.incidentAssigneeFormStore.isETA = Boolean(this.incidentAssigneeFormStore?.metadata?.eta);
            this.incidentAssigneeFormStore.isArrive = Boolean(this.incidentAssigneeFormStore?.metadata?.arrived);
            this.incidentAssigneeFormStore.isRequired.metadata.is = this.incidentAssigneeFormStore.isShowTime;

            //  Check Process
            this.incidentAssigneeFormStore.metadata = this.$extractMetadata(this.incidentFormStore?.result?.progress || []);
            if (this.incidentAssigneeFormStore.metadata) {
              this.incidentAssigneeFormStore.calculate = await this.$calculateTimeColor(new Date().toISOString(), this.incidentAssigneeFormStore?.metadata?.arrived, this.incidentAssigneeFormStore?.metadata?.createdAt, this.$calculateMinutesDifference(this.incidentAssigneeFormStore?.metadata?.createdAt, this.incidentAssigneeFormStore?.metadata?.eta));
            }

            this.currentAssigneeID = (this.incidentFormStore.result.assigneeID || "") as string;
            this.currentStatus = this.incidentFormStore.result.status;

            this.$configLightbox();
          })(),
          (async () => {
            this.incidentAssigneeFormStore.results = this.$transformUserData(
              await this.incidentAssigneeFormStore.fetchUser.List({
                isAgent: true,
                size: 9999,
              })
            );
          })(),
        ]);

        await this.searchStore.onUpdateCurrentPage(Number(this.$route.query.page) || 1, {
          ignore: String(this.$route.params.id),
          touristID: this.incidentFormStore.result.touristID,
          guestID: this.incidentFormStore.result.guestID,
        });
      }

      this.incidentFormStore.loading = false;
    },
    async setMasterData() {
      await Promise.all([this.masterDataStore.listOrganizations(), this.masterDataStore.listStations(0, 0)]);
    },
    async reset() {
      await this.incidentFormStore.$reset();
      await this.searchStore.$reset();
      await this.incidentAssigneeFormStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();
      await this.setMasterData();
      this.incidentAssigneeFormStore.isRequired.assigneeID.is = false;
      this.incidentAssigneeFormStore.result.routeName = "IncidentNoticeView";

      this.$configLightbox();
    },
  },
  async mounted() {
    await this.setting();
    this.intervalId = setInterval(async () => {
      const now = new Date();
      this.incidentAssigneeFormStore.calculate = await this.$calculateTimeColor(now.toISOString(), this.incidentAssigneeFormStore?.metadata?.arrived, this.incidentAssigneeFormStore?.metadata?.createdAt, this.$calculateMinutesDifference(this.incidentAssigneeFormStore?.metadata?.createdAt, this.incidentAssigneeFormStore?.metadata?.eta));
      this.timeLeft = this.$calculateTimeDifference(now, new Date(this.incidentAssigneeFormStore?.metadata?.eta), this.masterDataStore.lang());
      if (now >= new Date(this.incidentAssigneeFormStore?.metadata?.eta)) {
        clearInterval(this.intervalId);
        this.timeLeft = "None";
      }
    }, 1000);
  },
  async unmounted() {
    this.masterDataStore.station = "";
    await this.reset();
  },
  created() {
    watch([() => this.$route.query.page, () => this.$route.params.id], async () => {
      await this.fetch();
    });
  },
  setup() {
    const masterDataStore = useMasterDataStore();
    const incidentFormStore = useIncidentFormStore();
    const componentIncidentFormStore = useComponentIncidentFormStore();
    const incidentAssigneeFormStore = useIncidentAssigneeFormStore();
    const searchStore = useIncidentShareSearchStore();
    const authStore = useAuthStore();

    const addColor = computed(() => {
      switch (incidentAssigneeFormStore?.calculate?.now?.colorSegment) {
        case "Green":
          return "text-green-600";
        case "Yellow":
          return "text-yellow-300";
        case "Red":
          return "text-red-600";
        default:
          return "text-gray-600";
      }
    });

    const addArriveColor = computed(() => {
      switch (incidentAssigneeFormStore?.calculate?.arrived?.colorSegment) {
        case "Green":
          return "text-green-600";
        case "Yellow":
          return "text-yellow-300";
        case "Red":
          return "text-red-600";
        default:
          return "text-gray-600";
      }
    });
    return {
      masterDataStore,
      incidentFormStore,
      componentIncidentFormStore,
      searchStore,
      incidentAssigneeFormStore,
      authStore,
      addColor,
      addArriveColor,
      ENUM_FIELD_ROLE,
    };
  },
});
</script>

<template>
  <div class="grid grid-cols-12 lg:gap-2">
    <div class="col-start-1 col-end-13 lg:col-start-1 lg:col-end-9">
      <div class="ttp-layout">
        <div class="ttp-page-header-1">
          <div class="grid grid-cols-12 gap-2 w-full justify-center items-center">
            <div class="col-span-1 md:col-span-1">
              <component :is="$outlineIcons.DocumentTextIcon" class="flex-none inline-block ttp-icon-inside-box-02 text-gray-600" />
            </div>
            <div class="col-span-11 lg:col-span-6 flex flex-col w-full">
              <div>
                <div class="text-gray-600 ttp-text-base font-semibold leading-tight mb-1 text-left">
                  <span class="font-medium"><component :is="$solidIcons.IdentificationIcon" class="flex-none inline-block ttp-icon-inside-box-03" /> {{ $t("page.IncidentManageView.document_number") }}</span>
                  <span class="ml-1"> {{ $convertString(incidentFormStore?.result?.incidentNumber) }}</span>
                </div>
              </div>
              <div class="flex justify-start items-start md:flex-row gap-2 w-full text-gray-600 ttp-text-xs text-left">
                <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03" /><span class="font-medium">{{ $t("page.IncidentManageView.receive_date") }}</span>
                <div class="leading-0 flex justify-center items-center">
                  <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(incidentFormStore?.result?.createdAt).format("DD/MM/YYYY HH:mm") }}</span>
                </div>
              </div>
              <div class="flex justify-start items-start md:flex-row gap-2 w-full text-gray-600 ttp-text-xs text-left">
                <component :is="$solidIcons.ClockIcon" class="flex-none inline-block ttp-icon-inside-box-03" /><span class="font-medium">{{ $t("form.updated_date.label") }}</span>
                <div class="leading-0 flex justify-center items-center">
                  <span class="ttp-text-xs font-medium ml-1">{{ $dayjs(incidentFormStore?.result?.updatedAt).format("DD/MM/YYYY HH:mm") }}</span>
                </div>
              </div>
            </div>

            <div class="col-span-12 lg:col-span-5 flex w-full">
              <div class="grid grid-flow-row-dense grid-cols-1 grid-rows-4 lg:grid-cols-2 lg:grid-rows-2 gap-2 w-full">
                <router-link
                  :to="{
                    name: 'IncidentManageView',
                    params: { id: incidentFormStore.result?.id },
                  }"
                >
                  <component :classTagName="'w-full h-12'" :is="defaultFormComponents.form.button.btnManage.component" v-bind="defaultFormComponents.form.button.btnManage.props" />
                </router-link>
                <component :classTagName="'h-12'" v-bind="defaultFormComponents.form.button.btnClose.props" :is="defaultFormComponents.form.button.btnClose.component" @click="defaultFormComponents.form.button.btnClose.click" />
                <component :classTagName="'h-12'" :is="defaultFormComponents.form.button.btnReport.component" v-bind="defaultFormComponents.form.button.btnReport.props" @click="incidentFormStore.openHistoryModal" />
                <component
                  :classTagName="'h-12'"
                  :is="defaultFormComponents.form.button.btnChat.component"
                  v-bind="defaultFormComponents.form.button.btnChat.props"
                  @click="
                    () => {
                      $router.push({
                        name: 'ChatManageView',
                        params: { id: $route.params.id },
                      });
                    }
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ttp-layout">
        <div class="p-4">
          <div class="flex w-full">
            <div class="flex gap-2.5">
              <div class="swipe-photo inline-flex ttp-icon-box-04 items-center justify-center rounded-lg bg-gray-100 text-gray-300 overflow-hidden">
                <component v-if="!incidentFormStore?.result?.tourist?.image" :is="$solidIcons.UserIcon" class="inline-block ttp-icon-inside-box-01" />
                <a v-else :href="incidentFormStore?.result?.tourist?.image[0]?.url" target="_blank"><img :src="incidentFormStore?.result?.tourist?.image[0]?.url" class="object-cover object-center ttp-icon-box-04" /></a>
              </div>
            </div>
            <div v-if="$inArray(incidentFormStore?.result?.informerType, [$enums.ENUM_INFORMER_TYPE.GUEST, $enums.ENUM_INFORMER_TYPE.TOURIST])" class="w-full px-4">
              <div class="flex flex-col xl:flex-row">
                <div class="flex w-full gap-2 mb-2">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.name.label") }} :</div>
                  <div class="text-gray-500 ttp-text-xs">
                    {{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.name) : $convertString(incidentFormStore?.result?.tourist?.name) }}
                  </div>
                </div>
                <div class="flex w-full gap-2 mb-2">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.nationality.label") }} :</div>
                  <div class="text-gray-500 ttp-text-xs">
                    {{ $convertString(masterDataStore.itemNationalities().find(({ value }: any) => value === (incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.nationality) : $convertString(incidentFormStore?.result?.tourist?.nationality)))?.label) }}
                  </div>
                </div>
              </div>
              <div class="flex flex-col xl:flex-row">
                <div class="flex w-full gap-2 mb-2">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.country.label") }} :</div>
                  <div class="text-gray-500 ttp-text-xs">
                    {{ $convertString(masterDataStore.itemCountries().find(({ value }: any) => value === (incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.country) : $convertString(incidentFormStore?.result?.tourist?.country)))?.label) }}
                  </div>
                </div>
              </div>
              <div class="flex flex-col xl:flex-row">
                <div class="flex w-full gap-2 mb-2">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.phone.label") }} :</div>
                  <div class="text-gray-500 ttp-text-xs">
                    {{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.contactNumber) : $convertString(incidentFormStore?.result?.tourist?.contactNumber) }}
                  </div>
                </div>
                <div class="flex w-full gap-2 mb-2">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.email.label") }} :</div>
                  <div class="text-gray-500 ttp-text-xs">
                    {{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.email) : $convertString(incidentFormStore?.result?.tourist?.email) }}
                  </div>
                </div>
              </div>
              <div class="flex flex-col xl:flex-row">
                <div class="flex w-full gap-2 mb-2">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.gender.label") }} :</div>
                  <div class="text-gray-500 ttp-text-xs">
                    {{ $convertString(incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? masterDataStore.getGenderName(incidentFormStore?.result?.guest?.gender as typeof $enums.ENUM_GENDER) : "") }}
                  </div>
                </div>
                <div class="flex w-full gap-2 mb-2">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.passport_number.label") }} :</div>
                  <div class="text-gray-500 ttp-text-xs">
                    {{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.identificationNumber) : $convertString(incidentFormStore?.result?.tourist?.identificationNumber) }}
                  </div>
                </div>
              </div>
              <div v-if="true" class="flex flex-col xl:flex-row">
                <div class="flex w-full gap-2 mb-2">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.notification_channel.label") }} :</div>
                  <div class="text-gray-500 ttp-text-xs">
                    {{ incidentFormStore?.result?.channel == "1155" ? $t("role.CENTRAL_OFFICER") : $convertString(incidentFormStore?.result?.channel) }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="w-full px-4">
              <div class="flex justify-center items-center h-full">
                <div class="flex justify-center items-center w-full gap-2 mt-2">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">
                    {{ $t("layout.anonymous") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ttp-layout">
        <div class="p-4">
          <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50 mb-4">
            {{ $t("page.IncidentManageView.title") }}
          </div>
          <template v-if="incidentFormStore?.result?.images?.length">
            <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50 mb-3">รูปภาพ</div>
            <div id="ttp-gallery" class="flex gap-2 mb-4">
              <div class="swipe-photo flex flex-col sm:flex-row gap-5 w-full">
                <div v-if="$isArrayOfStringsOrObjects(incidentFormStore?.result?.images)" class="flex flex-col justify-center items-center w-full">
                  <div><LoadingFormComponent /></div>
                  <div class="ttp-text-xs">{{ $t("layout.loading") }}</div>
                </div>
                <a v-else-if="incidentFormStore?.result?.images?.length" v-for="(_item, index) in incidentFormStore?.result?.images" :key="index" :href="_item?.url ? _item?.url : '#'" target="_blank" class="w-full"><img class="object-contain object-center rounded-lg aspect-[16/9] bg-white" :src="_item?.url" alt="" /></a>
                <div v-else>-</div>
              </div>
            </div>
          </template>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.notificationChannel.label") }} : </span><span class="text-gray-500 ttp-text-xs">{{ incidentFormStore?.result?.channel == "1155" ? $t("role.CENTRAL_OFFICER") : $replaceAll($convertString(incidentFormStore?.result?.channel), "_", " ") }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.touristDetail.label") }} : </span><span class="text-gray-500 ttp-text-xs">{{ $convertString(incidentFormStore?.result?.information?.detail) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.officerDetail.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString($findStatusDetail(incidentFormStore?.result?.progress || [], $enums.ENUM_INCIDENT_STATUS.WAITING)) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.no.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(incidentFormStore?.result?.information?.incidentCode) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.story_type.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(masterDataStore.getIncidentCategoryByCodeAndLocale(incidentFormStore?.result?.information?.categoryCode, $enums.ENUM_FIELD_LOCALE.TH)) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.subject.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(masterDataStore.getIncidentTopicByCodeAndLocale(incidentFormStore?.result?.information?.topicCode, $enums.ENUM_FIELD_LOCALE.TH)) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.important.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(masterDataStore.getInformationPriorityName(incidentFormStore?.result?.information?.priority)) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.province_area.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(incidentFormStore?.result?.information?.province?.name?.TH) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.district.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs">{{ $convertString(incidentFormStore?.result?.information?.district?.name?.TH) }}</span>
          </div>
          <div class="w-full mb-2">
            <span class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.location_detail.label") }} : </span>
            <span class="text-gray-500 ttp-text-xs"> {{ $convertString(incidentFormStore?.result?.information?.location) }}</span>
          </div>
          <div class="flex w-full">
            <div class="mt-2 w-full">
              <div class="flex ttp-text-xs font-medium text-gray-900 dark:text-gray-50 leading-tight">
                <button
                  type="button"
                  @click="
                    () => {
                      incidentFormStore.isMap = !incidentFormStore.isMap;
                      if (incidentFormStore.isMap && !incidentFormStore.isMapGenerate) {
                        initializeMap();
                      }
                    }
                  "
                  class="flex w-full items-center justify-center"
                >
                  <div class="flex w-full ttp-text-xs gap-2">{{ $t("form.pin_location.label") }} <component :is="incidentFormStore.isMap ? $solidIcons.ChevronDownIcon : $solidIcons.ChevronUpIcon" class="inline-block ttp-icon-inside-box-03 opacity-40" /></div>
                </button>
                <!-- Google Map Link -->
                <div v-if="!$inArray(incidentFormStore?.result?.information?.latitude, [13.736717, null])" class="w-full text-right ttp-text-xs">
                  <a :href="`https://maps.google.com/?q=${incidentFormStore?.result?.information?.latitude},${incidentFormStore?.result?.information?.longitude}`" target="_blank"
                    ><div class="inline-flex rounded bg-blue-200 px-2 py-1 ttp-text-xs font-semibold leading-4 text-blue-700">{{ $t("btn.btn_google_map") }}</div></a
                  >
                </div>
              </div>
              <div :class="[{ 'flex w-full h-60 mt-2 relative bg-white border border-gray-200': true, hidden: !incidentFormStore.isMap }]">
                <div class="flex w-full h-full">
                  <div v-if="incidentFormStore.isMap" :class="{ 'absolute flex w-full h-full z-10 items-center justify-center': true, 'bg-black opacity-80': $route.params.id && incidentFormStore.result.information.latitude == 13.736717 }">
                    <div v-if="$route.params.id && incidentFormStore.result.information.latitude == 13.736717" class="text-white">ไม่พบพิกัดสถานที่</div>
                  </div>
                  <div id="map"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ttp-layout" v-if="incidentFormStore?.result?.informerType !== $enums.ENUM_INFORMER_TYPE.ANONYMOUS">
        <div class="p-4">
          <div class="flex ttp-between">
            <div class="text-gray-600 ttp-text-base font-semibold py-4">
              {{ $t("page.TouristManageView.record_title") }}
            </div>
            <form @submit="searchStore.onSubmit" class="flex gap-2">
              <component :classTagName="'w-full text-left'" :is="searchFormComponents.form.input.keyword.component" v-bind="searchFormComponents.form.input.keyword.props" @updateValue="searchFormComponents.form.input.keyword.onUpdateValue" />
              <component :is="searchFormComponents.form.button.btnSearch.component" v-bind="searchFormComponents.form.button.btnSearch.props" />
            </form>
          </div>
        </div>
        <div>
          <TableComponent :headers="headers" :content="searchStore.tableResults?.items" :loading="loading()" counter :counter-label="$t('form.number.label')">
            <template v-slot:body-status="props">
              <component :is="searchFormComponents.form.badge.status.component" v-bind="searchFormComponents.form.badge.status.props" :title="$formattedStatus($classStatus(props?.item?.status))" :classEnumName="$classStatus(props?.item?.status)" />
            </template>
            <template v-slot:body-action="props">
              <Menu as="div" class="inline-block relative">
                <MenuButton class="p-2">
                  <component :is="$outlineIcons.EllipsisVerticalIcon" class="inline-block ttp-icon-inside-box-03" />
                </MenuButton>
                <MenuItems class="absolute right-1 origin-top-right z-10 mt-2 w-4/4 shadow-md rounded-lg dark:shadow-gray-900 focus:outline-none">
                  <div class="bg-white ring-1 ring-black ring-opacity-5 rounded-lg divide-y divide-gray-100 dark:bg-gray-800 dark:divide-gray-700 dark:ring-gray-700">
                    <div v-if="!$inArray(props?.item?.status, [$enums.ENUM_INCIDENT_STATUS.CLOSED, $enums.ENUM_INCIDENT_STATUS.REPORTED, $enums.ENUM_INCIDENT_STATUS.REJECTED])" class="space-y-1">
                      <MenuItem v-slot="{ active }">
                        <router-link
                          :to="{
                            name: 'IncidentNoticeView',
                            params: { id: props?.item?.id },
                          }"
                          class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                          :class="{
                            'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                            'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                          }"
                        >
                          <component :is="$outlineIcons.EyeIcon" class="flex-none inline-block ttp-icon-inside-box-03 opacity-100 group-hover:opacity-100" />
                          <span class="grow text-gray-500 ttp-text-xs font-normal">{{ $t("btn.btn_view_notification") }}</span>
                        </router-link>
                      </MenuItem>
                    </div>
                    <div class="space-y-1">
                      <MenuItem v-slot="{ active }">
                        <router-link
                          :to="{
                            name: 'ChatManageView',
                            params: { id: props?.item?.id },
                          }"
                          class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                          :class="{
                            'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                            'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                          }"
                        >
                          <component :is="$outlineIcons.ChatBubbleLeftEllipsisIcon" class="flex-none inline-block ttp-icon-inside-box-03 opacity-100 group-hover:opacity-100" />
                          <span class="grow text-gray-500 ttp-text-xs font-normal">{{ $t("btn.btn_chat") }}</span>
                        </router-link>
                      </MenuItem>
                    </div>
                    <div class="space-y-1">
                      <MenuItem v-slot="{ active }">
                        <router-link
                          v-if="!$inArray(incidentFormStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.IN_REVIEW, $enums.ENUM_INCIDENT_STATUS.REPORTED, $enums.ENUM_INCIDENT_STATUS.CLOSED])"
                          :to="{
                            name: 'IncidentReportView',
                            params: { id: props?.item?.id },
                          }"
                          class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                          :class="{
                            'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                            'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                          }"
                        >
                          <component :is="$outlineIcons.ClipboardDocumentListIcon" class="flex-none inline-block ttp-icon-inside-box-03 opacity-100 group-hover:opacity-100" />
                          <span class="grow text-gray-500 ttp-text-xs font-normal">{{ $t("btn.btn_view_report") }}</span>
                        </router-link>
                        <a
                          v-else
                          @click="
                            () => {
                              searchStore.isOpenDefault = !searchStore.isOpenDefault;
                            }
                          "
                          href="#"
                          class="group ttp-text-xs font-medium flex items-center justify-between space-x-2 px-2.5 py-2 rounded-lg border border-transparent"
                          :class="{
                            'text-teal-800 bg-teal-50 dark:text-white dark:bg-gray-700/75 dark:border-transparent': active,
                            'text-gray-700 hover:text-teal-800 hover:bg-teal-50 active:border-teal-100 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700/75 dark:active:border-gray-600': !active,
                          }"
                        >
                          <component :is="$outlineIcons.ClipboardDocumentListIcon" class="flex-none inline-block ttp-icon-inside-box-03 opacity-100 group-hover:opacity-100" />
                          <span class="grow text-gray-500 ttp-text-xs font-normal">{{ $t("btn.btn_view_report") }}</span>
                        </a>
                      </MenuItem>
                    </div>
                  </div>
                </MenuItems>
              </Menu>
            </template>
          </TableComponent>
          <PaginationComponent
            :currentPage="Number($route.query.page) || 1"
            :totalPage="searchStore.totalPage"
            :gotoPage="searchStore.gotoPage"
            :nextPage="searchStore.nextPage"
            :prevPage="searchStore.prevPage"
            :onUpdateCurrentPage="() => {}"
            :totalItem="searchStore.total"
            unitItem="Incidents"
          />
        </div>
      </div>
    </div>
    <div class="col-start-1 col-end-13 lg:col-start-9 lg:col-end-13">
      <!-- Responsible -->
      <Responsible :id="incidentFormStore?.result?.id || ''" :responsible="incidentFormStore?.result?.responsible" :editable="authStore?.user?.role === ENUM_FIELD_ROLE.SUPERVISOR" />

      <!-- Assignee -->
      <div class="ttp-layout">
        <div class="flex flex-col gap-2.5 p-4">
          <div class="justify-center items-start gap-2.5 inline-flex">
            <div class="grow shrink basis-0 justify-start items-center gap-2.5 flex">
              <div class="justify-start items-center gap-2.5 flex">
                <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.status.label") }} :</div>
              </div>
              <div class="text-gray-500 ttp-text-xs">
                <component :is="defaultFormComponents.form.badge.status.component" v-bind="defaultFormComponents.form.badge.status.props" :title="$formattedStatus($classStatus(incidentFormStore?.result?.status))" :classEnumName="$classStatus(incidentFormStore?.result?.status)" />
              </div>
            </div>
          </div>
          <div v-if="incidentFormStore?.result?.progress?.length" class="justify-center items-start gap-2.5 flex flex-col">
            <div class="grow shrink basis-0 justify-start items-center gap-2.5 flex">
              <div class="justify-start items-center gap-2.5 flex">
                <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50 w-20">{{ $t("form.responsible_person.label") }} :</div>
              </div>
              <div class="text-gray-500 ttp-text-xs">
                {{ $convertString(incidentFormStore?.result?.assignee?.content[0].shortName) }}
              </div>
            </div>
            <div class="justify-center items-start gap-2.5 inline-flex">
              <div class="grow shrink basis-0 justify-start items-center gap-2.5 flex">
                <div class="justify-start items-center gap-2.5 flex">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.division.label") }} :</div>
                </div>
                <div class="text-gray-500 ttp-text-xs">{{ $convertString(masterDataStore.getOrganization(incidentFormStore?.result?.assignee?.organizationCode, "shortname")) }}</div>
              </div>
            </div>
            <div class="justify-center items-start gap-2.5 inline-flex">
              <div class="grow shrink basis-0 justify-start items-center gap-2.5 flex">
                <div class="justify-start items-center gap-2.5 flex">
                  <div class="ttp-text-xs font-medium text-gray-900 dark:text-gray-50">{{ $t("form.responsibleArea.label") }} :</div>
                </div>
                <div class="text-gray-500 ttp-text-xs">{{ $convertString(masterDataStore?.station?.shortName) }}</div>
              </div>
            </div>
            <div v-if="isBtnReportPermission() && $inArray(incidentFormStore?.result?.status, [$enums.ENUM_INCIDENT_STATUS.IN_REVIEW, $enums.ENUM_INCIDENT_STATUS.REPORTED, $enums.ENUM_INCIDENT_STATUS.CLOSED])" class="flex w-full">
              <component :classTagName="'w-full h-12 col-end-0 col-span-3 md:col-end-7 md:col-span-2 lg:col-end-7 lg:col-span-1'" :is="assigneeFormComponents.form.button.btnReport.component" v-bind="assigneeFormComponents.form.button.btnReport.props" @click="assigneeFormComponents.form.button.btnReport.click" />
            </div>
            <div class="flex w-full">
              <component :classTagName="'w-full h-12 col-end-0 col-span-3 md:col-end-7 md:col-span-2 lg:col-end-7 lg:col-span-1'" :is="assigneeFormComponents.form.button.btnEdit.component" v-bind="assigneeFormComponents.form.button.btnEdit.props" @click="assigneeFormComponents.form.button.btnEdit.click" />
            </div>
            <div class="flex w-full">
              <component v-if="incidentAssigneeFormStore.isEditAssign" :classTagName="'w-full col-start-1 col-end-13 md:col-start-7 md:col-end-13'" :is="assigneeFormComponents.form.select.assigneeID.component" v-bind="assigneeFormComponents.form.select.assigneeID.props" @updateValue="assigneeFormComponents.form.select.assigneeID.onUpdateValue" />
            </div>
            <div class="flex w-full">
              <component v-if="incidentAssigneeFormStore.isEditAssign" :classTagName="'w-full h-12 col-end-0 col-span-3 md:col-end-7 md:col-span-2 lg:col-end-7 lg:col-span-1'" :is="assigneeFormComponents.form.button.btnAssignee.component" v-bind="assigneeFormComponents.form.button.btnAssignee.props" @click="assigneeFormComponents.form.button.btnAssignee.click" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TransitionRoot appear :show="incidentFormStore.isHistory" as="template">
    <Dialog as="div" @close="incidentFormStore.closeHistoryModal" class="relative z-9999" :show="incidentFormStore.isHistory">
      <div class="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-sm"></div>
      <div class="fixed inset-0 overflow-y-auto p-4 lg:p-8">
        <DialogPanel class="w-full max-w-xl mx-auto flex flex-col rounded-lg shadow-md bg-white overflow-hidden dark:text-gray-100 dark:bg-gray-800">
          <div class="relative p-4">
            <div class="flex">
              <div class="w-full text-gray-600 ttp-text-base font-medium leading-normal">
                <div class="inline-flex items-center justify-center px-3 py-2">{{ $t("page.IncidentNoticeView.history") }} ({{ $t("page.IncidentNoticeView.number_code") }} {{ $convertString(incidentFormStore?.result?.incidentNumber) }})</div>
              </div>
              <div class="text-right">
                <button @click="incidentFormStore.closeHistoryModal" type="button" class="inline-flex items-center justify-center space-x-2 rounded-lg border border-transparent px-3 py-2 ttp-text-xs font-semibold leading-5 text-gray-600 hover:text-gray-900 focus:ring focus:ring-gray-300 focus:ring-opacity-25 active:border-gray-200 active:shadow-none dark:border-transparent dark:text-gray-300 dark:hover:border-gray-600 dark:hover:text-gray-200 dark:focus:ring-gray-600 dark:focus:ring-opacity-40 dark:active:border-gray-700">
                  <component :is="$solidIcons.XMarkIcon" class="inline-block ttp-icon-inside-box-03" />
                </button>
              </div>
            </div>
            <div class="my-5">
              <div class="relative py-5 dark:text-gray-100">
                <div class="w-6 md:w-12 lg:ml-40 absolute top-0 left-0 bottom-0 flex flex-col justify-center" aria-hidden="true">
                  <div class="mx-auto w-1 h-2.5 grow-0 bg-gradient-to-b from-transparent to-teal-100 rounded-t dark:to-teal-900"></div>
                  <div class="mx-auto w-1 grow bg-ttp-new dark:bg-ttp-new"></div>
                  <div class="mx-auto w-1 h-2.5 grow-0 bg-gradient-to-t from-transparent to-teal-100 rounded-b dark:to-teal-900"></div>
                </div>
                <ul class="relative space-y-4 pl-10 md:pl-12 lg:ml-40">
                  <li v-for="(data, n) in incidentFormStore?.result?.progress" :key="n" class="relative">
                    <div class="w-14 md:w-12 absolute top-0 left-0 bottom-0 -translate-x-full flex justify-center mt-3">
                      <div v-if="n == 0" class="ttp-icon-inside-box-03 text-white bg-ttp-success rounded-full flex items-center justify-center dark:text-teal-900 dark:bg-teal-300 dark:ring-teal-900 dark:ring-offset-gray-900">
                        <component :is="$solidIcons.CheckIcon" class="inline-block h-4 w-4" />
                      </div>

                      <div v-else class="ttp-icon-inside-box-04 bg-ttp-new rounded-full dark:bg-teal-300 dark:ring-teal-900 dark:ring-offset-gray-900"></div>
                    </div>
                    <div class="bg-gray-100 hover:ring hover:ring-gray-100 hover:ring-offset-2 rounded-xl p-4 dark:bg-gray-800 dark:hover:ring-gray-700 dark:ring-offset-gray-900">
                      <div class="px-1 ttp-text-xs leading-[14px] mb-2">
                        <component :is="defaultFormComponents.form.badge.status.component" v-bind="defaultFormComponents.form.badge.status.props" :title="$formattedStatus($classStatus(data?.status))" :classEnumName="$classStatus(data?.status)" />
                      </div>
                      <div class="px-1 mt-2 ttp-text-xs leading-[14px]">
                        {{ masterDataStore.getStatusByCode($classStatus(data?.status), false) }}
                      </div>
                      <div class="px-1 mt-2 ttp-text-xs text-gray-500 leading-[14px]">
                        {{ $t("layout.result") }} :
                        {{ $convertString(data?.detail) }}
                      </div>
                      <div class="px-1 mt-2 ttp-text-xs text-gray-500 leading-[14px]">
                        {{ masterDataStore.getStatusDetailByCode($classStatus(data?.status)) }}
                        :
                        {{ $convertString(data?.createdBy?.content[0]?.shortName) }}
                      </div>
                    </div>
                    <div class="px-4 py-2 lg:w-40 lg:absolute lg:top-0 lg:left-0 lg:bottom-0 lg:-translate-x-full lg:flex lg:flex-col lg:text-right lg:mt-4 lg:-ml-12 lg:p-0">
                      <div class="ttp-text-xs font-bold text-[#2c3483]">{{ $t("progress.processing_time") }}</div>
                      <div class="ttp-text-xs mb-2">{{ $dayjs(data?.createdAt).format("DD/MM/YYYY") }} {{ $dayjs(data?.createdAt).format("HH:mm") }}</div>
                      <div v-if="n + 1 != incidentFormStore?.result?.progress?.length" class="ttp-text-xs font-bold text-[#2c3483]">{{ $t("progress.period_time") }}</div>
                      <div v-if="n + 1 != incidentFormStore?.result?.progress?.length && incidentFormStore?.result?.progress?.[n + 1]" class="ttp-text-xs">{{ $calculateTimeDifference(new Date(incidentFormStore?.result?.progress[n + 1]?.createdAt), new Date(data?.createdAt), masterDataStore.lang()) }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  </TransitionRoot>

  <button v-if="!componentIncidentFormStore.isChat" @click="componentIncidentFormStore.isChat = !componentIncidentFormStore.isChat" class="fixed flex gap-2 ttp-text-xs bottom-[0px] right-[10px] bg-ttp-chat-right text-white p-2 items-center justify-center rounded-tl-xl rounded-tr-xl">
    <div>
      <div class="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-100 text-gray-300 dark:bg-gray-700 dark:text-gray-500">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" data-slot="icon" class="inline-block ttp-icon-inside-box-04"><path fill-rule="evenodd" d="M7.5 6a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM3.751 20.105a8.25 8.25 0 0 1 16.498 0 .75.75 0 0 1-.437.695A18.683 18.683 0 0 1 12 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 0 1-.437-.695Z" clip-rule="evenodd"></path></svg>
      </div>
    </div>
    <div>{{ incidentFormStore?.result?.informerType == $enums.ENUM_INFORMER_TYPE.GUEST ? $convertString(incidentFormStore?.result?.guest?.name) : $convertString(incidentFormStore?.result?.tourist?.name) }} ({{ $t("page.IncidentManageView.document_number") }}: {{ $convertString(incidentFormStore?.result?.incidentNumber) }})</div>
    <div><component :is="componentIncidentFormStore.isChat ? $solidIcons.ChevronDownIcon : $solidIcons.ChevronUpIcon" class="inline-block ttp-icon-inside-box-03" /></div>
  </button>
  <div v-else class="fixed right-0 bottom-0 w-full lg:w-[460px] z-[100]">
    <ChatDialogComponent />
  </div>

  <!-- Default Caution -->
  <component :is="defaultFormComponents.form.popup.defaultCaution.component" v-bind="defaultFormComponents.form.popup.defaultCaution.props" />

  <!-- Assignee Success & Caution -->
  <component v-if="incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="assigneeFormComponents.form.popup.assigneeSuccess.component" v-bind="assigneeFormComponents.form.popup.assigneeSuccess.props" />
  <component v-else-if="incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="assigneeFormComponents.form.popup.assigneeCaution.component" v-bind="assigneeFormComponents.form.popup.assigneeCaution.props" />

    <!-- assignResponsible Success -->
  <component v-if="incidentAssigneeFormStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="assigneeFormComponents.form.popup.assignResponsible.component" v-bind="assigneeFormComponents.form.popup.assignResponsible.props" />
</template>
