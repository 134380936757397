<script lang="ts">
import { defineComponent, watch } from "vue";

import { useTouristSearchStore } from "./stores/useTouristSearchStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import type { $string } from "@/configs/types/Shared/typeShare";
import { contentToSelectNationality } from "@/configs/helpers/FormatHelper";
import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import { ENUM_FIELD_LOCALE } from "@/configs/enums/enum";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";

export default defineComponent({
  name: "TouristView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
      headers: [
        {
          key: "name",
          label: this.$t("form.name.label"),
          parser: (v: any) => this.$convertString(v),
        },
        {
          key: "contactNumber",
          label: this.$t("form.phone.label"),
        },
        {
          key: "nationality",
          label: this.$t("form.nationality.label"),
          parser: (v: $string) => {
            const masterDataStore = useMasterDataStore();
            return this.$convertString(masterDataStore.itemNationalities().find(({ value }: any) => value === this.$convertString(v))?.label);
          },
        },
        {
          key: "country",
          label: this.$t("form.country.label"),
          parser: (v: $string) => {
            const masterDataStore = useMasterDataStore();
            return this.$convertString(masterDataStore.itemCountries().find(({ value }: any) => value === this.$convertString(v))?.label);
          },
        },
        { key: "action", label: "" },
      ],
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.touristSearchStore.error.keyword || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.touristSearchStore.isRequired.keyword.is,
                textError: this.$t(this.touristSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.touristSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.touristSearchStore.isRequired.keyword.placeholder),
                value: this.touristSearchStore.result.keyword,
                name: this.touristSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.touristSearchStore.result.keyword = value;
              },
            },
          },
          select: {
            nationality: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.touristSearchStore.error.nationality || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.touristSearchStore.isRequired.nationality.is,
                textError: this.$t(this.touristSearchStore.isRequired.nationality.placeholder),
                title: this.$t(this.touristSearchStore.isRequired.nationality.label),
                name: this.touristSearchStore.isRequired.nationality.name,
                placeholder: this.$t(this.touristSearchStore.isRequired.nationality.placeholder),
                value: this.touristSearchStore.result.nationality,
                items: contentToSelectNationality(this.masterDataStore.nationalities, ENUM_FIELD_LOCALE.TH),
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.touristSearchStore.result.nationality = value;
              },
            },
            country: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.touristSearchStore.error.country || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.touristSearchStore.isRequired.country.is,
                textError: this.$t(this.touristSearchStore.isRequired.country.placeholder),
                title: this.$t(this.touristSearchStore.isRequired.country.label),
                name: this.touristSearchStore.isRequired.country.name,
                placeholder: this.$t(this.touristSearchStore.isRequired.country.placeholder),
                value: this.touristSearchStore.result.country,
                items: contentToSelectNationality(this.masterDataStore.countries, ENUM_FIELD_LOCALE.TH),
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value: $string) => {
                this.touristSearchStore.result.country = value;
              },
            },
          },
        },
        table: {
          button: {
            btnReset: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_reset"),
                classEnumName: this.$enums.ENUM_COLOR.YELLOW,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: async (e: Event) => {
                this.touristSearchStore.result.nationality = null;
                this.touristSearchStore.result.country = null;
                this.touristSearchStore.result.keyword = null;
                this.$router.replace({ query: { page: 1 } });
                await this.touristSearchStore.onSubmit(e);
              },
            },
            btnSearch: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_search"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnEdit: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                componentIcon: this.$outlineIcons.EyeIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block text-ttp-primary",
                title: "",
                classEnumName: this.$enums.ENUM_COLOR.NONE,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
          },
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
        },
        alert: {
          backgroundCheck: {
            component: PopupSuccessComponent,
            props: {
              isOpen: this.touristSearchStore.isBackgroundCheck,
              textTitle: this.$t("popup.success.no_criminal"),
              textDetail: "",
              functionProp: () => {
                this.touristSearchStore.isBackgroundCheck = false;
              },
              functionCancelProp: () => {
                this.touristSearchStore.isBackgroundCheck = false;
              },
            } as typeof PopupSuccessComponent.props,
          },
        },
      };
    },
  },
  methods: {
    async fetch(page: number = 1) {
      await Promise.all([await this.touristSearchStore.onUpdateCurrentPage(Number(this.$route.query.page) || page)]);
    },
    loading() {
      return this.touristSearchStore.loading;
    },
    async reset() {
      await this.touristSearchStore.$reset();
    },
    async setting() {
      await this.reset();
      await this.fetch();

      await this.masterDataStore.listNationality();
      await this.masterDataStore.listCountry();
    },
  },
  async mounted() {
    await this.setting();
  },
  async unmounted() {
    await this.reset();
  },
  created() {
    watch(
      () => this.$route.query.page,
      () => {
        this.fetch();
      }
    );
  },
  setup() {
    const masterDataStore = useMasterDataStore();
    const touristSearchStore = useTouristSearchStore();

    return {
      masterDataStore,
      touristSearchStore,
    };
  },
});
</script>

<template>
  <div class="ttp-form">
    <form @submit="touristSearchStore.onSubmit">
      <div class="w-full gap-2">
        <div class="ttp-between items-start flex-col lg:flex-row">
          <div class="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-2 w-full">
            <component classTagName="w-full text-left" :is="defaultFormComponents.form.select.nationality.component" v-bind="defaultFormComponents.form.select.nationality.props" @updateValue="defaultFormComponents.form.select.nationality.onUpdateValue" />
            <component classTagName="w-full text-left" :is="defaultFormComponents.form.select.country.component" v-bind="defaultFormComponents.form.select.country.props" @updateValue="defaultFormComponents.form.select.country.onUpdateValue" />
            <component classTagName="w-full text-left" :is="defaultFormComponents.form.input.keyword.component" v-bind="defaultFormComponents.form.input.keyword.props" @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue" />
          </div>
          <div class="flex flex-cols gap-2 ttp-between items-start">
            <component :is="defaultFormComponents.table.button.btnReset.component" v-bind="defaultFormComponents.table.button.btnReset.props" @click="defaultFormComponents.table.button.btnReset.click" />
            <component :is="defaultFormComponents.table.button.btnSearch.component" v-bind="defaultFormComponents.table.button.btnSearch.props" />
          </div>
        </div>
      </div>
    </form>
  </div>

  <TableComponent :headers="headers" :content="touristSearchStore.tableResults?.items" :loading="touristSearchStore.loading" counter :counter-label="$t('form.number.label')">
    <template v-slot:body-status="props">
      <component :is="defaultFormComponents.table.badge.status.component" v-bind="defaultFormComponents.table.badge.status.props" :title="$formattedStatus($classStatus(props.value))" :classEnumName="$classStatus(props.value)" />
    </template>
    <template v-slot:body-action="props">
      <router-link
        :to="{
          name: 'TouristManageView',
          params: { id: props?.item?.id },
        }"
      >
        <component :is="defaultFormComponents.table.button.btnEdit.component" v-bind="defaultFormComponents.table.button.btnEdit.props" />
      </router-link>
    </template>
  </TableComponent>
  <PaginationComponent
    :currentPage="Number($route.query.page) || 1"
    :totalPage="touristSearchStore.totalPage"
    :gotoPage="touristSearchStore.gotoPage"
    :nextPage="touristSearchStore.nextPage"
    :prevPage="touristSearchStore.prevPage"
    :onUpdateCurrentPage="() => {}"
    :totalItem="touristSearchStore.total"
    unitItem="Persons"
  />

  <component v-if="touristSearchStore.isBackgroundCheck" :is="defaultFormComponents.alert.backgroundCheck.component" v-bind="defaultFormComponents.alert.backgroundCheck.props" />
</template>
