export default {
  layout: {
    appName: "旅游警察",
    menu: {
      AlertView: `管理通知`,
      AlertManageView: `通知`,
      PreviewView: `预览`,
      HomeView: `首页`,
      LogoutView: `登出`,
      NotFoundView: `页面未找到`,
      MaintenanceView: `维护`,
      LoginView: `登录`,
      ForgotPasswordView: `更改密码`,
      AllIncidentView: `所有事件`,
      IncidentView: `管理事件`,
      NewIncidentView: `新事件`,
      AcceptedIncidentView: `接受的事件`,
      WaitingIncidentView: `等待的事件`,
      CurrentIncidentView: `当前事件`,
      ReportingIncidentView: `报告的事件`,
      ClosingIncidentView: `关闭中的事件`,
      ClosedIncidentView: `已关闭的事件`,
      RejectedIncidentView: `拒绝的事件`,
      ConferenceView: `NT 会议`,
      OverviewView: `概述`,
      DashboardView: `仪表板`,
      DashboardIncidentTypeView: `事件类型`,
      ProfileView: `我的账户`,
      SettingMenuView: `我的账户`,
      ProfileEditView: `用户资料`,
      PasswordSettingView: `密码设置`,
      UserView: `管理用户`,
      UserManageView: `用户资料`,
      TouristView: `管理游客`,
      TouristManageView: `游客资料`,
      ChatView: `聊天室`,
      IncidentManageView: `管理事件`,
      IncidentNoticeView: `事件通知`,
      IncidentReportView: `报告`,
      HistoryView: `历史`,
      HistoryNoticeView: `事件通知`,
      HistoryReportView: `在线系统中注册的案例的初步事件报告`,
      ReportIncidentView: `报告`,
      VoiceCallView: `语音通话`,
      OverviewReportView: `报告`,
      NewsView: "管理新闻",
      NewsManageView: "新闻",
      NewsInternalView: "内部新闻",
      PopularPlaceView: "热门地点",
    },
    menu_head: {
      PreviewTitle: "预览",
      AccountTitle: "账户",
      UserTitle: "成员",
      TouristTitle: "旅客",
      IncidentTitle: "事件报告",
      SupportTitle: "支持软件的方案",
      ProfileTitle: "主页资料",
      HistoryTitle: "事件报告",
      DashboardTitle: "仪表板",
      NewsTitle: "News",
    },
    menu_bottom: {
      home_title: "首页",
      report_title: "提交报告",
      history_title: "处理记录",
      profile_title: "主页资料",
    },
    by: "推进",
    create: "创造",
    update: "改正",
    edit_address: "改正地址",
    edit_address_placeholder: "请输入地址",
    please_input: "请输入",
    not_found: "没有发现信息",
    not_found_image: "没有发现图片",
    loading: "正在下载",
    all: "看到全部",
    case: "案件",
    non: "没有指定",
    non_image: "不，我不想",
    non_location: "不想分享位置",
    select_picture: "请选择图片",
    result: "结果",
    anonymous: "匿名",
    gender: {
      male: "男",
      female: "女",
    },
    date: `Date`,
    error_detail: `An error has occurred. Please contact the public relations department.`,
  },
  divider: {
    or_login_with: "登入",
  },
  page: {
    AllIncidentView: {
      title_1: `工作人员到达时间约:`,
      title_2: `工作人员预计到达时间:`,
      title_3: `工作人员到达事故地点时间:`,
      title_4: `在事故现场花费的时间:`,
      waiting_1: `工作人员正在赶来`,
      waiting_2: `请稍等...`,
      status_1: `事件成功处理`,
      status_2: `工作完成`,
      status_3: `报告取消`,
    },
    PermissionView: {
      app_detail: `为了获取帮助，请始终启用应用程序访问权限，如下所示。`,
      web_detail: `<hr class="my-2" />
      <h2 class="ttp-text-lg font-medium text-center">在浏览器中启用权限的步骤</h2>
      <hr class="my-2" />
      <div>1. 打开浏览器</div>
      <div>2. 点击屏幕右上角的三个点以打开菜单</div>
      <div>3. 选择 "设置" (Settings)</div>
      <div>4. 向下滚动并点击 "隐私和安全" (Privacy and security)</div>
      <div>5. 选择 "网站设置" (Site settings)</div>
      <hr class="my-2" />
      <h3 class="ttp-text-base font-medium">权限:</h3>
      <div>- 位置: 点击 "位置" 并开启 "网站可以请求获取你的位置信息"</div>
      <div>- 摄像头: 点击 "摄像头" 并开启 "网站可以请求使用你的摄像头"</div>
      <div>- 麦克风: 点击 "麦克风" 并开启 "网站可以请求使用你的麦克风"</div>
      <div>- 通知: 点击 "通知" 并开启 "网站可以请求发送通知"</div>
      <div>- 照片: 如果网站请求访问文件权限，可以根据每个网站的需求设置文件访问权限</div>
    `,
    },
    NewsInternalDetailView: {
      not_found_gallery: `未找到相册`,
      gallery: `相册`,
    },
    NewsDetailView: {
      not_found_gallery: `未找到相册`,
    },
    NewsInternalView: {
      gallery: `相册`,
    },
    NewsView: {
      title: `新闻`,
      not_found: `未找到新闻`,
    },
    PreviewView: {
      title: `预览`,
    },
    HomeView: {
      title: `首页`,
      hello_txt: `您好`,
      welcome_txt: `欢迎来到泰国.`,
      welcome_detail_txt: `旅游警察，您的第一个朋友`,
      incident_status: `事件状态`,
      popular_places: `热门地点`,
      news: `新闻`,
      box_title: `需要向旅游警察报告事件吗？`,
      box_detail: `请按按钮回答旅游警察的问题`,
      sos_title: `需要紧急帮助吗？`,
      sos_detail: `请按紧急呼叫按钮`,
      sos_btn: `SOS`,
      see_more: `查看更多`,
    },
    LoginView: {
      title: "登陆",
      subtitle: "欢迎进入系统",
      agent_subtitle: "Welcome to Agent",
      lang: "选择语言",
    },
    ForgotPasswordView: {
      title: "忘记密码",
      detail_step_1: "Don't worry, we'll get you back! Your email.",
      detail_step_2: "请输入OTP密码 ",
      detail_step_3: "现在可以设置新密码",
      detail_step_verify: "验证你的身份",
      create: "设置新密码",
      desc: "输入你的伊妹为了经过检证",
      email: "We'll send a 6-digit code to your email.",
      otp: "检证OTP密码",
      otp_send_to: "密码已经发完成",
      otp_resend: "再发一次密码",
    },
    ResetPasswordView: {
      title: "密码已经换完成",
      detail: "密码已经换完成",
    },
    NotFoundView: {
      status_number: "没有发现信息",
      title: `这有点尴尬...`,
      detail: `看起来我们找不到您要找的页面。它可能已被移动或删除。对不起！请尝试在我们的网络应用程序中搜索。`,
    },
    MaintenanceView: {
      title: "正在改进过程中",
      detail: `请稍等，我们的网站正在改进中，很快就会以比新鲜烤好的百吉饼更炙热的姿态回来。感谢您的耐心等待。我们很快会为您带来一些非常酷的东西！`,
    },
    TouristManageView: {
      record_title: "联系记录",
    },
    OverviewView: {
      title_left: "最新动态",
      title_right: "sos 紧急报告",
      tab_left: "重新举报",
      tab_right: "已处理",
      not_found: "没新消息",
      tab_new: "最新消息",
      tab_in_progress: "正在进行中",
      tab_successful: "改正成功",
    },
    IncidentManageView: {
      title: "举报详细",
      document_number: "案例序号",
      receive_date: "举报日期",
      information: "举报者资料",
      history: "处理记录",
      emergency_contact: "紧急联系人",
    },
    IncidentNoticeView: {
      history: "处理记录",
      number_code: "案件数量",
    },
    RegisterView: {
      title: "报名成员",
      desc: "电子邮件",
      create: "创建账户",
      login: "您已有帐户",
    },
    ChatView: {
      title: "保护人",
      location_title: "定位",
      reply_message: `回复消息`,
      make_a_call: `打电话`,
      hang_up: `挂断`,
      answer_a_call: `接听电话`,
      response_voice_call: `通过语音通话回应`,
      response_bot: `由保护者（机器人）回应`,
      response_police: `由警察回应`,
      end_conversation: `结束对话`,
      detail: `如果有新的事件报告，请联系我们`,
    },
    ChatManageView: {
      title: "保护人",
      location_title: "定位",
    },
    MessageView: {
      title: "短信",
    },
    PreChatView: {
      title: "报告事件",
      sub_title: "报告事件",
      detail: "报告该事件并向泰国旅游警察寻求帮助。这不是一个通知。",
    },
    VoiceCallView: {
      hungUp: {
        hero: 'The call has ended.',
        title: 'Have you finished reporting your issue?',
        thankYou: {
          title: 'Thank you for information',
          reportOn: 'Your report no. is',
          description: 'Our tourist police will assist you as soon as possible. You can check the status of your report in history',
        },
        button: {
          confirm: 'Yes, I have',
          callAgain: 'Call 1155',
        }
      }
    },
    HistoryPoliceTouristAndAgentView: {
      title: "状况记录",
    },
    HistoryDetailPoliceTouristAndAgentView: {
      title: "状况记录",
      report_no: "报告号",
      report_info: "报告数据",
      not_found_detail: "您以前没有记录",
    },
    ReportIncidentView: {
      title: "提交报告",
      guardian_chatbot: "保护人聊天",
      report_incident_with_chatbot: "保护人报告",
      sos_report: "sos 报告",
      share_location: "和警方分享位置",
    },
    ProfileMenuView: {
      title: "主页资料",
    },
    ProfileEditTouristView: {
      title: "改正资料",
    },
    ProfileSocialMediaAccountView: {
      title: "社交媒体账户",
      link_txt: "连接",
    },
    ProfileUnlinkView: {
      link_google_txt: `您已连接到Google账户`,
      link_fb_txt: `您已连接到Facebook账户`,
      link_apple_txt: `您已连接到Apple账户`,
    },
    SettingContactUsView: {
      title: `联系我们`,
    },
    SettingLanguageView: {
      title: `语言`,
    },
    SettingNotificationView: {
      title: `通知`,
      push_notifications_txt: `推送通知`,
      chat_txt: `聊天通知`,
    },
    SettingSecurityPrivacyChangePasswordView: {
      title: `更改密码`,
    },
    SettingSecurityPrivacyView: {
      title: `安全和隐私`,
      allow_location: `允许跟踪我的位置`,
      allow_camera: `允许访问相机`,
      allow_photo: `允许访问相册`,
      allow_mic: `允许访问麦克风`,
    },
    PopularPlaceView: {
      not_found: `未找到地点`,
    },
    RegisterSuccessView: {
      head: `注册成功！`,
      title: `确认成功！`,
      welcome: `欢迎`,
      detail_1: `我们将尽力帮助您。感谢您信任我们`,
      detail_2: `您的账户已准备就绪。点击后将跳转到首页`,
    },
  },
  btn: {
    btn_permission: `隐私设置`,
    btn_readmore: `阅读更多`,
    btn_skip: `跳过`,
    btn_go_home: `返回主页`,
    btn_background_check: `背景检查`,
    btn_unlink: `取消连接`,
    btn_destination_stoped_incident: `到达目的地并停止事件`,
    btn_repair_report: `编辑`,
    btn_google_map: `前往Google地图`,
    btn_reset: `重置`,
    btn_receieve_report: `关闭任务`,
    btn_map: `地图`,
    btn_share: `分享`,
    btn_register: `注册`,
    btn_create_user: `创建用户`,
    btn_submit: `提交`,
    btn_confirm: `确认`,
    btn_select: `选择文件`,
    btn_delete: `删除此数据`,
    btn_delete_file: `删除文件`,
    btn_delete_image: `删除图片`,
    btn_delete_account: `删除账户`,
    btn_send_email: `发送邀请邮件`,
    btn_cancel: `取消`,
    btn_save: `保存`,
    btn_next: `下一步`,
    btn_accept: `接受`,
    btn_confirm_save: `确认保存`,
    btn_incident: `事件`,
    btn_record: `管理记录`,
    btn_assign: `分配`,
    btn_report: `提交报告`,
    btn_edit: `编辑`,
    btn_back: `返回`,
    btn_manage: `管理`,
    btn_export: `导出数据`,
    btn_new_case: `创建事件`,
    btn_view_report: `查看报告`,
    btn_back_dashboard: `返回仪表板`,
    btn_forgot_password: `忘记密码`,
    btn_sign_in: `登录`,
    btn_change_password: `更改密码`,
    btn_back_login: `返回登录页面`,
    btn_not_receive: `没有收到代码?`,
    btn_resend_code: `重新发送代码`,
    btn_search: `搜索`,
    btn_reject: `拒绝`,
    btn_all: `查看全部`,
    btn_receive: `接受`,
    btn_view_notification: `查看通知`,
    btn_manage_history: `管理记录`,
    btn_chat: `进入聊天室`,
    btn_login_with_phone: `继续使用电话号码`,
    btn_fb: `继续使用Facebook`,
    btn_google: `继续使用Google`,
    btn_apple: `继续使用Apple`,
    btn_successful: `写事件报告`,
    btn_edit_completed: `保存操作结果`,
    btn_location_completed: `到达目的地并停止事件`,
    btn_edit_report: `提交报告`,
    btn_approve_report: `批准报告`,
    btn_cancel_report: `取消报告`,
    btn_select_default: `选择`,
    btn_non: `未指定`,
    btn_no: `不`,
    btn_fill_in_manually: `手动填写`,
    btn_start_report: `开始报告`,
    btn_get_report: `报告紧急事件`,
    btn_logout: `是的，退出登录`,
    btn_news: `创建新闻`,
    btn_submit_work: `保存操作结果`,
    btn_alert: `创建警报`,
    btn_download: `下载`,
  },
  popup: {
    cancel: {
      cancel_title: "确定取消",
      cancel_detail: "您确定? 取消这次信息更新?",
    },
    reject: {
      reject_title: `确认取消报告`,
      reject_detail: `您确定要取消此报告吗？`,
    },
    error: {
      delete_title: "确认删除",
      delete_detail: "您确认吗?删除这个数据?",
      delete_file_image_title: "确认图片删除",
      delete_file_image_detail: "您确定?删除这个图片?",
    },
    success: {
      no_criminal: `未发现犯罪记录`,
      title: `保存成功`,
      detail: `您可以进行下一步`,
      history_detail: `您可以回顾事件报告`,
      receive_title: `接收成功`,
      receive_detail: `您可以进行下一步`,
      edit_receive_title: `成功修正负责人`,
      edit_receive_detail: `负责人可以进行下一步`,
      reject_title: `报告拒绝成功`,
      email_title: `邮件已编辑`,
      email_detail: `您可以使用您的新邮件登录，请再次登录`,
      delete_title: `数据删除成功`,
      delete_detail: `这个数据已经被删除`,
      delete_user_title: `账户删除成功`,
      delete_user_detail: `账户已经被删除`,
      delete_image_title: `图片删除成功`,
      delete_image_detail: `图片已经被删除`,
      close_title: `工作关闭成功`,
      close_detail: ``,
      edit_title: `编辑成功`,
      edit_detail: ``,
      send_title: `报告发送成功`,
      send_detail: ``,
      waiting_title: `提交成功`,
      closed_title: `工作关闭成功`,
      closed_detail: `您可以进行下一步`,
      confirm_title: `报告批准成功`,
      confirm_detail: ``,
      receive_suppressed_title: `事件成功抑制`,
      receive_suppressed_detail: `您可以进行下一步`,
      submit_title: `接收成功`,
      submit_detail: `您可以进行下一步`,
      copy_title: `复制成功`,
      send_wait_title: `保存成功`,
      save_receive_title: `工作保存成功`,
      tel1155_title: `通知`,
      tel1155_detail: `此通话需付费。您接受还是不接受？`,
    },
    caution: {
      default_title: "对不起",
      delete_title: "对不起",
      default_detail: "目前没有报告",
      recipient_detail: "该主题已存在或信息已更改",
      completed_detail: "无法完成,因为此项目已经被编辑",
      delete_image_detail: "无法删除图像",
      delete_detail: "无法删除",
      delete_user_detail: "无法删除该账户 ",
      error_detail: "遇到错误 无法完成交易",
      duplicate_detail: "系统中以存在数据",
    },
    textarea: {
      process_title: "处理结束",
      process_placeholder: "请输入处理结果",
      reject_title: "拒绝操作结果",
    },
    logout: {
      title: "登出",
      detail: "您确定要退出吗?",
    },
  },
  form: {
    address: {
      label: "地址",
      placeholder: "请输入地址",
    },
    opening_hours: {
      label: "营业时间",
      placeholder: "请输入营业时间",
    },
    tourist_types: {
      label: "游客类型",
      placeholder: "请输入游客类型",
    },
    types: {
      label: "类型",
      placeholder: "请输入类型",
    },
    map: {
      label: "地图",
      placeholder: "请输入地图",
    },
    latitude: {
      label: "纬度",
      placeholder: "请输入纬度",
    },
    longitude: {
      label: "经度",
      placeholder: "请输入经度",
    },
    area: {
      label: "区域",
      placeholder: "请输入区域",
    },
    metadata: {
      label: "到达事故现场需要多少分钟（以分钟为单位输入数字）",
      placeholder: "请输入到达事故现场需要多少分钟",
    },
    contentAt: {
      label: "新闻日期",
      placeholder: "请选择新闻日期",
    },
    publishedAt: {
      label: "显示日期",
      placeholder: "请选择显示日期",
    },
    thumbnail: {
      label: "图片",
      placeholder: "请选择图片",
    },
    gallery: {
      label: "相册",
      placeholder: "请选择图片",
    },
    published: {
      label: "显示状态",
      placeholder: "请选择显示状态",
    },
    isInternal: {
      label: "内部新闻状态",
      placeholder: "请选择内部新闻状态",
    },
    translations: {
      label: "泰文标题",
      placeholder: "请输入泰文标题",
    },
    translations_cn: {
      label: "中文文章",
      placeholder: "请输入中文文章",
    },
    translations_en: {
      label: "英文文章",
      placeholder: "请输入英文文章",
    },
    translations_jp: {
      label: "日文文章",
      placeholder: "请输入日文文章",
    },
    translations_kr: {
      label: "韩文文章",
      placeholder: "请输入韩文文章",
    },
    translations_ru: {
      label: "俄文文章",
      placeholder: "请输入俄文文章",
    },
    translations_th: {
      label: "泰文文章",
      placeholder: "请输入泰文文章",
    },
    channel: {
      label: `渠道`,
      placeholder: `请选择`,
    },
    is_track_location: {
      label: `允许访问位置跟踪`,
      placeholder: `请选择`,
    },
    is_camera: {
      label: `允许访问相机`,
      placeholder: `请选择`,
    },
    is_photo: {
      label: `允许访问照片`,
      placeholder: `请选择`,
    },
    is_microphone: {
      label: `允许访问麦克风`,
      placeholder: `请选择`,
    },
    is_push_notification: {
      label: `允许访问推送通知`,
      placeholder: `请选择`,
    },
    is_chat: {
      label: `允许访问聊天`,
      placeholder: `请选择`,
    },
    emergency_contact: {
      label: `紧急联系人`,
      placeholder: `请输入紧急联系人`,
    },
    images: {
      label: `图片`,
      placeholder: `请选择图片`,
    },
    region: {
      label: `区域`,
      placeholder: `请选择区域`,
    },
    message: {
      label: `Aa`,
      placeholder: `Aa`,
    },
    numberic: {
      label: ``,
      placeholder: ``,
    },
    location_search: {
      label: `事故地点`,
      placeholder: `搜索事故地点`,
    },
    notification_date: {
      label: `通知日期`,
      placeholder: `请选择通知日期`,
    },
    case_number: {
      label: `报告编号`,
      placeholder: `请输入报告编号`,
    },
    friendly_category: {
      label: `游客事件类型`,
      placeholder: `-`,
    },
    story_type: {
      label: `事件类型`,
      placeholder: `请选择事件类型`,
    },
    province_area: {
      label: `省区`,
      placeholder: `请选择省区`,
    },
    recipient: {
      label: `收件人`,
      placeholder: `请选择收件人`,
    },
    passport_number: {
      label: `护照号码`,
      placeholder: `请输入护照号码`,
    },
    number: {
      label: `编号`,
      placeholder: `请输入编号`,
    },
    gender: {
      label: `性别`,
      placeholder: `请输入性别`,
    },
    nationality: {
      label: `国籍`,
      placeholder: `请选择国籍`,
    },
    country: {
      label: `国家 / 地区`,
      placeholder: `请选择国家 / 地区`,
    },
    app_channel: {
      label: `注册渠道`,
      placeholder: `请选择注册渠道`,
    },
    notification_channel: {
      label: `通知渠道`,
      placeholder: `请选择通知渠道`,
    },
    phone: {
      label: `电话号码`,
      placeholder: `请输入电话号码`,
    },
    informer_phone: {
      label: `报告人电话号码`,
      placeholder: `请输入报告人电话号码`,
    },
    identification_type: {
      label: `身份验证类型`,
      placeholder: `请选择身份验证类型`,
    },
    type_code: {
      label: ``,
      placeholder: ``,
    },
    id: {
      label: `ID`,
      placeholder: `请输入ID`,
    },
    email: {
      label: `电子邮件`,
      placeholder: `请输入电子邮件`,
    },
    nowPassword: {
      label: `当前密码`,
      placeholder: `请输入当前密码`,
    },
    password: {
      label: `密码`,
      placeholder: `请输入密码`,
    },
    newPassword: {
      label: `新密码`,
      placeholder: `请输入新密码`,
    },
    repeatPassword: {
      label: `确认新密码`,
      placeholder: `请确认新密码`,
    },
    remember_me: {
      label: `记住我`,
      placeholder: ``,
    },
    status: {
      label: `状态`,
      placeholder: `请选择状态`,
    },
    role: {
      label: `角色`,
      placeholder: `请选择角色`,
    },
    division: {
      label: "部门",
      placeholder: "请选择部门",
    },
    subdivision: {
      placeholder: "请选择分部门",
    },
    station: {
      placeholder: "请选择车站",
    },
    responsibleArea: {
      label: "负责区域",
      placeholder: "请选择负责区域",
    },
    keyword: {
      label: `搜索...`,
      placeholder: ``,
    },
    image_name: {
      label: `图片`,
      placeholder: `请输入图片名称`,
    },
    informer_name: {
      label: `报告人名称`,
      placeholder: `请输入报告人名称`,
    },
    informer_type: {
      label: `报告人类型`,
      placeholder: `请选择报告人类型`,
    },
    name: {
      label: `名称`,
      placeholder: `请输入名称`,
    },
    title: {
      label: `标题`,
      placeholder: `请输入标题`,
    },
    title_news: {
      label: `标题`,
      placeholder: `请输入文本`,
    },
    title_alert: {
      label: `标题`,
      placeholder: `请输入文本`,
    },
    description: {
      label: `描述`,
      placeholder: `请输入文本`,
    },
    detail: {
      label: `文章`,
      placeholder: `请输入文本`,
    },
    firstName: {
      label: `名字`,
      placeholder: `请输入名字`,
    },
    lastName: {
      label: `姓氏`,
      placeholder: `请输入姓氏`,
    },
    idCard: {
      label: `身份证号码`,
      placeholder: `请输入身份证号码`,
    },
    isOpenAgent: {
      label: `启用代理平台`,
      placeholder: ``,
    },
    touristDetail: {
      label: `事件详情（来自游客）`,
      placeholder: `请输入事件详情（来自游客）`,
    },
    notificationChannel: {
      label: `通知渠道`,
      placeholder: ``,
    },
    officerDetail: {
      label: `事件详情（供官员使用）`,
      placeholder: `请输入详情`,
    },
    no: {
      label: `代码`,
      placeholder: `请输入代码`,
    },
    subject: {
      label: `主题`,
      placeholder: `请选择主题`,
    },
    important: {
      label: `重要性`,
      placeholder: `请选择重要性`,
    },
    district: {
      label: `区`,
      placeholder: `请选择区`,
    },
    sub_district: {
      label: `子区`,
      placeholder: `请选择子区`,
    },
    location_detail: {
      label: `位置详情`,
      placeholder: `请输入位置详情`,
    },
    pin_location: {
      label: `位置钉`,
      placeholder: ``,
    },
    submit_story: {
      label: `提交故事`,
      placeholder: ``,
    },
    close_case: {
      label: `关闭案例`,
      placeholder: ``,
    },
    send_responsibility: {
      label: `发送到负责区域`,
      placeholder: `请选择负责区域`,
    },
    agency: {
      label: `机构`,
      placeholder: ``,
    },
    police_inspector: {
      label: `警察检查员`,
      placeholder: ``,
    },
    result: {
      label: `结果`,
      placeholder: ``,
    },
    responsible_person: {
      label: `负责人`,
      placeholder: `请选择负责人`,
    },
    updated_date: {
      label: `最后更新`,
      placeholder: ``,
    },
    estimate_time: {
      label: `预计时间`,
      placeholder: ``,
    },
    subject_user: {
      label: `主题用户`,
      placeholder: ``,
    },
    assign_user: {
      label: `分配用户`,
      placeholder: ``,
    },
    inspector_user: {
      label: `检查员用户`,
      placeholder: ``,
    },
    type: {
      label: `事件类型`,
      placeholder: `请选择事件类型`,
    },
    startAt: {
      label: `开始日期`,
      placeholder: `请选择开始日期`,
    },
    endAt: {
      label: `结束日期`,
      placeholder: `请选择结束日期`,
    },
    information_user: {
      label: `报告人信息`,
      placeholder: `请选择报告人信息`,
    },
    admin_user: {
      label: `管理员用户`,
      placeholder: `请选择管理员用户`,
    },
    control_user: {
      label: `控制用户`,
      placeholder: `请选择控制用户`,
    },
    responsibleAreaCodes: {
      label: "负责区域",
      placeholder: "请选择负责区域",
    },
    locales: {
      label: "语言",
      placeholder: "请选择语言",
    },
  },
  error: {
    password: "密码规定至少 6 位字母",
    invalid_credential: "无法进入，请重新输入密码",
  },
  caution: {
    user_1: `注意: 如果启用代理操作系统，则需要选择负责区域`,
    password_1: `密码必须至少包含6个字符，包括大写字母、小写字母和数字`,
    picture_1: `建议: 图像尺寸为 200 x 200 像素`,
    picture_2: `(最大 5MB) 文件类型: JPG (JPEG), PNG`,
    picture_1_2: `建议: 图像尺寸为 490 x 385 像素`,
    picture_2_2: `(最大 5MB) 文件类型: JPG (JPEG), PNG`,
    delete_title: ``,
    email_1: `我们将使用此电子邮件验证您的帐户`,
  },
  role: {
    ADMIN: "系统管理员",
    CENTRAL_OFFICER: "1155 热线电员",
    OFFICER: "区域站的警察长官",
    REPORTER: "报告者",
    AGENT: "巡视警察",
  },
  incident_status: {
    waiting: "等候进行",
    in_progress: "正在进行中",
    canceled: "已完成",
  },
  lang: {
    th: "ไทย",
    en: "英文",
    cn: "中国大陆",
    ru: "Россия",
    kr: "대한민국",
    jp: "日本",
  },
  progress: {
    processing_time: "处理时间",
    period_time: "期间",
  },
  go_to_chat: "Go back to chat",
  sos: {
    title: 'SOS 系统可以用于报告下述情况',
    conditions: [
      '遭遇人身伤害，被他人虐待 （遭遇第三方的暴力殴打）',
      '被抢劫 （被他人抢夺财物，遇到劫匪胁迫自身生命安全，遭遇生命及财产安全的损失）',
      '遭遇严重的突发事故',
      '突然感染严重疾病',
    ],
    description: '如您遭遇以上4种危险, 均可以通过 tourist police App 发送举报信息, 或者拨打 1155 旅游警察电话进行举报!',
    button: {
      confirm: '确认',
      cancel: '取消',
    },
  },
  errorMessage: {
    duplicateEmail: '重复的电子邮件'
  }
};
