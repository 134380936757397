<script lang="ts">
import { defineComponent } from "vue";

import { useUserDeleteStore } from "./stores/useUserDeleteStore";
import { useUserSearchStore } from "./stores/useUserSearchStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import type { typeGetUser } from "@/configs/types/User/typeUser";
import type { ENUM_FIELD_STATUS } from "@/configs/enums/enum";

import BadgeDefaultComponent from "@/components/Forms/Badge/BadgeDefaultComponent.vue";
import ButtonDefaultComponent from "@/components/Forms/Button/ButtonDefaultComponent.vue";
import FormInputDefaultComponent from "@/components/Forms/Input/FormInputDefaultComponent.vue";
import FormSelectBoxDefaultComponent from "@/components/Forms/SelectBox/FormSelectBoxDefaultComponent.vue";
import PopupCautionComponent from "@/components/Popups/PopupCautionComponent.vue";
import PopupDeleteComponent from "@/components/Popups/PopupDeleteComponent.vue";
import PopupSuccessComponent from "@/components/Popups/PopupSuccessComponent.vue";
import type { typeGetOrganization, typeOrganizationContent } from "@/configs/types/MasterData/typeOrganization";

export default defineComponent({
  name: "UserView",
  components: {},
  data() {
    return {
      componentName: this.$options.name,
      headers: [
        {
          key: "content",
          label: this.$t("form.name.label"),
          parser: (v: any) => v?.[0]?.shortName,
        },
        {
          key: "idCard",
          label: this.$t("form.idCard.label"),
          parser: (v: any) => this.$convertString(v),
        },
        {
          key: "organization",
          label: this.$t("form.division.label"),
          parser: (v: typeGetOrganization) => this.$convertString(v?.content.find(({ locale }: typeOrganizationContent) => locale === "TH")?.fullName),
        },
        {
          key: "role",
          label: this.$t("form.role.label"),
          parser: (v: typeGetUser) => {
            return this.$t(`role.${v}`);
          },
        },
        {
          key: "status",
          label: this.$t("form.status.label"),
          parser: (v: any) => this.$convertString(v),
        },
        { key: "action", label: "" },
      ],
    };
  },
  computed: {
    defaultFormComponents() {
      return {
        form: {
          input: {
            keyword: {
              component: FormInputDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.userSearchStore.error.keyword || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userSearchStore.isRequired.keyword.is,
                textError: this.$t(this.userSearchStore.isRequired.keyword.placeholder),
                title: this.$t(this.userSearchStore.isRequired.keyword.label),
                placeholder: this.$t(this.userSearchStore.isRequired.keyword.placeholder),
                value: this.userSearchStore.query.keyword,
                name: this.userSearchStore.isRequired.keyword.name,
              } as typeof FormInputDefaultComponent.props,
              onUpdateValue: (value?: string) => {
                this.userSearchStore.query.keyword = value;
              },
            },
          },
          select: {
            status: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.userSearchStore.error.status || "").length > 0,
                isIcon: false,
                isDisable: true,
                isRequired: this.userSearchStore.isRequired.status.is,
                textError: this.$t(this.userSearchStore.isRequired.status.placeholder),
                title: this.$t(this.userSearchStore.isRequired.status.label),
                name: this.userSearchStore.isRequired.status.name,
                placeholder: this.$t(this.userSearchStore.isRequired.status.placeholder),
                value: this.userSearchStore.query.status,
                items: this.$convertEnumToArray(this.$enums.ENUM_FIELD_STATUS),
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value?: ENUM_FIELD_STATUS) => {
                this.userSearchStore.query.status = value;
              },
            },
            role: {
              component: FormSelectBoxDefaultComponent,
              props: {
                isLabel: false,
                isSpecial: false,
                isVisible: true,
                isError: (this.userSearchStore.error.role || "").length > 0,
                isIcon: false,
                isDisable: this.loading(),
                isRequired: this.userSearchStore.isRequired.role.is,
                textError: this.$t(this.userSearchStore.isRequired.role.placeholder),
                title: this.$t(this.userSearchStore.isRequired.role.label),
                name: this.userSearchStore.isRequired.role.name,
                placeholder: this.$t(this.userSearchStore.isRequired.role.placeholder),
                value: this.userSearchStore.query.role,
                items: this.masterDataStore.itemRoles(),
              } as typeof FormSelectBoxDefaultComponent.props,
              onUpdateValue: (value?: string) => {
                this.userSearchStore.query.role = value;
              },
            },
          },
          button: {
            btnCreate: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                classTagName: "w-full md:col-end-4 lg:col-end-7",
                htmlIcon: "",
                title: this.$t("btn.btn_create_user"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: () => {
                this.$router.push({ name: "UserManageView" });
              },
            },
          },
        },
        table: {
          button: {
            btnReset: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: false,
                title: this.$t("btn.btn_reset"),
                classEnumName: this.$enums.ENUM_COLOR.YELLOW,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: () => {
                this.userSearchStore.query.status = undefined;
                this.userSearchStore.query.role = undefined;
                this.userSearchStore.query.keyword = undefined;
                this.onSubmit();
              },
            },
            btnEdit: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                componentIcon: this.$outlineIcons.PencilSquareIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block text-ttp-primary",
                title: "",
                classEnumName: this.$enums.ENUM_COLOR.NONE,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
            btnDelete: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isLoading: this.loading(),
                isDisable: this.loading(),
                componentIcon: this.$outlineIcons.TrashIcon,
                classIcon: "ttp-icon-inside-box-03 inline-block text-ttp-error",
                title: "",
                classEnumName: this.$enums.ENUM_COLOR.NONE,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
              click: (props: { item: { id: string } }) => {
                this.userDeleteStore.isDelete = !this.userDeleteStore.isDelete;
                this.userDeleteStore.result.id = props?.item?.id as string;
              },
            },
            btnSearch: {
              component: ButtonDefaultComponent,
              props: {
                isSpecial: false,
                isVisible: true,
                isIcon: false,
                isLoading: this.loading(),
                isDisable: this.loading(),
                htmlIcon: "",
                isSubmit: true,
                title: this.$t("btn.btn_search"),
                classEnumName: this.$enums.ENUM_COLOR.DEFAULT_1,
                size: this.$enums.ENUM_SIZE.SMALL,
              } as typeof ButtonDefaultComponent.props,
            },
          },
          popup: {
            deletePopup: {
              component: PopupDeleteComponent,
              props: {
                isOpen: this.userDeleteStore.isDelete,
                textTitle: this.$t("popup.error.delete_title"),
                textDetail: this.$t("popup.error.delete_detail"),
                functionProp: async (e: Event) => {
                  this.userDeleteStore.isDelete = !this.userDeleteStore.isDelete;
                  await this.userDeleteStore.onSubmit(e);
                },
                functionCancelProp: () => {
                  this.userDeleteStore.isDelete = false;
                },
              } as typeof PopupDeleteComponent.props,
            },
            deleteSuccess: {
              component: PopupSuccessComponent,
              props: {
                isOpen: this.userDeleteStore.isSuccess,
                textTitle: this.$t("popup.success.delete_user_title"),
                textDetail: this.$t("popup.success.delete_user_detail"),
                functionProp: () => {
                  this.reset();
                },
                functionCancelProp: () => {
                  this.userDeleteStore.isSuccess = false;
                },
              } as typeof PopupSuccessComponent.props,
            },
            deleteCaution: {
              component: PopupCautionComponent,
              props: {
                isOpen: this.userDeleteStore.isSuccess,
                textTitle: this.$t("popup.caution.delete_title"),
                textDetail: this.$t("popup.caution.delete_detail"),
                functionProp: () => {
                  this.setting();
                },
                functionCancelProp: () => {
                  this.userDeleteStore.isSuccess = false;
                },
              } as typeof PopupCautionComponent.props,
            },
          },
          badge: {
            status: {
              component: BadgeDefaultComponent,
              props: {
                htmlIcon: "",
                isSpecial: false,
                isVisible: true,
                isIcon: true,
                isDisable: false,
                size: this.$enums.ENUM_SIZE.NORMAL,
              } as typeof BadgeDefaultComponent.props,
            },
          },
        },
      };
    },
  },
  methods: {
    fetch() {
      this.userSearchStore.get(this.$route.query);
    },
    loading() {
      return this.userDeleteStore.loading || this.userSearchStore.loading;
    },
    onSubmit() {
      this.$router.push({ query: this.userSearchStore.query });
    },
    reset() {
      this.userDeleteStore.$reset();
      this.userSearchStore.$reset();
      this.fetch();
    },
    setting() {
      this.userDeleteStore.reset = this.reset;
      this.userDeleteStore.fetch = this.fetch;
    },
    goToPage(page: number, e?: Event) {
      e?.preventDefault();
      Object.assign(this.userSearchStore.query, { page });

      this.$router.push({ query: this.userSearchStore.query });
    },
    nextPage(e?: Event) {
      e?.preventDefault();
      const page = (this.userSearchStore.query?.page || 1) + 1;

      Object.assign(this.userSearchStore.query, { page });

      this.$router.push({ query: this.userSearchStore.query });
    },
    prevPage(e?: Event) {
      e?.preventDefault();
      const page = (this.userSearchStore.query?.page || 1) - 1;

      Object.assign(this.userSearchStore.query, { page });

      this.$router.push({ query: this.userSearchStore.query });
    },
  },
  async mounted() {
    this.fetch();
  },
  watch: {
    async ["$route.query"]() {
      await this.fetch();
    },
  },
  setup() {
    const masterDataStore = useMasterDataStore();
    const userDeleteStore = useUserDeleteStore();
    const userSearchStore = useUserSearchStore();

    return {
      masterDataStore,
      userDeleteStore,
      userSearchStore,
    };
  },
});
</script>

<template>
  <div class="ttp-form">
    <div class="items-center justify-end grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-2">
      <component :is="defaultFormComponents.form.button.btnCreate.component" v-bind="defaultFormComponents.form.button.btnCreate.props" @click="defaultFormComponents.form.button.btnCreate.click" />
    </div>
    <form @submit.prevent="onSubmit">
      <div class="w-full gap-2">
        <div class="ttp-between flex-col lg:flex-row items-start">
          <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-2 w-full">
            <component classTagName="w-full text-left" :is="defaultFormComponents.form.select.status.component" v-bind="defaultFormComponents.form.select.status.props" @updateValue="defaultFormComponents.form.select.status.onUpdateValue" />
            <component classTagName="w-full text-left" :is="defaultFormComponents.form.select.role.component" v-bind="defaultFormComponents.form.select.role.props" @updateValue="defaultFormComponents.form.select.role.onUpdateValue" />
            <component classTagName="w-full text-left" :is="defaultFormComponents.form.input.keyword.component" v-bind="defaultFormComponents.form.input.keyword.props" @updateValue="defaultFormComponents.form.input.keyword.onUpdateValue" />
          </div>
          <div class="flex gap-2 ttp-between !items-start">
            <component :is="defaultFormComponents.table.button.btnReset.component" v-bind="defaultFormComponents.table.button.btnReset.props" @click="defaultFormComponents.table.button.btnReset.click" />
            <component :is="defaultFormComponents.table.button.btnSearch.component" v-bind="defaultFormComponents.table.button.btnSearch.props" />
          </div>
        </div>
      </div>
    </form>
  </div>

  <TableComponent :headers="headers" :content="userSearchStore.tableResults?.items" :loading="userSearchStore.loading" counter :counter-label="$t('form.number.label')">
    <template v-slot:body-status="props">
      <component :is="defaultFormComponents.table.badge.status.component" v-bind="defaultFormComponents.table.badge.status.props" :title="$formattedStatus($classStatus(props.value))" :classEnumName="$classStatus(props.value)" />
    </template>
    <template v-slot:body-action="props">
      <router-link
        :to="{
          name: 'UserManageView',
          params: { id: props?.item?.id },
        }"
      >
        <component :is="defaultFormComponents.table.button.btnEdit.component" v-bind="defaultFormComponents.table.button.btnEdit.props" />
      </router-link>
      <component :is="defaultFormComponents.table.button.btnDelete.component" v-bind="defaultFormComponents.table.button.btnDelete.props" @click="defaultFormComponents.table.button.btnDelete.click(props)" />
    </template>
  </TableComponent>
  <PaginationComponent
    :query="userSearchStore.query"
    :currentPage="Number($route.query.page) || 1"
    :totalPage="userSearchStore.totalPage"
    :gotoPage="goToPage"
    :nextPage="nextPage"
    :prevPage="prevPage"
    :onUpdateCurrentPage="() => {}"
    :totalItem="userSearchStore.total"
    unitItem="Persons"
  />

  <!-- Delete -->
  <component :is="defaultFormComponents.table.popup.deletePopup.component" v-bind="defaultFormComponents.table.popup.deletePopup.props" />
  <component v-if="userDeleteStore.actionStatus == $enums.ENUM_MUTATION_STATUS.SUCCESS" :is="defaultFormComponents.table.popup.deleteSuccess.component" v-bind="defaultFormComponents.table.popup.deleteSuccess.props" />
  <component v-else-if="userDeleteStore.actionStatus == $enums.ENUM_MUTATION_STATUS.ERROR" :is="defaultFormComponents.table.popup.deleteCaution.component" v-bind="defaultFormComponents.table.popup.deleteCaution.props" />
</template>
