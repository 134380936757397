<template>
  <BreadcrumbComponent title="Dashboard" :breadcrumbs="breadcrumbs" />

  <div class="ttp-layout">
    <div class="p-4">
      <div class="ttp-page-header-1">
        <h2 class="ttp-text-sm">ประเภทการแจ้งเหตุ</h2>
      </div>
      <div class="grid grid-cols-4 gap-2">
        <VueDatePicker v-model="dates" :format="dateFormat" range :enable-time-picker="false"  />
        <VueSelect v-model="incidentCategory" :options="incidentCategories" :reduce="({ code }: SelectOption) => code" label="label" placeholder="ประเภทเรื่อง" class="border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-xs rounded-lg" />
        <VueSelect v-model="incidentTopic" :options="incidentTopics" :reduce="({ code }: SelectOption) => code" label="label" placeholder="หัวข้อเรื่อง" class="border border-gray-300 focus:border-ttp-primary text-gray-600 ttp-text-xs rounded-lg" multiple />
        <div class="text-right">
          <ButtonDefaultComponent isVisible title="ค้นหา" :classEnumName="ENUM_COLOR.DEFAULT_1" :size="ENUM_SIZE.SMALL" @click="submit" />
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
      <section>
        <div>
          <h4 class="text-black text-lg font-bold">อันดับการแจ้งเหตุ แยกตามประเภท</h4>
          <table class="table-auto w-full text-black">
            <thead>
              <tr>
                <td class="w-10 text-sm text-gray-400 text-center">อันดับ</td>
                <td></td>
                <td class="w-20 text-right text-sm text-gray-400">จำนวน (เหตุ)</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in store.incidentCategotyReport.slice(0, showAllIncidentCategoryReport ? store.incidentCategotyReport.length : 5)">
                <td class="text-center">{{ index + 1 }}</td>
                <td class="px-2">
                  <div class="flex h-10 w-full items-center overflow-hidden rounded-sm relative">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="flex items-center justify-center self-stretch rounded-sm bg-blue-100 text-xs font-medium text-[#1C2434] transition-all duration-500 ease-out" :aria-valuenow="Math.round((item.total * 100) / totalProvice)" :style="`width: ${Math.round((item.total * 100) / totalProvice)}%`" />
                    <span class="absolute left-5">{{ item.type?.content?.[0]?.title }} ({{ Math.round((item.total * 100) / totalProvice) }}%)</span>
                  </div>
                </td>
                <td class="px-2 text-right">{{ item.total }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <button class="mt-6" @click="showAllIncidentCategoryReport = !showAllIncidentCategoryReport">
              <template v-if="!showAllIncidentCategoryReport">
                คลิกเพื่อดูเพิ่มเติม
                <ChevronDownIcon class="w-5 mx-auto" />
              </template>
              <template v-else>
                <ChevronUpIcon class="w-5 mx-auto" />
                คลิกเพื่อย่อ
              </template>
            </button>
          </div>
        </div>
      </section>
      <section>
        <div class="flex flex-col h-full">
          <h4 class="text-black text-lg font-bold">อันดับการแจ้งเหตุ แยกตามหัวเรื่อง</h4>
          <div v-if="incidentCategory">
            <table class="table-auto w-full text-black">
              <thead>
                <tr>
                  <td class="w-10 text-sm text-gray-400 text-center">อันดับ</td>
                  <td></td>
                  <td class="w-20 text-right text-sm text-gray-400">จำนวน (เหตุ)</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in store.incidentTopicReport.slice(0, showAllIncidentTopicReport ? store.incidentTopicReport.length : 5)">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="px-2">
                    <div class="flex h-10 w-full items-center overflow-hidden rounded-sm relative">
                      <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="flex items-center justify-center self-stretch rounded-sm bg-blue-100 text-xs font-medium text-[#1C2434] transition-all duration-500 ease-out" :aria-valuenow="Math.round((item.total * 100) / totalProvice)" :style="`width: ${Math.round((item.total * 100) / totalProvice)}%`" />
                      <span class="absolute left-5">{{ item.type?.content?.[0]?.title }} ({{ Math.round((item.total * 100) / totalProvice) }}%)</span>
                    </div>
                  </td>
                  <td class="px-2 text-right">{{ item.total }}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center">
              <button class="mt-6" @click="showAllIncidentTopicReport = !showAllIncidentTopicReport">
                <template v-if="!showAllIncidentTopicReport">
                  คลิกเพื่อดูเพิ่มเติม
                  <ChevronDownIcon class="w-5 mx-auto" />
                </template>
                <template v-else>
                  <ChevronUpIcon class="w-5 mx-auto" />
                  คลิกเพื่อย่อ
                </template>
              </button>
            </div>
          </div>
          <EmptyDataComponent v-else title='กรุณาเลือก “ประเภทเรื่อง”<br />เพื่อดูหัวเรื่องย่อยที่ต้องการ' classTagName="py-5 flex flex-col h-full justify-center" />
        </div>
      </section>
      <section>
        <h4 class="text-black text-lg font-bold">สถิติการแจ้งเหตุ แยกตามจังหวัด</h4>
        <div>
          <table class="table-auto w-full text-black">
            <thead>
              <tr>
                <td></td>
                <td></td>
                <td>จำนวน</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in store.incidentProvinceReport.slice(0, showAllProvinceReport ? store.incidentProvinceReport.length : 5)">
                <td class="px-2">{{ item.type?.name.TH }}</td>
                <td class="px-2 w-3/5">
                  <div class="flex h-4 w-full items-center overflow-hidden rounded-full bg-blue-100 dark:bg-blue-950">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="flex items-center justify-center self-stretch rounded-full bg-blue-600 text-xs font-medium text-white transition-all duration-500 ease-out" :aria-valuenow="Math.round((item.total * 100) / totalProvice)" :style="`width: ${Math.round((item.total * 100) / totalProvice)}%`">{{ Math.round((item.total * 100) / totalProvice) }}%</div>
                  </div>
                </td>
                <td class="px-2 text-center">{{ item.total }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <button class="mt-6" @click="showAllProvinceReport = !showAllProvinceReport">
              <template v-if="!showAllProvinceReport">
                คลิกเพื่อดูเพิ่มเติม
                <ChevronDownIcon class="w-5 mx-auto" />
              </template>
              <template v-else>
                <ChevronUpIcon class="w-5 mx-auto" />
                คลิกเพื่อย่อ
              </template>
            </button>
          </div>
        </div>
      </section>
      <section>
        <h4 class="text-black text-lg font-bold mb-6">จำนวนเหตุทั้งหมด</h4>
        <div>
          <Doughnut
            :options="channelCounterOptions"
            :plugins="channelCounterPlugins"
            :data="{
              labels: ['สายด่วน 1155', 'สถานี', 'TPB App'],
              datasets: [
                {
                  data: [store.channelCounterReport[1155], store.channelCounterReport.WALK_IN, store.channelCounterReport.APPLICATION],
                  backgroundColor: ['#9CA3AF', '#3C50E0', '#80CAEE'],
                },
              ],
            }"
          />
        </div>
      </section>
      <section class="col-span-2">
        <div>
          <h4 class="text-black text-lg font-bold">สถิติการแจ้งเหตุ แยกตามพื้นที่รับผิดชอบ</h4>
          <table class="table-auto w-full text-black">
            <thead>
              <tr>
                <td class="w-10 text-sm text-gray-400 text-center">อันดับ</td>
                <td class="text-sm text-gray-400">กองกำกับการ กองบังคับการ</td>
                <td class="w-20 text-right text-sm text-gray-400">จำนวน (เหตุ)</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in store.responsibleAreaReport.slice(0, showAllReponsibleAreaReport ? store.responsibleAreaReport.length : 5)">
                <td class="text-center">{{ index + 1 }}</td>
                <td class="px-2">
                  <div class="flex h-10 w-full items-center overflow-hidden rounded-sm relative">
                    <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="flex items-center justify-center self-stretch rounded-sm bg-blue-100 text-xs font-medium text-[#1C2434] transition-all duration-500 ease-out" :aria-valuenow="Math.round((item.total * 100) / totalProvice)" :style="`width: ${Math.round((item.total * 100) / totalProvice)}%`" />
                    <span class="absolute left-5">{{ item.type?.content?.[0]?.shortName }} ({{ Math.round((item.total * 100) / totalProvice) }}%)</span>
                  </div>
                </td>
                <td class="px-2 text-right">{{ item.total }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <button class="mt-6" @click="showAllReponsibleAreaReport = !showAllReponsibleAreaReport">
              <template v-if="!showAllReponsibleAreaReport">
                คลิกเพื่อดูเพิ่มเติม
                <ChevronDownIcon class="w-5 mx-auto" />
              </template>
              <template v-else>
                <ChevronUpIcon class="w-5 mx-auto" />
                คลิกเพื่อย่อ
              </template>
            </button>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import VueSelect from "vue-select";
import BreadcrumbComponent, { type Breadcrumb } from "@/layouts/AuthenticationLayouts/PoliceCommandCenter/Breadcrumb.vue";
import { useDashboardStore } from "@/stores/PoliceCommandCenter/useDashboardStore";
import { useMasterDataStore } from "@/stores/Shared/useMasterDataStore";
import { ENUM_COLOR, ENUM_SIZE } from "@/configs/enums/enum";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import type { Chart, ChartOptions, LegendItem, Plugin } from "chart.js";
import { Doughnut } from "vue-chartjs";
import EmptyDataComponent from '@/components/Empty/EmptyData.vue';

type SelectOption = { label: string; code: string };

const store = useDashboardStore();
const masterDataStore = useMasterDataStore();
const { t } = useI18n();

const breadcrumbs = computed((): Breadcrumb[] => [
  {
    title: t("layout.menu.DashboardView"),
  },
  {
    title: t("layout.menu.DashboardIncidentTypeView"),
    route: {
      name: "DashboardIncidentTypeView",
    },
  },
]);

const dates = ref<Date[]>([]);
const incidentCategory = ref<string>();
const incidentTopic = ref<string[]>([]);
const loading = ref<boolean>(false);
const showAllProvinceReport = ref<boolean>(false);
const showAllReponsibleAreaReport = ref<boolean>(false);
const showAllIncidentCategoryReport = ref<boolean>(false);
const showAllIncidentTopicReport = ref<boolean>(false);

const incidentCategories = computed(
  (): Array<SelectOption> =>
    masterDataStore.incidentCategories.map((item) => ({
      code: item.code as string,
      label: item.content.find((c) => c.locale === "TH")?.title as string,
    }))
);

const incidentTopics = computed(
  () =>
    masterDataStore.incidentCategories
      .find((item) => item.code === incidentCategory.value)
      ?.topics.map((item) => ({
        code: item.code as string,
        label: item.content.find((c) => c.locale === "TH")?.title as string,
      })) || []
);
const totalProvice = computed(() => store.incidentProvinceReport.reduce((s, r) => s + r.total, 0));

const dateFormat = (dates: Date[]) => dates.map((date: Date) => dayjs(date).format("D MMM YYYY")).join(" - ");

const setupDefaultDates = () => {
  const today = dayjs();
  dates.value = [today.subtract(7, "day").startOf("day").toDate(), today.endOf("day").toDate()];
};

// Chart options
const channelCounterOptions: ChartOptions<"doughnut"> = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
      align: "center",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        generateLabels: (chart: Chart): LegendItem[] => {
          const [datasets] = chart.data.datasets;
          const total = datasets.data.reduce<number>((t: number, data) => {
            t += data as number;
            return t;
          }, 0);
          return datasets.data.map<LegendItem>((data, i) => ({
            text: `${chart?.data?.labels?.[i]} ${Math.round(((data as number) / total) * 100)}%`,
            fillStyle: (datasets.backgroundColor as string[])[i],
            strokeStyle: (datasets.backgroundColor as string[])[i],
            index: i,
          }));
        },
      },
    },
  },
};

const channelCounterPlugins = computed((): Plugin<"doughnut">[] => [
  {
    id: "textCenter",
    beforeDatasetsDraw: (chart: any) => {
      const ctx = chart.ctx;
      const xCoor = chart.chartArea.left + (chart.chartArea.right - chart.chartArea.left) / 2;
      const yCoor = chart.chartArea.top + (chart.chartArea.bottom - chart.chartArea.top) / 2;
      ctx.save();
      ctx.font = `2em "Outfit", "IBM Plex Sans Thai"`;
      ctx.fillStyle = "#212B36";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        Object.values(store.channelCounterReport).reduce((acc, cur) => acc + cur, 0),
        xCoor,
        yCoor
      );
      ctx.restore();
    },
  },
]);

onMounted(() => {
  setupDefaultDates();
  handleDate();

  masterDataStore.listIncidentCategory();
});

const handleDate = async () => {
  const [start, end] = [...dates.value].map((d) => dayjs(d));

  try {
    loading.value = true;
    await Promise.all([
      store.getIncidentProviceReport,
      store.getChannelCounterReport,
      store.getResponsibleAreaReport,
      store.getIncidentCategoryReport,
      ...(incidentCategory.value ? [store.getIncidentTopicReport] : []),
    ].map((c) => c(start, end, incidentCategory.value, incidentTopic.value)));
  } catch (e) {
    console.error(e);
  } finally {
    loading.value = false;
  }
};

const submit = () => {
  handleDate();
};

watch(incidentCategory, () => {
  incidentTopic.value = [];
});
</script>

<style lang="css" scoped>
section {
  @apply bg-white p-5 rounded-md shadow-sm;
}
</style>
